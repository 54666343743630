import CreateUserDepartmentRequest from '../models/userDepartment/CreateUserDepartmentRequest';
import UpdateUserDepartmentRequest from '../models/userDepartment/UpdateUserDepartmentRequest';
import UserDepartment from '../models/userDepartment/UserDepartment';
import apiService from './ApiService';

export class UserDepartmentService {
  // async update(
  //   id: number,
  //   updateDepartmentRequest: UpdateUserDepartmentRequest,
  // ): Promise<void> {
  //   const { departmentId, designationId, started_at, ended_at, salary } =
  //     updateDepartmentRequest;
  //   await apiService.put(`/api/user-department/${id}`, {
  //     departmentId: departmentId || undefined,
  //     designationId: designationId || undefined,
  //     started_at: started_at || undefined,
  //     ended_at: ended_at || undefined,
  //     salary: salary || undefined,
  //   });
  // }
  async update(
    id: number,
    updateDepartmentRequest: UpdateUserDepartmentRequest,
  ) {
    await apiService.put(
      `/api/user-department//${id}`,
      updateDepartmentRequest,
    );
  }

  async save(createDepartmentRequest: CreateUserDepartmentRequest) {
    await apiService.post('/api/user-department', createDepartmentRequest);
  }
  async find(id: string) {
    // return (await apiService.get<UserDepartment[]>('/api/user-department', {}))
    //   .data;
    return (await apiService.get(`/api/user-department/${id}`)).data;
  }

  async findOne(id: string): Promise<UserDepartment[]> {
    // return (await apiService.get<UserDepartment[]>('/api/user-department', {}))
    //   .data;
    return (
      await apiService.get<UserDepartment[]>(`/api/user-department/edit/${id}`)
    ).data;
  }

  async delete(id: string): Promise<void> {
    await apiService.put(`/api/user-department/delete/${id}`);
  }
}

// export default new UserService();
const userDepartmentService = new UserDepartmentService();
export { userDepartmentService as default };
