import SearchIcon from '@mui/icons-material/Search';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import html2pdf from 'html2pdf.js';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { DateRangePicker } from 'react-date-range';
import { useHistory } from 'react-router-dom';

import Layout from '../../components/layout';
import LeaveService from '../../services/LeaveService';
import DatePickerModal from '../DatePickerModal';

const columns = [
  { id: 'sr_no', label: 'Sr no.', minWidth: 50, sortable: false },
  {
    id: 'usersName',
    label: 'Name',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'fullDays',
    label: 'Full Day Leaves',
    minWidth: 100,
    sortable: true,
  },

  {
    id: 'halfLeaves',
    label: 'Half Day Leaves',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'shortLeaves',
    label: 'Short Leaves',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'details',
    label: 'Details',
    minWidth: 100,
    sortable: false,
  },
];

export default function MyLeaves() {
  const history = useHistory();
  const toggleContainer = useRef<HTMLDivElement | null>(null);

  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isMyTeam, setIsMyTeam] = useState(false);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortBy, setSortBy] = useState<string>('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = React.useState(0);
  const currentPage = 1;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const range = [];
  const minRange = Math.max(1, currentPage - 2);
  const maxRange = Math.min(totalPages, currentPage + 2);
  for (let i = minRange; i <= maxRange; i++) {
    range.push(i);
  }

  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);

    if (event.target.value !== '') {
    } else {
      setFilteredData(tableData);
    }

    const filtered = tableData.filter((data) =>
      data.userFullName.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);

      const staticStartDate = localStorage.getItem('staticStartDateLeave');
      const startDates = JSON.parse(staticStartDate);
      const startDate = new Date(startDates);

      const staticEndDate = localStorage.getItem('staticEndDateLeave');
      const endDates = JSON.parse(staticEndDate);
      const endDate = new Date(endDates);

      const selectionRanges = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      };

      setSelectionRange(selectionRanges);

      const isSameDate =
        startDate.getDate() === endDate.getDate() &&
        startDate.getMonth() === endDate.getMonth() &&
        startDate.getFullYear() === endDate.getFullYear();

      let formattedDate = '';

      if (isSameDate) {
        formattedDate = startDate.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });
      } else {
        const sameMonth = startDate.getMonth() === endDate.getMonth();
        const sameYear = startDate.getFullYear() === endDate.getFullYear();

        if (sameMonth && sameYear) {
          formattedDate = `${startDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
          })} - ${endDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}`;
        } else if (!sameMonth && sameYear) {
          formattedDate = `${startDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
          })} - ${endDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}`;
        } else {
          formattedDate = `${startDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
          })} - ${endDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}`;
        }
      }

      const formatToLocalDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`; // Format: "YYYY-MM-DD"
      };

      setSelectedDate(formattedDate);

      const allFilters = {
        loggedInUserId: parsedUser.id,
        startDate: formatToLocalDate(startDate),
        endDate: formatToLocalDate(endDate),
      };

      const totalLeaves = await LeaveService.numberOfMyLeaves(allFilters);

      totalLeaves.data.sort((a, b) => {
        if (a.userFullName < b.userFullName) {
          return -1;
        }
        if (a.userFullName > b.userFullName) {
          return 1;
        }
        return 0;
      });

      setIsMyTeam(totalLeaves.data.length > 1 ? true : false);
      setFilteredData(totalLeaves.data);
      setTableData(totalLeaves.data);
    };

    fetchUserData();
  }, []);

  const handleSort = (columnId: string) => {
    if (columnId === 'sr_no' || columnId === 'details') return;
    if (sortBy === columnId) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(columnId);
      setSortOrder('asc');
    }
  };

  const sortedItems = [...currentItems].sort((a, b) => {
    if (sortBy === 'sr_no' || sortBy === 'details') return 0;

    if (sortBy === 'usersName') {
      const nameA = a.userFullName.toUpperCase();
      const nameB = b.userFullName.toUpperCase();
      if (nameA < nameB) return sortOrder === 'asc' ? -1 : 1;
      if (nameA > nameB) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    }

    if (a[sortBy] < b[sortBy]) return sortOrder === 'asc' ? -1 : 1;
    if (a[sortBy] > b[sortBy]) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  const handleClickOutside = (event: MouseEvent) => {
    if (
      toggleContainer.current &&
      !toggleContainer.current.contains(event.target as Node)
    ) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleApply = async () => {
    const startDates = selectionRange.startDate;
    const endDates = selectionRange.endDate;
    const isSameDate =
      startDates.getDate() === endDates.getDate() &&
      startDates.getMonth() === endDates.getMonth() &&
      startDates.getFullYear() === endDates.getFullYear();

    let formattedDate = '';

    if (isSameDate) {
      formattedDate = startDates.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });
    } else {
      const sameMonth = startDates.getMonth() === endDates.getMonth();
      const sameYear = startDates.getFullYear() === endDates.getFullYear();

      if (sameMonth && sameYear) {
        formattedDate = `${startDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
        })} - ${endDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}`;
      } else if (!sameMonth && sameYear) {
        formattedDate = `${startDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
        })} - ${endDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}`;
      } else {
        formattedDate = `${startDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
        })} - ${endDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}`;
      }
    }

    setSelectedDate(formattedDate);

    localStorage.setItem('staticStartDateLeave', JSON.stringify(startDates));
    localStorage.setItem('staticEndDateLeave', JSON.stringify(endDates));

    const formatToLocalDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    };

    const storedUser = localStorage.getItem('user');
    const parsedUser = JSON.parse(storedUser);

    const data = {
      userId: parsedUser.id,
      month: 'specialFilter',
      startDate: formatToLocalDate(selectionRange.startDate),
      endDate: formatToLocalDate(selectionRange.endDate),
    };
    const totalLeaves = await LeaveService.filterMyLeavesOfMonth(data);

    if (searchTerm !== '') {
      const filtered = totalLeaves.filter((data) =>
        data.userFullName.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(totalLeaves);
    }
    setTableData(totalLeaves);

    closeModal();
  };

  const handleSelect = async (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    const sidebar = document.querySelector('.sidebarParent');
    const navbar = document.querySelector('.main__navbar__right');
    if (sidebar) {
      sidebar.classList.remove('blurred__sidebar');
    }
    if (navbar) {
      navbar.classList.remove('blurred__sidebar');
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
    const sidebar = document.querySelector('.sidebarParent');
    const navbar = document.querySelector('.main__navbar__right');
    if (sidebar) {
      sidebar.classList.add('blurred__sidebar');
    }
    if (navbar) {
      navbar.classList.add('blurred__sidebar');
    }
  };

  const setThisYearRange = () => {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const endOfYear = new Date(now.getFullYear(), 11, 31);
    setSelectionRange({
      startDate: startOfYear,
      endDate: endOfYear,
      key: 'selection',
    });

    const formattedDate = `${startOfYear.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })} - ${endOfYear.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })}`;

    setSelectedDate(formattedDate);
  };

  const setLastYearRange = () => {
    const now = new Date();
    const startOfLastYear = new Date(now.getFullYear() - 1, 0, 1);
    const endOfLastYear = new Date(now.getFullYear() - 1, 11, 31);
    setSelectionRange({
      startDate: startOfLastYear,
      endDate: endOfLastYear,
      key: 'selection',
    });

    const formattedDate = `${startOfLastYear.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })} - ${endOfLastYear.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })}`;

    setSelectedDate(formattedDate);
  };

  const handleViewLeave = async (usersId) => {
    history.push(`/MyLeaves/${usersId}`);
  };

  function handleDownloadPdf() {
    const table = document.getElementById('your-table-id');

    if (table) {
      const container = document.createElement('div');

      const header = document.createElement('div');
      header.innerHTML =
        '<img src="/logo.png" style="display: block; margin: 0 auto;"></img>';
      container.appendChild(header);

      const beforeTable = document.createElement('div');
      beforeTable.innerHTML = '<p style="text-align: center;"></p>';
      container.appendChild(beforeTable);

      const clonedTable = table.cloneNode(true) as HTMLElement;

      const rows = clonedTable.querySelectorAll('tr');
      rows.forEach((row) => {
        const lastCell = row.lastElementChild;
        lastCell?.remove();
      });

      container.appendChild(clonedTable);

      const afterTable = document.createElement('div');
      afterTable.innerHTML =
        '<div style="margin-top:5%;display:flex;flex-direction:row;justify-content:space-around;border-top:1px solid black"><div style="width:30%"><p style="color:#444444">2nd Floor, 38 Central Commercial Plaza, Bahria Orchard, Lahore</p></div><div><p style="color:#444444">ceo@ainsofttech.com</p></div><div><p style="color:#444444">+92 301 8424 988</p></div></div>';
      container.appendChild(afterTable);

      const footer = document.createElement('div');
      footer.innerHTML = '<p style="height:20px"><br></p>';
      container.appendChild(footer);

      // Set CSS styles to avoid content overlap
      const style = document.createElement('style');
      style.innerHTML = `
            .page-break {
                page-break-before: always;
            }
            .container {
                margin-bottom: 50px; /* Ensure there is enough margin */
            }
            tr {
                page-break-inside: avoid; /* Prevent row break */
            }
        `;
      container.appendChild(style);

      // Add a page break to avoid overlap
      const pageBreak = document.createElement('div');
      pageBreak.className = 'page-break';
      container.appendChild(pageBreak);

      const htmlString = container.innerHTML;

      html2pdf()
        .from(htmlString)
        .set({
          margin: 10,
          filename: 'leaves.pdf',
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        })
        .save();
    }
  }

  const formatDateRange = (startDate: Date, endDate: Date): string => {
    const sameMonth = startDate.getMonth() === endDate.getMonth();
    const sameYear = startDate.getFullYear() === endDate.getFullYear();

    if (sameMonth && sameYear) {
      return `${startDate.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
      })} - ${endDate.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })}`;
    } else if (!sameMonth && sameYear) {
      return `${startDate.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
      })} - ${endDate.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })}`;
    } else {
      return `${startDate.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
      })} - ${endDate.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })}`;
    }
  };

  return (
    <Layout>
      <div className="leavesIndex">
        <div className="leavesIndex__header" style={{ paddingBottom: 0 }}>
          <div className="leavesIndex__header_left">
            {isMyTeam ? (
              <h4 className="attendance_overview_heading">Team Leaves</h4>
            ) : (
              <h4 className="attendance_overview_heading">My Leaves</h4>
            )}
          </div>
        </div>

        <div className="leavesIndex__header">
          <div className="leavesIndex__header_left">
            <div className="remaingLeavesCounter">
              {isMyTeam && (
                <div className="remaingLeavesCounterEachAttendance">
                  <div
                    className={`searchNameFilterParent ${
                      isFocused ? 'searchNameFilterParentFocused' : ''
                    }`}
                    style={{ marginRight: '1vw' }}
                  >
                    <FormControl variant="standard">
                      <OutlinedInput
                        onChange={handleChange}
                        value={searchTerm}
                        className="searchNameFilter"
                        placeholder="Search By Name"
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                </div>
              )}

              <div className="remaingLeavesCounterEachAttendance">
                <div className="singleInputFieldFilter">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={['DatePicker', 'DatePicker', 'DatePicker']}
                    >
                      <FormControl variant="standard">
                        <OutlinedInput
                          className="searchNameFilter"
                          placeholder="Select Date"
                          onClick={openModal}
                          value={selectedDate || ''}
                          readOnly
                        />
                      </FormControl>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>

              <div
                className="remaingLeavesCounterEachAttendance remaingLeavesCounterMyLeaves"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <button
                  onClick={handleDownloadPdf}
                  className="downloadPDFButton downloadPDFButton2 bg-custom hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Download PDF
                </button>
              </div>
            </div>
          </div>
        </div>
        <Card className="myLeaves__card" variant="outlined">
          <div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer>
                <Table
                  className="MyLeavesTable"
                  stickyHeader
                  aria-label="sticky table"
                  id="your-table-id"
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          style={{ textAlign: 'center' }}
                        >
                          {column.sortable ? (
                            <TableSortLabel
                              active={sortBy === column.id}
                              direction={
                                sortBy === column.id ? sortOrder : 'asc'
                              }
                              onClick={() => handleSort(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          ) : (
                            column.label
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedItems.map((row, index) => (
                      <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                        <TableCell style={{ textAlign: 'center' }}>
                          {index + 1}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: 'center' }}
                          className="truncate"
                        >
                          {row['userFullName']}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {row['fullDays']}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {row['halfLeaves']}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {row['shortLeaves']}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          <button
                            style={{
                              border: 'none',
                              color: '#0066ff',
                              backgroundColor: 'transparent',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleViewLeave(row['userId'])}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 26 26"
                            >
                              <g data-name="Group 295">
                                <path
                                  data-name="visibility_FILL0_wght300_GRAD0_opsz24 (1)"
                                  d="M13.003 16.95a4.236 4.236 0 0 0 3.115-1.284 4.248 4.248 0 0 0 1.281-3.117 4.236 4.236 0 0 0-1.284-3.115 4.248 4.248 0 0 0-3.117-1.281 4.236 4.236 0 0 0-3.115 1.284 4.248 4.248 0 0 0-1.281 3.117 4.236 4.236 0 0 0 1.284 3.115 4.248 4.248 0 0 0 3.117 1.281zm0-1.485a2.809 2.809 0 0 1-2.063-.85 2.809 2.809 0 0 1-.85-2.063 2.809 2.809 0 0 1 .85-2.063 2.809 2.809 0 0 1 2.063-.85 2.809 2.809 0 0 1 2.063.85 2.809 2.809 0 0 1 .85 2.063 2.809 2.809 0 0 1-.85 2.063 2.809 2.809 0 0 1-2.065.85zm0 4.639a11.281 11.281 0 0 1-6.231-1.817 13.565 13.565 0 0 1-4.509-4.782 1.9 1.9 0 0 1-.2-.468 1.892 1.892 0 0 1-.062-.484 1.884 1.884 0 0 1 .062-.484 1.9 1.9 0 0 1 .2-.468 13.564 13.564 0 0 1 4.509-4.782 11.281 11.281 0 0 1 6.229-1.82 11.281 11.281 0 0 1 6.231 1.818 13.564 13.564 0 0 1 4.514 4.782 1.9 1.9 0 0 1 .2.468 1.893 1.893 0 0 1 .062.484 1.885 1.885 0 0 1-.062.484 1.9 1.9 0 0 1-.2.468 13.565 13.565 0 0 1-4.509 4.782 11.281 11.281 0 0 1-6.236 1.814zm-.002-7.552zm0 5.934a10.3 10.3 0 0 0 5.6-1.6 10.543 10.543 0 0 0 3.9-4.329 10.544 10.544 0 0 0-3.9-4.329 10.3 10.3 0 0 0-5.6-1.6 10.3 10.3 0 0 0-5.6 1.6 10.544 10.544 0 0 0-3.9 4.329 10.543 10.543 0 0 0 3.9 4.329 10.3 10.3 0 0 0 5.6 1.6z"
                                />
                                <path
                                  data-name="Rectangle 293"
                                  fill="none"
                                  d="M0 0h26v26H0z"
                                />
                              </g>
                            </svg>
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </Card>
        <DatePickerModal isOpen={isModalOpen} onClose={closeModal}>
          <div className="modal__popup">
            <div className="date-filter-container">
              <div
                className="date-pickers"
                style={{ display: 'flex', gap: '16px' }}
              >
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={handleSelect}
                />
              </div>
              <div
                className="preset-buttons"
                style={{ margin: '16px 0', display: 'flex', gap: '8px' }}
              >
                <Button variant="outlined" onClick={setThisYearRange}>
                  This Year
                </Button>
                <Button variant="outlined" onClick={setLastYearRange}>
                  Last Year
                </Button>
              </div>
              <div
                className="action-buttons"
                style={{ marginTop: '16px', textAlign: 'right' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleApply}
                >
                  Apply
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={closeModal}
                  style={{ marginLeft: '8px' }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </DatePickerModal>
      </div>
    </Layout>
  );
}
