import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import html2pdf from 'html2pdf.js';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../../components/layout';
import LeaveService from '../../services/LeaveService';

const columns = [
  { id: 'sr_no', label: 'Sr no.', minWidth: 50 },
  {
    id: 'userFullName',
    label: 'Name',
    minWidth: 100,
  },
  {
    id: 'date',
    label: 'Date',
    minWidth: 100,
  },

  {
    id: 'duration',
    label: 'Duration',
    minWidth: 100,
  },

  {
    id: 'details',
    label: 'Details',
    minWidth: 100,
  },
];

export default function LeaveRequests() {
  const history = useHistory();

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const currentPage = 1;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const range = [];
  const minRange = Math.max(1, currentPage - 2);
  const maxRange = Math.min(totalPages, currentPage + 2);
  for (let i = minRange; i <= maxRange; i++) {
    range.push(i);
  }

  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    const fetchUserData = async () => {
      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);
      const totalLeaves = await LeaveService.getPendingLeaves(parsedUser.id);
      setFilteredData(totalLeaves);
    };

    fetchUserData();
  }, []);

  const handleViewLeave = async (usersId) => {
    history.push(`/MyLeaves/view/${usersId}`);
  };

  function handleDownloadPdf() {
    const table = document.getElementById('your-table-id');

    if (table) {
      const container = document.createElement('div');

      const header = document.createElement('div');
      header.innerHTML =
        '<img src="/logo.png" style="display: block; margin: 0 auto;"></img>';
      container.appendChild(header);

      const beforeTable = document.createElement('div');
      beforeTable.innerHTML = '<p style="text-align: center;"></p>';
      container.appendChild(beforeTable);

      const clonedTable = table.cloneNode(true) as HTMLElement;

      const rows = clonedTable.querySelectorAll('tr');
      rows.forEach((row) => {
        const lastCell = row.lastElementChild;
        lastCell?.remove();
      });

      container.appendChild(clonedTable);

      const afterTable = document.createElement('div');
      afterTable.innerHTML =
        '<div style="margin-top:5%;display:flex;flex-direction:row;justify-content:space-around;border-top:1px solid black"><div style="width:30%"><p style="color:#444444">2nd Floor, 38 Central Commercial Plaza, Bahria Orchard, Lahore</p></div><div><p style="color:#444444">ceo@ainsofttech.com</p></div><div><p style="color:#444444">+92 301 8424 988</p></div></div>';
      container.appendChild(afterTable);

      const footer = document.createElement('div');
      footer.innerHTML = '<p style="height:20px"><br></p>';
      container.appendChild(footer);

      // Set CSS styles to avoid content overlap
      const style = document.createElement('style');
      style.innerHTML = `
            .page-break {
                page-break-before: always;
            }
            .container {
                margin-bottom: 50px; /* Ensure there is enough margin */
            }
            tr {
                page-break-inside: avoid; /* Prevent row break */
            }
        `;
      container.appendChild(style);

      // Add a page break to avoid overlap
      const pageBreak = document.createElement('div');
      pageBreak.className = 'page-break';
      container.appendChild(pageBreak);

      const htmlString = container.innerHTML;

      html2pdf()
        .from(htmlString)
        .set({
          margin: 10,
          filename: 'leaves.pdf',
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        })
        .save();
    }
  }

  return (
    <Layout>
      <div className="leavesIndex">
        <div className="leavesIndex__header" style={{ paddingBottom: 0 }}>
          <div className="leavesIndex__header_left">
            <h4 className="attendance_overview_heading">Pending Leaves</h4>
          </div>
        </div>

        <div className="leavesIndex__header">
          <div className="leavesIndex__header_left">
            <div className="remaingLeavesCounter">
              <div
                className="remaingLeavesCounterEachAttendance remaingLeavesCounterMyLeaves"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <button
                  onClick={handleDownloadPdf}
                  className="downloadPDFButton downloadPDFButton2 bg-custom hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Download PDF
                </button>
              </div>
            </div>
          </div>
        </div>
        <Card className="myLeaves__card" variant="outlined">
          <div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              {currentItems.length > 0 ? (
                <TableContainer>
                  <Table
                    className="MyLeavesTable"
                    stickyHeader
                    aria-label="sticky table"
                    id="your-table-id"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentItems.map((row, index) => (
                        <TableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <TableCell style={{ textAlign: 'center' }}>
                            {index + 1}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {row['userFullName']}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {row['date']}
                          </TableCell>
                          {row.specialRequestId ? (
                            <TableCell
                              style={{
                                display: 'flex',
                                position: 'relative',
                                textAlign: 'center',
                                flexDirection: 'column-reverse',
                              }}
                            >
                              <span>
                                {row['which_half'] === 'first_half'
                                  ? 'First Half'
                                  : row['which_half'] === 'second_half'
                                  ? 'Second Half'
                                  : row['which_half'] === 'short_leave'
                                  ? 'Short Leave'
                                  : 'Full Day'}
                              </span>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <a
                                  style={{ cursor: 'pointer' }}
                                  href={`/MyLeaves/view/${row['id']}`}
                                >
                                  <span
                                    style={{
                                      backgroundColor: 'rgb(255, 206, 30)',
                                      fontSize: '10px',
                                      padding: '2px 3px',
                                      borderRadius: '8px',
                                      display: 'inline-block',
                                    }}
                                  >
                                    M.L
                                  </span>
                                </a>
                              </div>
                            </TableCell>
                          ) : (
                            <TableCell
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              {row['which_half'] === 'first_half'
                                ? 'First Half'
                                : row['which_half'] === 'second_half'
                                ? 'Second Half'
                                : row['which_half'] === 'short_leave'
                                ? 'Short Leave'
                                : 'Full Day'}
                            </TableCell>
                          )}
                          {/* <TableCell style={{ textAlign: 'center' }}>
                            {row['which_half'] === 'first_half'
                              ? 'First Half'
                              : row['which_half'] === 'second_half'
                              ? 'Second Half'
                              : row['which_half'] === 'short_leave'
                              ? 'Short Leave'
                              : 'Full Day'}
                          </TableCell> */}
                          <TableCell style={{ textAlign: 'center' }}>
                            <button
                              style={{
                                border: 'none',
                                color: '#0066ff',
                                backgroundColor: 'transparent',
                                cursor: 'pointer',
                              }}
                              onClick={() => handleViewLeave(row['id'])}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 26 26"
                              >
                                <g data-name="Group 295">
                                  <path
                                    data-name="visibility_FILL0_wght300_GRAD0_opsz24 (1)"
                                    d="M13.003 16.95a4.236 4.236 0 0 0 3.115-1.284 4.248 4.248 0 0 0 1.281-3.117 4.236 4.236 0 0 0-1.284-3.115 4.248 4.248 0 0 0-3.117-1.281 4.236 4.236 0 0 0-3.115 1.284 4.248 4.248 0 0 0-1.281 3.117 4.236 4.236 0 0 0 1.284 3.115 4.248 4.248 0 0 0 3.117 1.281zm0-1.485a2.809 2.809 0 0 1-2.063-.85 2.809 2.809 0 0 1-.85-2.063 2.809 2.809 0 0 1 .85-2.063 2.809 2.809 0 0 1 2.063-.85 2.809 2.809 0 0 1 2.063.85 2.809 2.809 0 0 1 .85 2.063 2.809 2.809 0 0 1-.85 2.063 2.809 2.809 0 0 1-2.065.85zm0 4.639a11.281 11.281 0 0 1-6.231-1.817 13.565 13.565 0 0 1-4.509-4.782 1.9 1.9 0 0 1-.2-.468 1.892 1.892 0 0 1-.062-.484 1.884 1.884 0 0 1 .062-.484 1.9 1.9 0 0 1 .2-.468 13.564 13.564 0 0 1 4.509-4.782 11.281 11.281 0 0 1 6.229-1.82 11.281 11.281 0 0 1 6.231 1.818 13.564 13.564 0 0 1 4.514 4.782 1.9 1.9 0 0 1 .2.468 1.893 1.893 0 0 1 .062.484 1.885 1.885 0 0 1-.062.484 1.9 1.9 0 0 1-.2.468 13.565 13.565 0 0 1-4.509 4.782 11.281 11.281 0 0 1-6.236 1.814zm-.002-7.552zm0 5.934a10.3 10.3 0 0 0 5.6-1.6 10.543 10.543 0 0 0 3.9-4.329 10.544 10.544 0 0 0-3.9-4.329 10.3 10.3 0 0 0-5.6-1.6 10.3 10.3 0 0 0-5.6 1.6 10.544 10.544 0 0 0-3.9 4.329 10.543 10.543 0 0 0 3.9 4.329 10.3 10.3 0 0 0 5.6 1.6z"
                                  />
                                  <path
                                    data-name="Rectangle 293"
                                    fill="none"
                                    d="M0 0h26v26H0z"
                                  />
                                </g>
                              </svg>
                            </button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <TableContainer>
                  <Table
                    className="MyLeavesTable"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          style={{ textAlign: 'center', padding: '20px' }}
                        >
                          No records found
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {currentItems.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Paper>
          </div>
        </Card>
      </div>
    </Layout>
  );
}
