import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Layout from '../../components/layout';
import LeaveService from '../../services/LeaveService';
import UserService from '../../services/UserService';
import ValidationModal from '../BootstrapValidationModal';
import RejectionModal from '../RejectionModal';

export default function ViewLeave() {
  const { id } = useParams() as { id: string };
  const history = useHistory();

  const [leavesData, setLeavesData] = useState([]);
  const [userName, setUserName] = useState('');
  const [leaveDate, setLeaveDate] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('');

  const [approveByUserName, setApproveByUserName] = useState('');
  const [isTeamLead, setIsTeamLead] = useState(false);
  const [descriptionText, setDescriptionText] = useState('');
  const [description, setDescription] = useState('');
  const [reason, setReason] = useState('');

  const [isSubmittingModalOpen, setIsSubmittingModalOpen] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [approveButtonDisabled, setApproveButtonDisabled] = useState(false);
  const [rejectButtonDisabled, setRejectButtonDisabled] = useState(false);

  let isMounted = true;

  useEffect(() => {
    const fetchUserData = async (id) => {
      const leavesData = await LeaveService.findOne(id);
      console.log(leavesData['leave'].specialRequest);
      if (leavesData['leave'].specialRequest) {
        setReason(leavesData['leave'].specialRequest.reason);
      }
      setLeavesData(leavesData['leave']);
      setDescription(leavesData['leave'].description);
      if (leavesData['leave'].status === 'approved') {
        setApproveButtonDisabled(true);
      } else {
        setApproveButtonDisabled(false);
      }
      if (leavesData['leave'].status === 'rejected') {
        setRejectButtonDisabled(true);
      } else {
        setRejectButtonDisabled(false);
      }
      setUserName(leavesData['usersName']);

      if (leavesData['leave'].approvedByUser) {
        setApproveByUserName(
          leavesData['leave'].approvedByUser.first_name +
            ' ' +
            leavesData['leave'].approvedByUser.last_name,
        );
      }
      setLeaveDate(leavesData['date']);
      const leaveUsersId = leavesData['leave'].usersId;
      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);
      const MyTeam = await UserService.findTeam(parsedUser.id);
      const usersArray = MyTeam.user;

      const isPermitted = usersArray.filter((users) => {
        return users['id'] === leaveUsersId;
      });
      if (parsedUser.roleId === 1) {
        setIsTeamLead(true);
      } else {
        if (parsedUser.id === leavesData['usersId']) {
          setIsTeamLead(false);
        }
        if (isPermitted.length !== 0) {
          if (parsedUser.id !== leavesData['usersId']) {
            setIsTeamLead(true);
          } else {
            setIsTeamLead(false);
          }
        } else {
          history.push(`/myLeaves`);
        }
      }
    };
    fetchUserData(id);
  }, [id]);

  useEffect(() => {
    let intervalId;
    let isMounted = true;
    setIsModalOpen(false);
    setCurrentStatus('');
    const startProgress = () => {
      intervalId = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress = oldProgress + 100 / steps;
          if (newProgress >= 100) {
            clearInterval(intervalId);
            setTimeout(() => {
              if (isMounted) {
                setIsSubmittingModalOpen(false);
                history.push(`/leave/requests`);
              }
            }, 500);
            return 100;
          }
          return newProgress;
        });
      }, intervalDuration);
    };

    const intervalDuration = 30;
    const totalTime = 10000;
    const steps = totalTime / intervalDuration;
    if (isSubmittingModalOpen) {
      startProgress();
    }

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [isSubmittingModalOpen]);

  const respondToRequest = async () => {
    if (leavesData['status'] !== currentStatus) {
      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);
      const data = {
        approvedByUserId: parsedUser.id,
        status: currentStatus,
        rejectReason: descriptionText,
        respondToRequest: 'respondToRequest',
      };
      setIsModalOpen(false);
      setCurrentStatus('');
      setIsSubmittingModalOpen(true);
      await LeaveService.update(+id, data);
    }

    if (isMounted) {
      setIsModalOpen(false);
      setCurrentStatus('');
    }
  };

  // const respondToLeave = async (status) => {
  //   if (leavesData['status'] !== status) {
  //     const storedUser = localStorage.getItem('user');
  //     const parsedUser = JSON.parse(storedUser);
  //     const data = {
  //       approvedByUserId: parsedUser.id,
  //       status: status,
  //     };

  //     await LeaveService.update(+id, data);
  //     const leavesData = await LeaveService.findOne(id);
  //     setLeavesData(leavesData['leave']);
  //     setUserName(leavesData['usersName']);

  //     if (leavesData['approveUsersName']) {
  //       setApproveByUserName(leavesData['approveUsersName']);
  //     }
  //     window.location.reload();
  //   }
  // };

  const handleChangeDescription = (e) => {
    const { value } = e.target;
    setDescriptionText(value);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentStatus('');
  };

  const closeSubmittingModal = () => {
    setIsSubmittingModalOpen(false);
  };

  const openModal = async (status) => {
    setIsModalOpen(true);
    setCurrentStatus(status);

    const sidebar = document.querySelector('.sidebarParent');
    const navbar = document.querySelector('.main__navbar__right');

    if (sidebar) {
      sidebar.classList.add('blurred__sidebar');
    }
    if (navbar) {
      navbar.classList.add('blurred__sidebar');
    }
  };

  const closeRejectionModal = () => {
    setIsRejectionModalOpen(false);
    setCurrentStatus('');
  };

  const openRejectionModal = async (status) => {
    setIsRejectionModalOpen(true);
    setCurrentStatus(status);

    const sidebar = document.querySelector('.sidebarParent');
    const navbar = document.querySelector('.main__navbar__right');

    if (sidebar) {
      sidebar.classList.add('blurred__sidebar');
    }
    if (navbar) {
      navbar.classList.add('blurred__sidebar');
    }
  };
  return (
    <Layout>
      <div className="leavesIndex">
        <div
          className="leavesIndex__header leavesIndex__body_view_screen"
          style={{ paddingBottom: 0 }}
        >
          <div>
            <h4 className="attendance_overview_heading">Leave Details</h4>
          </div>
          <div style={{ display: 'flex' }}>
            {isTeamLead && (
              <div
                className="leavesIndex__header_right"
                style={{ paddingRight: '1vw' }}
              >
                <button
                  onClick={() => openModal('approved')}
                  className={`approveButton ${
                    approveButtonDisabled ? 'disabled' : ''
                  }`}
                  disabled={approveButtonDisabled}
                >
                  Approve
                </button>
                <button
                  onClick={() => openRejectionModal('rejected')}
                  className={`rejectButton ${
                    rejectButtonDisabled ? 'disabled' : ''
                  }`}
                  disabled={rejectButtonDisabled}
                >
                  Decline
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="leavesIndex__body leavesIndex__body_view_screen">
          <div className="leavesIndex__body_padding leavesIndex__body_padding_view_screen">
            <Box
              component="form"
              noValidate
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr' },
                gap: 2,
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputField">
                  <div className="mt-2 flex items-center">
                    <InputLabel
                      className="formInputLabels viewScreenHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Name
                    </InputLabel>
                    <InputLabel
                      className="formInputLabels formInputLabels2 viewScreenSubHeading ellipsis"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      {userName}
                    </InputLabel>
                  </div>
                </div>
                <div className="singleInputField">
                  <div className="mt-2 flex items-center">
                    <InputLabel
                      className="formInputLabels viewScreenHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Duration
                    </InputLabel>
                    <InputLabel
                      className="formInputLabels formInputLabels2 viewScreenSubHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      {leavesData['which_half']}
                    </InputLabel>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {leavesData['which_half'] === 'short_leave' ? (
                  <div className="singleInputField">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels viewScreenHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Date
                      </InputLabel>
                      <InputLabel
                        className="formInputLabels formInputLabels2 viewScreenSubHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        {leaveDate}
                      </InputLabel>
                    </div>
                  </div>
                ) : (
                  <div className="singleInputField">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels viewScreenHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Status
                      </InputLabel>
                      <InputLabel
                        className="formInputLabels formInputLabels2 viewScreenSubHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        {leavesData['status']}
                      </InputLabel>
                    </div>
                  </div>
                )}
                {leavesData['which_half'] === 'short_leave' ? (
                  <div className="singleInputField">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels viewScreenHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Time
                      </InputLabel>
                      <InputLabel
                        className="formInputLabels formInputLabels2 viewScreenSubHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        {leavesData['fromTime']}
                      </InputLabel>
                    </div>
                  </div>
                ) : (
                  <div className="singleInputField">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels viewScreenHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Date
                      </InputLabel>
                      <InputLabel
                        className="formInputLabels formInputLabels2 viewScreenSubHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        {leaveDate}
                      </InputLabel>
                    </div>
                  </div>
                )}
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {leavesData['which_half'] === 'short_leave' && (
                  <div className="singleInputField">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels viewScreenHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Status
                      </InputLabel>
                      <InputLabel
                        className="formInputLabels formInputLabels2 viewScreenSubHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        {leavesData['status']}
                      </InputLabel>
                    </div>
                  </div>
                )}
                <div className="singleInputField">
                  {approveByUserName !== '' && (
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels viewScreenHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Marked By
                      </InputLabel>
                      <InputLabel
                        className="formInputLabels formInputLabels2 viewScreenSubHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        {approveByUserName}
                      </InputLabel>
                    </div>
                  )}
                </div>
                {/* <div className="singleInputField">
                  {leavesData['duration'] && (
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels viewScreenHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Time
                      </InputLabel>
                      <InputLabel
                        className="formInputLabels formInputLabels2 viewScreenSubHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        {leavesData['fromTime']} - {leavesData['toTime']}
                      </InputLabel>
                    </div>
                  )}
                </div> */}
              </div>
              {reason !== '' && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className="singleInputField">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels viewScreenHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Special Request Reason
                      </InputLabel>
                      <InputLabel
                        className="formInputLabels formInputLabels2 viewScreenSubHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        {reason}
                      </InputLabel>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div className="singleInputFieldTextArea">
                    <InputLabel
                      className="formInputLabels viewScreenHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Reason For Leave
                    </InputLabel>
                    <FormControl variant="standard">
                      <textarea
                        className={`leaveDurationTextArea leaveDurationInput leaveDurationTextAreaViewScreen`}
                        value={description}
                        placeholder="Enter text here..."
                        rows={6}
                        cols={50}
                        readOnly
                      />
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '1vw 0',
                  }}
                >
                  {leavesData['status'] === 'rejected' &&
                    leavesData['rejectReason'] !== null && (
                      <div className="singleInputField singleInputFieldTextArea ">
                        <InputLabel
                          className="formInputLabels viewScreenHeading"
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          Reason For Rejection
                        </InputLabel>
                        <FormControl variant="standard">
                          <textarea
                            className={`leaveDurationTextArea leaveDurationInput leaveDurationTextAreaViewScreen`}
                            value={leavesData['rejectReason']}
                            placeholder="Enter text here..."
                            rows={6}
                            cols={50}
                            readOnly
                          />
                        </FormControl>
                      </div>
                    )}
                </div>
              </div>
            </Box>
          </div>
        </div>
        <RejectionModal isOpen={isModalOpen} onClose={closeModal}>
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div
                className="ValidationModal"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span className="approvalText">
                  Are You sure you want to approve this request !
                </span>

                <div className="CloseRejectionModalButton">
                  <button
                    type="button"
                    className="CloseRejectionModal"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="submitValidationModal"
                    onClick={respondToRequest}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </RejectionModal>
        <RejectionModal
          isOpen={isRejectionModalOpen}
          onClose={closeRejectionModal}
        >
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div
                className="ValidationModal"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <h3 className="rejectionHeading">Decline</h3>
                <span className="rejectionText">
                  Would you like to mention any reason for Leave rejection?
                </span>
                <FormControl variant="standard">
                  <textarea
                    className={`leaveDurationTextArea leaveDurationInput`}
                    placeholder="Mention Reason here (optional)"
                    rows={6}
                    cols={50}
                    onChange={handleChangeDescription}
                    value={descriptionText}
                  />
                </FormControl>
                <div className="CloseRejectionModalButton">
                  <button
                    type="button"
                    className="CloseRejectionModal"
                    onClick={closeRejectionModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="submitValidationModal"
                    onClick={respondToRequest}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </RejectionModal>
        <ValidationModal
          isOpen={isSubmittingModalOpen}
          onClose={closeSubmittingModal}
        >
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div className="ProgressModal">
                <Box className="progressOfSubmitting" sx={{ width: '100%' }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                      height: 15,
                      borderRadius: 10,
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#1fc214',
                      },
                    }}
                  />
                </Box>
                <span>Submitting Request</span>
              </div>
            </div>
          </div>
        </ValidationModal>
      </div>
    </Layout>
  );
}
