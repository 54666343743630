import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import html2pdf from 'html2pdf.js';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { DateRangePicker } from 'react-date-range';
import { useHistory, useParams } from 'react-router-dom';

import Layout from '../../components/layout';
import LeaveService from '../../services/LeaveService';
import DatePickerModal from '../DatePickerModal';
import RejectionModal from '../RejectionModal';

const columns = [
  { id: 'sr_no', label: 'Sr no.', minWidth: 50, sortable: false },
  {
    id: 'usersName',
    label: 'Name',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'leaveDate',
    label: 'Date',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'leaveTenure',
    label: 'Duration',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'leaveStatus',
    label: 'Status',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'approvedBy',
    label: 'Marked By',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'details',
    label: 'Details',
    minWidth: 100,
    sortable: false,
  },
];

export default function LeaveList() {
  const history = useHistory();
  const { id } = useParams() as { id: string };
  const toggleContainer = useRef<HTMLDivElement | null>(null);

  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [selectedDate, setSelectedDate] = useState(null);

  const [halfLeaveCounter, setHalfLeaveCounter] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortBy, setSortBy] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedDuration, setSelectedDuration] = useState('all');

  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const currentPage = 1;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const range = [];
  const minRange = Math.max(1, currentPage - 2);
  const maxRange = Math.min(totalPages, currentPage + 2);
  for (let i = minRange; i <= maxRange; i++) {
    range.push(i);
  }

  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    const fetchUserData = async () => {
      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);
      setCurrentUserId(parsedUser.id);

      const staticStartDate = localStorage.getItem('staticStartDateLeave');
      const startDates = JSON.parse(staticStartDate);
      const startDate = new Date(startDates);

      const staticEndDate = localStorage.getItem('staticEndDateLeave');
      const endDates = JSON.parse(staticEndDate);
      const endDate = new Date(endDates);

      const selectionRanges = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      };

      setSelectionRange(selectionRanges);

      const isSameDate =
        startDate.getDate() === endDate.getDate() &&
        startDate.getMonth() === endDate.getMonth() &&
        startDate.getFullYear() === endDate.getFullYear();

      let formattedDate = '';

      if (isSameDate) {
        formattedDate = startDate.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });
      } else {
        const sameMonth = startDate.getMonth() === endDate.getMonth();
        const sameYear = startDate.getFullYear() === endDate.getFullYear();

        if (sameMonth && sameYear) {
          formattedDate = `${startDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
          })} - ${endDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}`;
        } else if (!sameMonth && sameYear) {
          formattedDate = `${startDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
          })} - ${endDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}`;
        } else {
          formattedDate = `${startDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
          })} - ${endDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}`;
        }
      }

      const formatToLocalDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`; // Format: "YYYY-MM-DD"
      };

      setSelectedDate(formattedDate);

      const allFilters = {
        loggedInUserId: id,
        startDate: formatToLocalDate(startDate),
        endDate: formatToLocalDate(endDate),
      };

      const leaves = await LeaveService.findAllOfMine(allFilters);
      const leaves1 = await LeaveService.findRemaingLeaves(parsedUser.id);

      setHalfLeaveCounter(leaves1.halfLeave);
      setFilteredData(leaves.data.leave);
      setTableData(leaves.data.leave);
    };

    fetchUserData();
  }, []);

  const handleSort = (columnId: string) => {
    if (columnId === 'sr_no' || columnId === 'details') return;
    if (sortBy === columnId) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(columnId);
      setSortOrder('asc');
    }
  };

  const sortedItems = [...currentItems].sort((a, b) => {
    if (sortBy === 'sr_no' || sortBy === 'details') return 0;

    if (sortBy === 'usersName') {
      const nameA = a.userFullName.toUpperCase();
      const nameB = b.userFullName.toUpperCase();
      if (nameA < nameB) return sortOrder === 'asc' ? -1 : 1;
      if (nameA > nameB) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    }

    if (a[sortBy] < b[sortBy]) return sortOrder === 'asc' ? -1 : 1;
    if (a[sortBy] > b[sortBy]) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  const handleClickOutside = (event: MouseEvent) => {
    if (
      toggleContainer.current &&
      !toggleContainer.current.contains(event.target as Node)
    ) {
      setIsDateModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleApply = async () => {
    const startDates = selectionRange.startDate;
    const endDates = selectionRange.endDate;
    const isSameDate =
      startDates.getDate() === endDates.getDate() &&
      startDates.getMonth() === endDates.getMonth() &&
      startDates.getFullYear() === endDates.getFullYear();

    let formattedDate = '';

    if (isSameDate) {
      formattedDate = startDates.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });
    } else {
      const sameMonth = startDates.getMonth() === endDates.getMonth();
      const sameYear = startDates.getFullYear() === endDates.getFullYear();

      if (sameMonth && sameYear) {
        formattedDate = `${startDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
        })} - ${endDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}`;
      } else if (!sameMonth && sameYear) {
        formattedDate = `${startDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
        })} - ${endDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}`;
      } else {
        formattedDate = `${startDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
        })} - ${endDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}`;
      }
    }

    setSelectedDate(formattedDate);

    localStorage.setItem('staticStartDateLeave', JSON.stringify(startDates));
    localStorage.setItem('staticEndDateLeave', JSON.stringify(endDates));

    const formatToLocalDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    };

    const allFilters = {
      loggedInUserId: id,
      startDate: formatToLocalDate(selectionRange.startDate),
      endDate: formatToLocalDate(selectionRange.endDate),
    };

    const leaves = await LeaveService.findAllOfMine(allFilters);
    setHalfLeaveCounter(leaves.data.remainingHalfLeaves);
    setFilteredData(leaves.data.leave);
    setTableData(leaves.data.leave);

    closeDateModal();
  };

  const handleSelect = async (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const closeDateModal = () => {
    setIsDateModalOpen(false);
    const sidebar = document.querySelector('.sidebarParent');
    const navbar = document.querySelector('.main__navbar__right');
    if (sidebar) {
      sidebar.classList.remove('blurred__sidebar');
    }
    if (navbar) {
      navbar.classList.remove('blurred__sidebar');
    }
  };

  const openDateModal = () => {
    setIsDateModalOpen(true);
    const sidebar = document.querySelector('.sidebarParent');
    const navbar = document.querySelector('.main__navbar__right');
    if (sidebar) {
      sidebar.classList.add('blurred__sidebar');
    }
    if (navbar) {
      navbar.classList.add('blurred__sidebar');
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentId(null);
  };

  const closeModalWithDelete = async () => {
    if (currentId) {
      await LeaveService.delete(currentId);
      setIsModalOpen(false);

      const staticStartDate = localStorage.getItem('staticStartDateLeave');
      const startDates = JSON.parse(staticStartDate);
      const startDate = new Date(startDates);

      const staticEndDate = localStorage.getItem('staticEndDateLeave');
      const endDates = JSON.parse(staticEndDate);
      const endDate = new Date(endDates);

      const selectionRanges = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      };

      setSelectionRange(selectionRanges);

      const isSameDate =
        startDate.getDate() === endDate.getDate() &&
        startDate.getMonth() === endDate.getMonth() &&
        startDate.getFullYear() === endDate.getFullYear();

      let formattedDate = '';

      if (isSameDate) {
        formattedDate = startDate.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });
      } else {
        const sameMonth = startDate.getMonth() === endDate.getMonth();
        const sameYear = startDate.getFullYear() === endDate.getFullYear();

        if (sameMonth && sameYear) {
          formattedDate = `${startDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
          })} - ${endDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}`;
        } else if (!sameMonth && sameYear) {
          formattedDate = `${startDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
          })} - ${endDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}`;
        } else {
          formattedDate = `${startDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
          })} - ${endDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}`;
        }
      }

      const formatToLocalDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`; // Format: "YYYY-MM-DD"
      };

      setSelectedDate(formattedDate);

      const allFilters = {
        loggedInUserId: id,
        startDate: formatToLocalDate(startDate),
        endDate: formatToLocalDate(endDate),
      };

      const leaves = await LeaveService.findAllOfMine(allFilters);

      setHalfLeaveCounter(leaves.data.remainingHalfLeaves);
      setFilteredData(leaves.data.leave);
      setTableData(leaves.data.leave);
    }
  };

  const handleViewLeave = async (id) => {
    history.push(`/MyLeaves/view/${id}`);
  };

  const handleEditLeave = async (id) => {
    history.push(`/MyLeaves/edit/${id}`);
  };

  const handleDeleteLeave = async (id) => {
    setIsModalOpen(true);
    setCurrentId(id);
  };

  function handleDownloadPdf() {
    const table = document.getElementById('your-table-id');

    if (table) {
      const container = document.createElement('div');

      const header = document.createElement('div');
      header.innerHTML =
        '<img src="/logo.png" style="display: block; margin: 0 auto;"></img>';
      container.appendChild(header);

      const beforeTable = document.createElement('div');
      beforeTable.innerHTML = '<p style="text-align: center;"></p>';
      container.appendChild(beforeTable);

      const clonedTable = table.cloneNode(true) as HTMLElement;

      const rows = clonedTable.querySelectorAll('tr');
      rows.forEach((row) => {
        const lastCell = row.lastElementChild;
        lastCell?.remove();
      });

      container.appendChild(clonedTable);

      const afterTable = document.createElement('div');
      afterTable.innerHTML =
        '<div style="margin-top:5%;display:flex;flex-direction:row;justify-content:space-around;border-top:1px solid black"><div style="width:30%"><p style="color:#444444">2nd Floor, 38 Central Commercial Plaza, Bahria Orchard, Lahore</p></div><div><p style="color:#444444">ceo@ainsofttech.com</p></div><div><p style="color:#444444">+92 301 8424 988</p></div></div>';
      container.appendChild(afterTable);

      const footer = document.createElement('div');
      footer.innerHTML = '<p style="height:20px"><br></p>';
      container.appendChild(footer);

      // Set CSS styles to avoid content overlap
      const style = document.createElement('style');
      style.innerHTML = `
            .page-break {
                page-break-before: always;
            }
            .container {
                margin-bottom: 50px; /* Ensure there is enough margin */
            }
            tr {
                page-break-inside: avoid; /* Prevent row break */
            }
        `;
      container.appendChild(style);

      // Add a page break to avoid overlap
      const pageBreak = document.createElement('div');
      pageBreak.className = 'page-break';
      container.appendChild(pageBreak);

      const htmlString = container.innerHTML;

      html2pdf()
        .from(htmlString)
        .set({
          margin: 10,
          filename: 'leaves.pdf',
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        })
        .save();
    }
  }

  const setThisYearRange = () => {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const endOfYear = new Date(now.getFullYear(), 11, 31);
    setSelectionRange({
      startDate: startOfYear,
      endDate: endOfYear,
      key: 'selection',
    });

    const formattedDate = `${startOfYear.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })} - ${endOfYear.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })}`;

    setSelectedDate(formattedDate);
  };

  const setLastYearRange = () => {
    const now = new Date();
    const startOfLastYear = new Date(now.getFullYear() - 1, 0, 1);
    const endOfLastYear = new Date(now.getFullYear() - 1, 11, 31);
    setSelectionRange({
      startDate: startOfLastYear,
      endDate: endOfLastYear,
      key: 'selection',
    });

    const formattedDate = `${startOfLastYear.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })} - ${endOfLastYear.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })}`;

    setSelectedDate(formattedDate);
  };

  const handleSelectedStatus = async (event) => {
    const { value } = event.target;
    setSelectedStatus(value);

    if (selectedDuration === 'all') {
      if (value === 'all') {
        setFilteredData(tableData);
      } else {
        const approvedhalfLeaves = tableData.filter((leave) => {
          return leave['leaveStatus'].toLowerCase() === value;
        });

        setFilteredData(approvedhalfLeaves);
      }
    } else {
      if (value === 'all') {
        if (selectedDuration === 'half_day') {
          const approvedhalfLeaves = tableData.filter((leave) => {
            return (
              leave['leaveTenure'].toLowerCase() === 'first_half' ||
              leave['leaveTenure'].toLowerCase() === 'second_half'
            );
          });

          setFilteredData(approvedhalfLeaves);
        } else {
          const approvedhalfLeaves = tableData.filter((leave) => {
            return leave['leaveTenure'].toLowerCase() === selectedDuration;
          });

          setFilteredData(approvedhalfLeaves);
        }
      } else {
        if (selectedDuration === 'half_day') {
          const approvedhalfLeaves = tableData.filter((leave) => {
            return (
              leave['leaveStatus'].toLowerCase() === value &&
              (leave['leaveTenure'].toLowerCase() === 'first_half' ||
                leave['leaveTenure'].toLowerCase() === 'second_half')
            );
          });

          setFilteredData(approvedhalfLeaves);
        } else {
          const approvedhalfLeaves = tableData.filter((leave) => {
            return (
              leave['leaveStatus'].toLowerCase() === value &&
              leave['leaveTenure'].toLowerCase() === selectedDuration
            );
          });

          setFilteredData(approvedhalfLeaves);
        }
      }
    }
  };

  const handleSelectDuration = async (event) => {
    const { value } = event.target;
    setSelectedDuration(value);

    if (selectedStatus === 'all') {
      if (value === 'all') {
        setFilteredData(tableData);
      } else {
        if (value === 'half_day') {
          const approvedhalfLeaves = tableData.filter((leave) => {
            return (
              leave['leaveTenure'].toLowerCase() === 'first_half' ||
              leave['leaveTenure'].toLowerCase() === 'second_half'
            );
          });

          setFilteredData(approvedhalfLeaves);
        } else {
          const approvedhalfLeaves = tableData.filter((leave) => {
            return leave['leaveTenure'].toLowerCase() === value;
          });

          setFilteredData(approvedhalfLeaves);
        }
      }
    } else {
      if (selectedStatus === 'all') {
        if (value === 'half_day') {
          const approvedhalfLeaves = tableData.filter((leave) => {
            return (
              leave['leaveTenure'].toLowerCase() === 'first_half' ||
              leave['leaveTenure'].toLowerCase() === 'second_half'
            );
          });

          setFilteredData(approvedhalfLeaves);
        } else {
          const approvedhalfLeaves = tableData.filter((leave) => {
            return leave['leaveTenure'].toLowerCase() === value;
          });

          setFilteredData(approvedhalfLeaves);
        }
      } else {
        if (value === 'half_day') {
          const approvedhalfLeaves = tableData.filter((leave) => {
            return (
              leave['leaveStatus'].toLowerCase() === selectedStatus &&
              (leave['leaveTenure'].toLowerCase() === 'first_half' ||
                leave['leaveTenure'].toLowerCase() === 'second_half')
            );
          });

          setFilteredData(approvedhalfLeaves);
        } else {
          const approvedhalfLeaves = tableData.filter((leave) => {
            return (
              leave['leaveStatus'].toLowerCase() === selectedStatus &&
              leave['leaveTenure'].toLowerCase() === value
            );
          });

          setFilteredData(approvedhalfLeaves);
        }
      }
    }
  };

  return (
    <Layout>
      <div className="leavesIndex">
        <div className="leavesIndex__header" style={{ paddingBottom: 0 }}>
          <div className="leavesIndex__header_left">
            <h4 className="attendance_overview_heading">My Leaves</h4>
          </div>
        </div>
        <div className="leavesIndex__header">
          <div className="leavesIndex__header_left">
            <div className="remaingLeavesCounter remaingLeavesCounterLeaveDetails">
              <p className="attendance_overview_sub_heading">
                Remaining Leaves:
              </p>

              <div>
                <div className="singleLeavesCounter">
                  <p className="remaingLeaversCounter">
                    Half: {halfLeaveCounter}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="leavesIndex__header">
          <div className="leavesIndex__header_left">
            <div className="remaingLeavesCounter">
              <div className="remaingLeavesCounterEachAttendance">
                <div className="singleInputFieldFilter">
                  <FormControl variant="standard">
                    <OutlinedInput
                      className="searchNameFilter"
                      placeholder="Select Date"
                      onClick={openDateModal}
                      value={selectedDate || ''}
                      readOnly
                    />
                  </FormControl>
                </div>
              </div>
              <div className="remaingLeavesCounterEach inventoryFilterFields">
                <div className="singleInputField singleInputFieldFilter">
                  <select
                    name="leave_type"
                    id="leave_type"
                    className="singleInputFilter"
                    onChange={handleSelectDuration}
                    value={selectedDuration}
                  >
                    <option value="" disabled style={{ display: 'none' }}>
                      Select Duration
                    </option>
                    <option value="full_day">Full Day</option>
                    <option value="half_day">Half Day</option>
                    <option value="short_leave">Short Leave</option>
                    <option value="all">All</option>
                  </select>
                </div>
              </div>
              <div
                style={{ marginLeft: '0.5vw' }}
                className="remaingLeavesCounterEach inventoryFilterFields"
              >
                <div className="singleInputField singleInputFieldFilter">
                  <select
                    name="leave_type"
                    id="leave_type"
                    className="singleInputFilter"
                    onChange={handleSelectedStatus}
                    value={selectedStatus}
                  >
                    <option value="all">All</option>
                    <option value="pending">Pending</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                    <option value="all">All</option>
                  </select>
                </div>
              </div>

              <div
                className="remaingLeavesCounterEachAttendance"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <button
                  onClick={handleDownloadPdf}
                  className="downloadPDFButton downloadPDFButton2 bg-custom hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Download PDF
                </button>
              </div>
            </div>
          </div>
        </div>
        <Card className="myLeaves__card" variant="outlined">
          <div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              {sortedItems.length > 0 ? (
                <TableContainer>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    id="your-table-id"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{ textAlign: 'center' }}
                          >
                            {column.sortable ? (
                              <TableSortLabel
                                active={sortBy === column.id}
                                direction={
                                  sortBy === column.id ? sortOrder : 'asc'
                                }
                                onClick={() => handleSort(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                            ) : (
                              column.label
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedItems.map((row, index) => {
                        const serialNumber = indexOfFirstItem + index + 1;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={serialNumber}
                          >
                            <TableCell
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              {serialNumber}
                            </TableCell>
                            {columns.slice(1).map((column) => {
                              const value = row[column.id];
                              if (column.id === 'leaveTenure') {
                                return row.specialRequestId ? (
                                  <TableCell
                                    style={{
                                      display: 'flex',
                                      position: 'relative',
                                      textAlign: 'center',
                                      flexDirection: 'column-reverse',
                                    }}
                                    key={column.id}
                                  >
                                    <span>{value}</span>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                      }}
                                    >
                                      <a
                                        style={{ cursor: 'pointer' }}
                                        href={`/MyLeaves/view/${row.leaveId}`}
                                      >
                                        <span
                                          style={{
                                            backgroundColor:
                                              'rgb(255, 206, 30)',
                                            fontSize: '10px',
                                            padding: '2px 3px',
                                            borderRadius: '8px',
                                            display: 'inline-block',
                                          }}
                                        >
                                          {row.reason === 'missed_leave'
                                            ? 'M.L'
                                            : 'S.L'}
                                        </span>
                                      </a>
                                    </div>
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    style={{
                                      textAlign: 'center',
                                    }}
                                    key={column.id}
                                  >
                                    {value} {row.reason}
                                  </TableCell>
                                );
                              }
                              if (column.id === 'leaveStatus') {
                                if (value === 'approved') {
                                  return (
                                    <TableCell
                                      style={{
                                        color: '#45a259',
                                        textAlign: 'center',
                                      }}
                                      key={column.id}
                                    >
                                      {value}
                                    </TableCell>
                                  );
                                } else if (value === 'rejected') {
                                  return (
                                    <TableCell
                                      style={{
                                        color: '#d7364d',
                                        textAlign: 'center',
                                      }}
                                      key={column.id}
                                    >
                                      {value}
                                    </TableCell>
                                  );
                                } else {
                                  return (
                                    <TableCell
                                      style={{
                                        color: '#0066ff',
                                        textAlign: 'center',
                                      }}
                                      key={column.id}
                                    >
                                      {value}
                                    </TableCell>
                                  );
                                }
                              } else if (column.id === 'approvedBy') {
                                if (value) {
                                  return (
                                    <TableCell
                                      style={{
                                        textAlign: 'center',
                                      }}
                                      key={column.id}
                                    >
                                      {value}
                                    </TableCell>
                                  );
                                } else {
                                  return (
                                    <TableCell
                                      style={{
                                        textAlign: 'center',
                                      }}
                                      key={column.id}
                                    >
                                      -
                                    </TableCell>
                                  );
                                }
                              } else if (column.id === 'details') {
                                return (
                                  <TableCell
                                    style={{
                                      textAlign: 'center',
                                    }}
                                    key={column.id}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      {/* view */}
                                      <div>
                                        <button
                                          style={{
                                            border: 'none',
                                            color: '#0066ff',
                                            backgroundColor: 'transparent',
                                            cursor: 'pointer',
                                          }}
                                          onClick={() =>
                                            handleViewLeave(row['leaveId'])
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 26 26"
                                          >
                                            <g data-name="Group 295">
                                              <path
                                                data-name="visibility_FILL0_wght300_GRAD0_opsz24 (1)"
                                                d="M13.003 16.95a4.236 4.236 0 0 0 3.115-1.284 4.248 4.248 0 0 0 1.281-3.117 4.236 4.236 0 0 0-1.284-3.115 4.248 4.248 0 0 0-3.117-1.281 4.236 4.236 0 0 0-3.115 1.284 4.248 4.248 0 0 0-1.281 3.117 4.236 4.236 0 0 0 1.284 3.115 4.248 4.248 0 0 0 3.117 1.281zm0-1.485a2.809 2.809 0 0 1-2.063-.85 2.809 2.809 0 0 1-.85-2.063 2.809 2.809 0 0 1 .85-2.063 2.809 2.809 0 0 1 2.063-.85 2.809 2.809 0 0 1 2.063.85 2.809 2.809 0 0 1 .85 2.063 2.809 2.809 0 0 1-.85 2.063 2.809 2.809 0 0 1-2.065.85zm0 4.639a11.281 11.281 0 0 1-6.231-1.817 13.565 13.565 0 0 1-4.509-4.782 1.9 1.9 0 0 1-.2-.468 1.892 1.892 0 0 1-.062-.484 1.884 1.884 0 0 1 .062-.484 1.9 1.9 0 0 1 .2-.468 13.564 13.564 0 0 1 4.509-4.782 11.281 11.281 0 0 1 6.229-1.82 11.281 11.281 0 0 1 6.231 1.818 13.564 13.564 0 0 1 4.514 4.782 1.9 1.9 0 0 1 .2.468 1.893 1.893 0 0 1 .062.484 1.885 1.885 0 0 1-.062.484 1.9 1.9 0 0 1-.2.468 13.565 13.565 0 0 1-4.509 4.782 11.281 11.281 0 0 1-6.236 1.814zm-.002-7.552zm0 5.934a10.3 10.3 0 0 0 5.6-1.6 10.543 10.543 0 0 0 3.9-4.329 10.544 10.544 0 0 0-3.9-4.329 10.3 10.3 0 0 0-5.6-1.6 10.3 10.3 0 0 0-5.6 1.6 10.544 10.544 0 0 0-3.9 4.329 10.543 10.543 0 0 0 3.9 4.329 10.3 10.3 0 0 0 5.6 1.6z"
                                              />
                                              <path
                                                data-name="Rectangle 293"
                                                fill="none"
                                                d="M0 0h26v26H0z"
                                              />
                                            </g>
                                          </svg>
                                        </button>
                                      </div>
                                      {/* edit */}
                                      {row['usersId'] ===
                                        parseInt(currentUserId) && (
                                        <div style={{ margin: '0 0.5vw' }}>
                                          {row['leaveStatus'] === 'pending' ? (
                                            <button
                                              style={{
                                                border: 'none',
                                                color: '#0066ff',
                                                backgroundColor: 'transparent',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() =>
                                                handleEditLeave(row['leaveId'])
                                              }
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 26 26"
                                              >
                                                <g data-name="Group 303">
                                                  <path d="M4.099 24a2.027 2.027 0 0 1-1.49-.61 2.027 2.027 0 0 1-.61-1.49V6.352a2.027 2.027 0 0 1 .61-1.49 2.027 2.027 0 0 1 1.49-.61h7.96a.785.785 0 0 1 .653.274.939.939 0 0 1 .218.6.917.917 0 0 1-.227.6.811.811 0 0 1-.662.274H4.099a.342.342 0 0 0-.246.112.342.342 0 0 0-.112.246v15.548a.342.342 0 0 0 .112.246.342.342 0 0 0 .246.112h15.548a.342.342 0 0 0 .246-.112.342.342 0 0 0 .112-.246V13.89a.785.785 0 0 1 .274-.653.939.939 0 0 1 .6-.218.939.939 0 0 1 .6.218.785.785 0 0 1 .274.653v8.015a2.027 2.027 0 0 1-.61 1.49 2.027 2.027 0 0 1-1.49.61zm7.775-9.874zm-2.9 1.854v-2.069a2.062 2.062 0 0 1 .163-.808 2.084 2.084 0 0 1 .449-.674l9.912-9.912a1.7 1.7 0 0 1 .594-.394 1.83 1.83 0 0 1 .659-.124 1.778 1.778 0 0 1 .657.124 1.678 1.678 0 0 1 .574.383l1.459 1.454a1.98 1.98 0 0 1 .388.6 1.713 1.713 0 0 1 .135.661 1.881 1.881 0 0 1-.115.65 1.609 1.609 0 0 1-.385.585l-9.946 9.946a2.209 2.209 0 0 1-.674.458 1.97 1.97 0 0 1-.808.172h-2.013a1.017 1.017 0 0 1-.749-.3 1.017 1.017 0 0 1-.304-.752zM22.239 5.23 20.78 3.738zM10.716 15.287h1.448l7.24-7.24-.724-.724-.775-.746-7.189 7.189zm7.964-7.964-.775-.746.775.746.724.724z" />
                                                  <path
                                                    data-name="Rectangle 294"
                                                    fill="none"
                                                    d="M0 0h26v26H0z"
                                                  />
                                                </g>
                                              </svg>
                                            </button>
                                          ) : (
                                            <button
                                              style={{
                                                border: 'none',
                                                color: '#0066ff',
                                                backgroundColor: 'transparent',
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 26 26"
                                              >
                                                <g data-name="Group 296">
                                                  <path
                                                    d="M142.1-875.537a2.027 2.027 0 0 1-1.49-.61 2.027 2.027 0 0 1-.61-1.49v-15.548a2.027 2.027 0 0 1 .61-1.49 2.027 2.027 0 0 1 1.49-.61h7.96a.785.785 0 0 1 .653.274.939.939 0 0 1 .218.6.917.917 0 0 1-.227.6.811.811 0 0 1-.662.274H142.1a.342.342 0 0 0-.246.112.342.342 0 0 0-.112.246v15.548a.342.342 0 0 0 .112.246.342.342 0 0 0 .246.112h15.548a.342.342 0 0 0 .246-.112.342.342 0 0 0 .112-.246v-8.016a.785.785 0 0 1 .274-.653.939.939 0 0 1 .6-.218.939.939 0 0 1 .6.218.785.785 0 0 1 .274.653v8.015a2.027 2.027 0 0 1-.61 1.49 2.027 2.027 0 0 1-1.49.61zm7.775-9.874zm-2.9 1.854v-2.069a2.062 2.062 0 0 1 .163-.808 2.084 2.084 0 0 1 .449-.674l9.912-9.912a1.7 1.7 0 0 1 .594-.394 1.83 1.83 0 0 1 .659-.124 1.778 1.778 0 0 1 .657.124 1.678 1.678 0 0 1 .574.383l1.459 1.454a1.98 1.98 0 0 1 .388.6 1.713 1.713 0 0 1 .135.661 1.881 1.881 0 0 1-.115.65 1.609 1.609 0 0 1-.385.585l-9.946 9.946a2.209 2.209 0 0 1-.674.458 1.97 1.97 0 0 1-.808.172h-2.013a1.017 1.017 0 0 1-.749-.3 1.017 1.017 0 0 1-.304-.752zm13.265-10.75-1.459-1.492zm-11.523 10.057h1.448l7.24-7.24-.724-.724-.775-.746-7.189 7.189zm7.964-7.964-.775-.746.775.746.724.724z"
                                                    transform="translate(-138.001 899.537)"
                                                    style={{ opacity: '.2' }}
                                                  />
                                                  <path
                                                    data-name="Rectangle 294"
                                                    fill="none"
                                                    d="M0 0h26v26H0z"
                                                  />
                                                </g>
                                              </svg>
                                            </button>
                                          )}
                                        </div>
                                      )}
                                      {/* delete */}
                                      {row['usersId'] ===
                                        parseInt(currentUserId) && (
                                        <div>
                                          {row['leaveStatus'] === 'pending' ? (
                                            <button
                                              style={{
                                                border: 'none',
                                                color: '#0066ff',
                                                backgroundColor: 'transparent',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() =>
                                                handleDeleteLeave(
                                                  row['leaveId'],
                                                )
                                              }
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 26 26"
                                              >
                                                <g data-name="Group 302">
                                                  <path
                                                    data-name="delete_FILL0_wght300_GRAD0_opsz24 (1)"
                                                    d="M6.658 24a2.267 2.267 0 0 1-1.663-.692 2.268 2.268 0 0 1-.692-1.663V5.107h-.326a.945.945 0 0 1-.7-.281.946.946 0 0 1-.281-.7.944.944 0 0 1 .281-.7.946.946 0 0 1 .7-.281h4.886a1.111 1.111 0 0 1 .337-.816 1.111 1.111 0 0 1 .816-.337h5.513a1.111 1.111 0 0 1 .816.337 1.111 1.111 0 0 1 .337.816h4.886a.945.945 0 0 1 .7.281.947.947 0 0 1 .281.7.945.945 0 0 1-.281.7.946.946 0 0 1-.7.281h-.326v16.538a2.268 2.268 0 0 1-.692 1.663 2.267 2.267 0 0 1-1.663.692zM19.287 5.107H6.257v16.538a.39.39 0 0 0 .113.288.39.39 0 0 0 .288.113h12.228a.39.39 0 0 0 .288-.113.39.39 0 0 0 .113-.288zm-8.92 14.333a.944.944 0 0 0 .7-.281.946.946 0 0 0 .281-.7V8.687a.945.945 0 0 0-.281-.7.946.946 0 0 0-.7-.281.945.945 0 0 0-.7.281.946.946 0 0 0-.281.7v9.772a.946.946 0 0 0 .281.7.946.946 0 0 0 .7.281zm4.811 0a.945.945 0 0 0 .7-.281.946.946 0 0 0 .281-.7V8.687a.945.945 0 0 0-.281-.7.946.946 0 0 0-.7-.281.945.945 0 0 0-.7.281.946.946 0 0 0-.281.7v9.772a.945.945 0 0 0 .281.7.946.946 0 0 0 .7.281zM6.257 5.107z"
                                                  />
                                                  <path
                                                    data-name="Rectangle 292"
                                                    fill="none"
                                                    d="M0 0h26v26H0z"
                                                  />
                                                </g>
                                              </svg>
                                            </button>
                                          ) : (
                                            <button
                                              style={{
                                                border: 'none',
                                                color: '#0066ff',
                                                backgroundColor: 'transparent',
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 26 26"
                                              >
                                                <g data-name="Group 297">
                                                  <path
                                                    data-name="delete_FILL0_wght300_GRAD0_opsz24 (1)"
                                                    d="M183.659-793.383a2.267 2.267 0 0 1-1.663-.692 2.268 2.268 0 0 1-.692-1.663v-16.538h-.326a.945.945 0 0 1-.7-.281.946.946 0 0 1-.281-.7.944.944 0 0 1 .281-.7.946.946 0 0 1 .7-.281h4.886a1.111 1.111 0 0 1 .337-.816 1.111 1.111 0 0 1 .816-.337h5.513a1.111 1.111 0 0 1 .816.337 1.111 1.111 0 0 1 .337.816h4.886a.945.945 0 0 1 .7.281.947.947 0 0 1 .281.7.945.945 0 0 1-.281.7.946.946 0 0 1-.7.281h-.326v16.538a2.268 2.268 0 0 1-.692 1.663 2.267 2.267 0 0 1-1.663.692zm12.629-18.893h-13.03v16.538a.39.39 0 0 0 .113.288.39.39 0 0 0 .288.113h12.228a.39.39 0 0 0 .288-.113.39.39 0 0 0 .113-.288zm-8.92 14.333a.944.944 0 0 0 .7-.281.946.946 0 0 0 .281-.7v-9.772a.945.945 0 0 0-.281-.7.946.946 0 0 0-.7-.281.945.945 0 0 0-.7.281.946.946 0 0 0-.281.7v9.772a.946.946 0 0 0 .281.7.946.946 0 0 0 .7.281zm4.811 0a.945.945 0 0 0 .7-.281.946.946 0 0 0 .281-.7v-9.772a.945.945 0 0 0-.281-.7.946.946 0 0 0-.7-.281.945.945 0 0 0-.7.281.946.946 0 0 0-.281.7v9.772a.945.945 0 0 0 .281.7.946.946 0 0 0 .7.281zm-8.921-14.333z"
                                                    transform="translate(-176.001 817.383)"
                                                    style={{ opacity: '.2' }}
                                                  />
                                                  <path
                                                    data-name="Rectangle 292"
                                                    fill="none"
                                                    d="M0 0h26v26H0z"
                                                  />
                                                </g>
                                              </svg>
                                            </button>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </TableCell>
                                );
                              } else if (column.id === 'usersName') {
                                return (
                                  <TableCell
                                    className="truncate"
                                    key={column.id}
                                  >
                                    {value}
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell
                                    style={{
                                      textAlign: 'center',
                                    }}
                                    key={column.id}
                                  >
                                    {value}
                                  </TableCell>
                                );
                              }
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                // If no records found
                <TableContainer>
                  <Table
                    className="MyLeavesTable"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          style={{ textAlign: 'center', padding: '20px' }}
                        >
                          No records found
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {sortedItems.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Paper>
          </div>
        </Card>

        <RejectionModal isOpen={isModalOpen} onClose={closeModal}>
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div
                className="ValidationModal"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span className="approvalText">
                  Are you sure that you want to delete that leave request ?
                </span>

                <div className="CloseRejectionModalButton">
                  <button
                    type="button"
                    className="CloseRejectionModal"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="rejectButtonValidationModal"
                    onClick={closeModalWithDelete}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </RejectionModal>
        <DatePickerModal isOpen={isDateModalOpen} onClose={closeDateModal}>
          <div className="modal__popup">
            <div className="date-filter-container">
              <div
                className="date-pickers"
                style={{ display: 'flex', gap: '16px' }}
              >
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={handleSelect}
                />
              </div>
              <div
                className="preset-buttons"
                style={{ margin: '16px 0', display: 'flex', gap: '8px' }}
              >
                <Button variant="outlined" onClick={setThisYearRange}>
                  This Year
                </Button>
                <Button variant="outlined" onClick={setLastYearRange}>
                  Last Year
                </Button>
              </div>
              <div
                className="action-buttons"
                style={{ marginTop: '16px', textAlign: 'right' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleApply}
                >
                  Apply
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={closeDateModal}
                  style={{ marginLeft: '8px' }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </DatePickerModal>
      </div>
    </Layout>
  );
}
