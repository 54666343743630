import {
  AttachFile as AttachFileIcon,
  Cancel as CancelIcon,
  LocationOn as LocationOnIcon,
} from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Chip, IconButton, Stack, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import OutlinedInput from '@mui/material/OutlinedInput';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Layout from '../../components/layout';
import AnnouncementService from '../../services/AnnouncementService';
import ValidationModal from '../BootstrapValidationModal';

export default function EditAnnouncement() {
  const history = useHistory();
  const { id } = useParams() as { id: string };

  const [progress, setProgress] = React.useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingModalOpen, setIsSubmittingModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);

  const [title, setTitle] = useState('');
  const [location, setLocation] = useState('');
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);
  const [descriptionText, setDescriptionText] = useState('');

  const [titleError, setTitleError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [guestName, setGuestName] = useState('');
  const [guests, setGuests] = useState([]);
  const [imageUrl, setImageUrl] = useState('');

  const [toDateError, setToDateError] = useState(false);
  const [dateErrorText, setDateErrorText] = useState('');

  useEffect(() => {
    let intervalId;
    let isMounted = true;

    const startProgress = () => {
      intervalId = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress = oldProgress + 100 / steps;
          if (newProgress >= 100) {
            clearInterval(intervalId);
            setTimeout(() => {
              if (isMounted) {
                setIsSubmittingModalOpen(false);
                history.push(`/announcement`);
              }
            }, 500);
            return 100;
          }
          return newProgress;
        });
      }, intervalDuration);
    };

    const intervalDuration = 30;
    const totalTime = 1000;
    const steps = totalTime / intervalDuration;

    if (isSubmittingModalOpen) {
      startProgress();
    }

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [isSubmittingModalOpen]);

  useEffect(() => {
    const fetchAnnouncementData = async () => {
      const announcement = await AnnouncementService.findOne(id);
      setTitle(announcement.title);
      setDescriptionText(announcement.description);
      setLocation(announcement.location);
      setFileName(announcement.file);
      if (announcement.guests.length > 0) {
        setGuests(announcement.guests);
      }

      const dateObject = dayjs(announcement.time);
      const toDateObject = dayjs(announcement.toTime);

      setSelectedDate(dateObject);
      setSelectedToDate(toDateObject);
      const fileName = announcement.file;
      if (fileName) {
        const extension = fileName.split('.').pop();

        try {
          const response = await fetch(
            `https://hrportals.ainsofttech.com/api/api/announcement/image/${id}.${extension}`,
          );
          setImageUrl(response.url);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchAnnouncementData();
  }, [id]);

  const onSubmit = async (record) => {
    if (title === '') {
      setTitleError(true);
      setIsModalOpen(true);
      setIsSubmitting(false);
      return;
    } else {
      setTitleError(false);
      setIsModalOpen(false);
      setIsSubmitting(false);
    }

    if (!selectedDate) {
      setDateError(true);
      setIsModalOpen(true);
      setIsSubmitting(false);
      return;
    } else {
      setDateError(false);
      setIsModalOpen(false);
      setIsSubmitting(false);
    }

    if (!selectedToDate) {
      setToDateError(true);
      setIsModalOpen(true);
      setIsSubmitting(false);
      return;
    } else {
      setToDateError(false);
      setIsModalOpen(false);
      setIsSubmitting(false);
    }

    if (descriptionText === '') {
      setDescriptionError(true);
      setIsModalOpen(true);
      setIsSubmitting(false);
      return;
    } else {
      setDescriptionError(false);
      setIsModalOpen(false);
      setIsSubmitting(false);
    }

    if (selectedToDate && selectedDate) {
      const isValid = selectedToDate.isAfter(selectedDate);

      if (!isValid) {
        setDateErrorText('To Time must be greater than From Time');
        setToDateError(true);
        setIsModalOpen(true);
        return;
      } else {
        setToDateError(false);
        setDateErrorText('');
        setIsModalOpen(false);
      }
    }

    setIsSubmitting(true);

    try {
      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);

      const formData = new FormData();
      formData.append('time', selectedDate.toISOString());
      formData.append('toTime', selectedToDate.toISOString());

      formData.append('title', title);
      formData.append('description', descriptionText);
      formData.append('usersId', parsedUser.id);
      formData.append('location', location || '');
      formData.append('guestsFromFrontend', JSON.stringify(guests)); // Convert guests array to a string
      if (file) {
        formData.append('file', file);
      }
      await AnnouncementService.update(+id, formData);
      setIsSubmittingModalOpen(true);
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const closeSubmittingModal = () => {
    setIsSubmittingModalOpen(false);
  };

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    if (name === 'specific_item') {
      setTitle(value);
      if (value !== '') {
        setTitleError(false);
      }
    }

    if (name === 'location') {
      setLocation(value);
    }
  };

  const handleRemoveGuest = (guestToRemove) => {
    setGuests(guests.filter((guest) => guest !== guestToRemove));
  };

  const handleAddGuest = (event) => {
    if (event.key === 'Enter' && guestName.trim()) {
      setGuests([...guests, guestName.trim()]);
      setGuestName('');
    }
  };

  const handleEditGuest = (guestToEdit) => {
    setGuestName(guestToEdit);
    handleRemoveGuest(guestToEdit);
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      setFile(selectedFile);
    }
  };

  const handleRemoveFile = () => {
    setFileName('');
    setFile(null);
  };

  const handleChangeDescription = (e) => {
    const { value } = e.target;
    setDescriptionText(value);
    if (value !== '') {
      setDescriptionError(false);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date) {
      setDateError(false);
    }
  };

  const handleToDateChange = (date) => {
    setSelectedToDate(date);
    if (date) {
      setToDateError(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Layout>
      <div className="leavesIndex">
        <h4 className="attendance_overview_heading">Update Announcement</h4>

        <div className="leavesIndex__body">
          <div className="leavesIndex__body_padding">
            <Box
              component="form"
              noValidate
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr' },
                gap: 2,
              }}
            >
              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className="singleInputField singleInputAnnouncement">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Title *
                      </InputLabel>
                      <FormControl
                        className={`newLeaveScreen  ${
                          titleError ? 'leaveDurationInput-error' : ''
                        }`}
                        variant="standard"
                      >
                        <OutlinedInput
                          name="specific_item"
                          onChange={handleChange}
                          value={title}
                          placeholder="Announcement Title"
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '1vw',
                  }}
                >
                  <div className="singleInputField singleInputFieldWithoutBorder singleInputHoliday singleInputAnnouncement">
                    <InputLabel
                      className="formInputLabels"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      From Time *
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DateTimePicker']}>
                        <DateTimePicker
                          onChange={handleDateChange}
                          className={`leaveDurationInput newLeaveScreen announcementDateAndTime ${
                            dateError ? 'leaveDurationInput-error' : ''
                          }`}
                          value={selectedDate}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  {selectedDate ? (
                    <div className="singleInputField singleInputFieldWithoutBorder singleInputHoliday singleInputAnnouncement">
                      <InputLabel
                        className="formInputLabels"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        To Time *
                      </InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker']}>
                          <DateTimePicker
                            onChange={handleToDateChange}
                            className={`leaveDurationInput newLeaveScreen announcementDateAndTime ${
                              toDateError ? 'leaveDurationInput-error' : ''
                            }`}
                            value={selectedToDate}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  ) : (
                    <div className="singleInputField singleInputFieldWithoutBorder singleInputHoliday singleInputAnnouncement"></div>
                  )}
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '1vw',
                  }}
                >
                  <div className="singleInputField singleInputAnnouncement">
                    <InputLabel
                      className="formInputLabels"
                      shrink
                      htmlFor="location-input"
                    >
                      Location
                    </InputLabel>
                    <FormControl
                      className="newLeaveScreen newAnnouncement"
                      variant="standard"
                      fullWidth
                    >
                      <OutlinedInput
                        id="location-input"
                        name="location"
                        onChange={handleChange}
                        value={location}
                        placeholder="Enter Location"
                        autoComplete="off"
                        endAdornment={
                          !location && (
                            <Tooltip title="Location">
                              <IconButton edge="end" aria-label="location">
                                <LocationOnIcon />
                              </IconButton>
                            </Tooltip>
                          )
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="singleInputField singleInputAnnouncement">
                    <InputLabel
                      className="formInputLabels"
                      shrink
                      htmlFor="file-input"
                    >
                      Add Attachment
                    </InputLabel>
                    <FormControl
                      className="newLeaveScreen newAnnouncement"
                      variant="outlined"
                      fullWidth
                    >
                      <OutlinedInput
                        id="file-input"
                        value={fileName || ''}
                        placeholder="Upload Attachment"
                        readOnly
                        endAdornment={
                          <React.Fragment>
                            {fileName ? (
                              <>
                                <Tooltip title="Remove file">
                                  <IconButton
                                    edge="end"
                                    onClick={handleRemoveFile}
                                    aria-label="remove file"
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="View File">
                                  <IconButton
                                    edge="end"
                                    onClick={() =>
                                      window.open(
                                        imageUrl,
                                        '_blank',
                                        'noopener,noreferrer',
                                      )
                                    }
                                    aria-label="view file"
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              <Tooltip title="Add file">
                                <IconButton
                                  edge="end"
                                  onClick={() =>
                                    document
                                      .getElementById('file-upload')
                                      .click()
                                  }
                                  aria-label="add file"
                                >
                                  <AttachFileIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </React.Fragment>
                        }
                        onClick={() =>
                          document.getElementById('file-upload').click()
                        }
                      />
                      <input
                        id="file-upload"
                        type="file"
                        hidden
                        onChange={handleFileChange}
                      />
                    </FormControl>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '1vw',
                  }}
                >
                  <div className="singleInputField singleInputAnnouncement">
                    <InputLabel
                      className="formInputLabels"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Guests
                    </InputLabel>
                    <FormControl
                      className={`newLeaveScreen guestAnnouncement`}
                      variant="standard"
                    >
                      <OutlinedInput
                        name="guest"
                        value={guestName}
                        onChange={(e) => setGuestName(e.target.value)}
                        onKeyDown={handleAddGuest}
                        placeholder="Enter Names Of Guests And Press Enter"
                        autoComplete="off"
                      />
                    </FormControl>
                    <Stack
                      direction="row"
                      spacing={1}
                      flexWrap="wrap"
                      marginTop={2}
                    >
                      {guests.map((guest, index) => (
                        <Chip
                          key={index}
                          label={guest}
                          onClick={() => handleEditGuest(guest)}
                          onDelete={() => handleRemoveGuest(guest)}
                          deleteIcon={
                            <CancelIcon
                              style={{
                                color: '#15267d',
                                backgroundColor: 'transparent',
                              }}
                            />
                          }
                          sx={{
                            backgroundColor: 'transparent',
                            color: 'rgba(0, 0, 0, 0.6)',
                            border: '1px solid #15267d',
                            borderRadius: '2%',
                          }}
                        />
                      ))}
                    </Stack>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '1vw 0',
                  }}
                >
                  <div className="singleInputField singleInputFieldTextArea">
                    <InputLabel
                      className="formInputLabels"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Description
                    </InputLabel>
                    <FormControl variant="standard">
                      <textarea
                        className={`leaveDurationTextArea leaveDurationInput ${
                          descriptionError ? 'leaveDurationInput-error' : ''
                        }`}
                        onChange={handleChangeDescription}
                        value={descriptionText}
                        placeholder="Enter text here..."
                        rows={6}
                        cols={50}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputField"></div>
                <div className="singleInputField">
                  <div className="formButton">
                    <button
                      type="button"
                      disabled={isSubmitting}
                      className="formSubmitButton"
                      onClick={onSubmit}
                    >
                      {isSubmitting ? 'Updating...' : 'Update'}
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </div>

        <ValidationModal
          isOpen={isSubmittingModalOpen}
          onClose={closeSubmittingModal}
        >
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div className="ProgressModal">
                <Box className="progressOfSubmitting" sx={{ width: '100%' }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                      height: 15,
                      borderRadius: 10,
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#1fc214',
                      },
                    }}
                  />
                </Box>
                <span>Submitting Request</span>
              </div>
            </div>
          </div>
        </ValidationModal>
        <ValidationModal isOpen={isModalOpen} onClose={closeModal}>
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div className="ValidationModal">
                {dateErrorText === '' ? (
                  <span>Please Enter All Fields To Continue!</span>
                ) : (
                  <span>{dateErrorText}</span>
                )}

                <div className="CloseValidationModalButton">
                  <button
                    type="button"
                    disabled={isSubmitting}
                    className="CloseValidationModal"
                    onClick={closeModal}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ValidationModal>
      </div>
    </Layout>
  );
}
