import { MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import OutlinedInput from '@mui/material/OutlinedInput';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../../components/layout';
import HolidayService from '../../services/HolidayService';
import ValidationModal from '../BootstrapValidationModal';

export default function NewHoliday() {
  const history = useHistory();

  const [progress, setProgress] = React.useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingModalOpen, setIsSubmittingModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [title, setTitle] = useState('');
  const [descriptionText, setDescriptionText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [officeCity, setOfficeCity] = useState('both');

  const [titleError, setTitleError] = useState(false);
  const [selectedFromDateError, setSelectedFromDateError] = useState(false);
  const [selectedToDateError, setSelectedToDateError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [validDateError, setValidDateError] = useState(false);

  useEffect(() => {
    let intervalId;
    let isMounted = true;

    const startProgress = () => {
      intervalId = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress = oldProgress + 100 / steps;
          if (newProgress >= 100) {
            clearInterval(intervalId);
            setTimeout(() => {
              if (isMounted) {
                setIsSubmittingModalOpen(false);
                history.push(`/holiday`);
              }
            }, 500);
            return 100;
          }
          return newProgress;
        });
      }, intervalDuration);
    };

    const intervalDuration = 30;
    const totalTime = 1000;
    const steps = totalTime / intervalDuration;

    if (isSubmittingModalOpen) {
      startProgress();
    }

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [isSubmittingModalOpen]);

  const onSubmit = async (record) => {
    if (title === '') {
      setTitleError(true);
      setIsModalOpen(true);
      setIsSubmitting(false);
      return;
    } else {
      setTitleError(false);
      setIsModalOpen(false);
      setIsSubmitting(false);
    }

    if (!selectedFromDate) {
      setSelectedFromDateError(true);
      setIsModalOpen(true);
      setIsSubmitting(false);
      return;
    } else {
      setSelectedFromDateError(false);
      setIsModalOpen(false);
      setIsSubmitting(false);
    }

    if (!selectedToDate) {
      setSelectedToDateError(true);
      setIsModalOpen(true);
      setIsSubmitting(false);
      return;
    } else {
      setSelectedToDateError(false);
      setIsModalOpen(false);
      setIsSubmitting(false);
    }

    const isValid =
      selectedToDate.$d.getTime() >= selectedFromDate.$d.getTime();

    if (!isValid) {
      setIsModalOpen(true);
      setValidDateError(true);
      setSelectedFromDateError(true);
      setSelectedToDateError(true);
      setErrorMessage('To Date should be greater than From Date');

      return;
    } else {
      setValidDateError(false);
      setSelectedFromDateError(false);
      setSelectedToDateError(false);
      setErrorMessage('');
    }

    if (descriptionText === '') {
      setDescriptionError(true);
      setIsModalOpen(true);
      setIsSubmitting(false);
      return;
    } else {
      setDescriptionError(false);
      setIsModalOpen(false);
      setIsSubmitting(false);
    }

    setIsSubmitting(true);
    try {
      const fromDateTime = new Date(selectedFromDate);
      fromDateTime.setHours(12, 0, 0, 0);

      const toDateTime = new Date(selectedToDate);
      toDateTime.setHours(12, 0, 0, 0);

      const data = {
        from_date: fromDateTime,
        to_date: toDateTime,
        name: title,
        description: descriptionText,
        officeCity: officeCity,
      };

      await HolidayService.save(data);
      setIsSubmittingModalOpen(true);
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const closeSubmittingModal = () => {
    setIsSubmittingModalOpen(false);
  };

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { value } = e.target;
    setTitle(value);
    if (value !== '') {
      setTitleError(false);
    }
  };

  const handleChangeOfficeCity = (e: { target: { name: any; value: any } }) => {
    const { value } = e.target;
    setOfficeCity(value);
  };

  const handleChangeDescription = (e) => {
    const { value } = e.target;
    setDescriptionText(value);
    if (value !== '') {
      setDescriptionError(false);
    }
  };

  const handleFromDateChange = (date) => {
    setSelectedFromDate(date);
    if (date) {
      setSelectedFromDateError(false);
    }
  };

  const handleToDateChange = (date) => {
    setSelectedToDate(date);
    if (date) {
      setSelectedToDateError(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Layout>
      <div className="leavesIndex">
        <h4 className="attendance_overview_heading">Add New Holiday</h4>

        <div className="leavesIndex__body">
          <div className="leavesIndex__body_padding">
            <Box
              component="form"
              noValidate
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr' },
                gap: 2,
              }}
            >
              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className="singleInputField singleInputAnnouncement">
                    <InputLabel
                      className="formInputLabels"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Title
                    </InputLabel>
                    <FormControl
                      className={`newLeaveScreen ${
                        titleError ? 'leaveDurationInput-error' : ''
                      }`}
                      variant="standard"
                    >
                      <OutlinedInput
                        name="specific_item"
                        onChange={handleChange}
                        value={title}
                        placeholder="Holiday Title"
                      />
                    </FormControl>
                  </div>
                  <div className="singleInputField singleInputAnnouncement">
                    <InputLabel
                      className="formInputLabels"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Office City
                    </InputLabel>

                    <Select
                      name="office_city"
                      value={officeCity}
                      onChange={handleChangeOfficeCity}
                      displayEmpty
                      className="NewUserLabel"
                    >
                      <MenuItem value="both">Both Offices</MenuItem>
                      <MenuItem value="lahore">Lahore</MenuItem>
                      <MenuItem value="islamabad">Islamabad</MenuItem>
                    </Select>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className="singleInputField singleInputFieldWithoutBorder singleInputHoliday singleInputAnnouncement">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={[
                          'DatePicker',
                          'MobileDatePicker',
                          'DesktopDatePicker',
                          'StaticDatePicker',
                        ]}
                      >
                        <InputLabel
                          className="formInputLabels"
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          From Date
                        </InputLabel>

                        <DesktopDatePicker
                          onChange={handleFromDateChange}
                          className={`leaveDurationInput newLeaveScreen announcementDateAndTime ${
                            selectedFromDateError
                              ? 'leaveDurationInput-error'
                              : ''
                          }`}
                          value={selectedFromDate}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div className="singleInputField singleInputFieldWithoutBorder singleInputHoliday singleInputAnnouncement">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={[
                          'DatePicker',
                          'MobileDatePicker',
                          'DesktopDatePicker',
                          'StaticDatePicker',
                        ]}
                      >
                        <InputLabel
                          className="formInputLabels"
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          To Date
                        </InputLabel>

                        <DesktopDatePicker
                          onChange={handleToDateChange}
                          className={`leaveDurationInput newLeaveScreen announcementDateAndTime ${
                            selectedToDateError
                              ? 'leaveDurationInput-error'
                              : ''
                          }`}
                          value={selectedToDate}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '1vw 0',
                  }}
                >
                  <div className="singleInputField singleInputFieldTextArea">
                    <InputLabel
                      className="formInputLabels"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Description
                    </InputLabel>
                    <FormControl variant="standard">
                      <textarea
                        className={`leaveDurationTextArea leaveDurationInput ${
                          descriptionError ? 'leaveDurationInput-error' : ''
                        }`}
                        onChange={handleChangeDescription}
                        value={descriptionText}
                        placeholder="Enter text here..."
                        rows={6}
                        cols={50}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputField"></div>
                <div className="singleInputField">
                  <div className="formButton">
                    <button
                      type="button"
                      disabled={isSubmitting}
                      className="formSubmitButton"
                      onClick={onSubmit}
                    >
                      {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </div>

        <ValidationModal
          isOpen={isSubmittingModalOpen}
          onClose={closeSubmittingModal}
        >
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div className="ProgressModal">
                <Box className="progressOfSubmitting" sx={{ width: '100%' }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                      height: 15,
                      borderRadius: 10,
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#1fc214',
                      },
                    }}
                  />
                </Box>
                <span>Submitting Request</span>
              </div>
            </div>
          </div>
        </ValidationModal>
        <ValidationModal isOpen={isModalOpen} onClose={closeModal}>
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div className="ValidationModal">
                {validDateError ? (
                  <span>{errorMessage}</span>
                ) : (
                  <span>Please Enter All Fields To Continue!</span>
                )}
                <div className="CloseValidationModalButton">
                  <button
                    type="button"
                    disabled={isSubmitting}
                    className="CloseValidationModal"
                    onClick={closeModal}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ValidationModal>
      </div>
    </Layout>
  );
}
