import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';

import forgetPassword from './forgetPassword';
import useAuth from './hooks/useAuth';
import OtpVerification from './otpVerification';
import NewAnnouncement from './pages/announcement/add';
import EditAnnouncement from './pages/announcement/edit';
import AnnouncementList from './pages/announcement/list';
import ViewAnnouncement from './pages/announcement/View';
import EditAttendance from './pages/attendance/edit';
import AttendanceList from './pages/attendance/list';
import ViewAttendance from './pages/attendance/view';
import Dashboard from './pages/dashboard/Dashboard';
import NewHoliday from './pages/holiday/add';
import EditHoliday from './pages/holiday/edit';
import HolidayList from './pages/holiday/list';
import ViewHoliday from './pages/holiday/View';
import NewInventory from './pages/inventory/add';
import EditInventory from './pages/inventory/edit';
import InventoryList from './pages/inventory/list';
import ViewInventory from './pages/inventory/ViewInventory';
import NewLeave from './pages/leave/add';
import EditLeave from './pages/leave/edit';
import LeaveList from './pages/leave/list';
import MyLeaves from './pages/leave/myLeaves';
import LeaveRequests from './pages/leave/requests';
import ViewLeave from './pages/leave/ViewLeave';
import Login from './pages/Login';
import ResetPassword from './pages/resetPassword';
import ResetPasswordSuccess from './pages/resetPasswordSuccess';
import NewSpecial from './pages/special/add';
import SpecialList from './pages/special/list';
import ViewSpecialRequest from './pages/special/view';
import ChangePassword from './pages/user/changePassword';
import UserProfile from './pages/user/userProfile';
import { AuthRoute, PrivateRoute } from './Route';
import authService from './services/AuthService';
import RolePermissionService from './services/RolePermissionService';

export default function App() {
  const { authenticatedUser, setAuthenticatedUser } = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);
  const [rolePermission, setRolePermission] = useState([]);

  const authenticate = async () => {
    try {
      const authResponse = await authService.refresh();
      setAuthenticatedUser(authResponse.user);
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        const roleId = parsedUser.roleId;
        fetchPermissionsData(roleId);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    if (!authenticatedUser) {
      authenticate();
    } else {
      setIsLoaded(true);
    }
  }, []);

  const fetchPermissionsData = async (id) => {
    const rolePermission = await RolePermissionService.findAll(id);
    const allPermissionOfRole = rolePermission['rolePermission'];

    const userListPermissions = allPermissionOfRole.filter(
      (item) =>
        item.permission_name === 'hr' ||
        item.permission_name === 'holidays' ||
        item.permission_name === 'announcements' ||
        item.permission_name === 'accounts' ||
        item.permission_name === 'payroll',
    );
    setRolePermission(userListPermissions);
  };

  return isLoaded ? (
    <Router>
      <Switch>
        <AuthRoute exact path="/login" component={Login} />
        <AuthRoute exact path="/forgetPassword" component={forgetPassword} />
        <AuthRoute exact path="/otpVerification" component={OtpVerification} />
        <AuthRoute exact path="/resetPassword" component={ResetPassword} />
        <AuthRoute
          exact
          path="/resetPasswordSuccess"
          component={ResetPasswordSuccess}
        />

        <PrivateRoute exact path="/" component={Dashboard} />
        <PrivateRoute exact path="/profile" component={UserProfile} />

        <PrivateRoute exact path="/changePassword" component={ChangePassword} />

        <PrivateRoute exact path="/attendance" component={AttendanceList} />
        <PrivateRoute
          exact
          path="/attendance/view/:id"
          component={ViewAttendance}
        />
        <PrivateRoute
          exact
          path="/attendance/edit/:usersId/:checkInId/:checkOutId"
          component={EditAttendance}
        />

        {/* Leave module  */}
        <PrivateRoute exact path="/MyLeaves/:id" component={LeaveList} />
        <PrivateRoute exact path="/MyLeaves" component={MyLeaves} />
        <PrivateRoute exact path="/leave/requests" component={LeaveRequests} />
        <PrivateRoute exact path="/NewLeave" component={NewLeave} />
        <PrivateRoute exact path="/MyLeaves/edit/:id" component={EditLeave} />
        <PrivateRoute exact path="/MyLeaves/view/:id" component={ViewLeave} />

        {/* Special Request module  */}
        <PrivateRoute exact path="/special" component={SpecialList} />
        <PrivateRoute exact path="/special/add" component={NewSpecial} />
        <PrivateRoute
          exact
          path="/special/view/:id"
          component={ViewSpecialRequest}
        />

        {/* holiday module */}
        <PrivateRoute exact path="/holiday" component={HolidayList} />
        {rolePermission.some(
          (permission) => permission.permission_name === 'holidays',
        ) && <PrivateRoute exact path="/holiday/add" component={NewHoliday} />}
        <PrivateRoute exact path="/holiday/view/:id" component={ViewHoliday} />

        {rolePermission.some(
          (permission) => permission.permission_name === 'holidays',
        ) && (
          <PrivateRoute
            exact
            path="/holiday/edit/:id"
            component={EditHoliday}
          />
        )}

        {/* Announcement module */}
        <PrivateRoute exact path="/announcement" component={AnnouncementList} />

        {rolePermission.some(
          (permission) => permission.permission_name === 'announcements',
        ) && (
          <PrivateRoute
            exact
            path="/announcement/add"
            component={NewAnnouncement}
          />
        )}

        <PrivateRoute
          exact
          path="/announcement/view/:id"
          component={ViewAnnouncement}
        />

        {rolePermission.some(
          (permission) => permission.permission_name === 'announcements',
        ) && (
          <PrivateRoute
            exact
            path="/announcement/edit/:id"
            component={EditAnnouncement}
          />
        )}

        {/* Inventory module  */}
        {/* <PrivateRoute exact path="/inventory" component={InventoryList} />
        <PrivateRoute
          exact
          path="/inventory/view/:id"
          component={ViewInventory}
        />
        <PrivateRoute
          exact
          path="/inventory/request/edit/:id"
          component={EditInventory}
        />

        <PrivateRoute exact path="/inventory/add" component={NewInventory} /> */}
        {/* Redirect to /route if route does not exist */}
        <Redirect to="/" />
      </Switch>
    </Router>
  ) : null;
}
