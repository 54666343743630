import 'react-circular-progressbar/dist/styles.css';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { useHistory } from 'react-router-dom';

import Layout from '../../components/layout';
import AnnouncementService from '../../services/AnnouncementService';
import AttendanceService from '../../services/AttendanceService';
import HolidayService from '../../services/HolidayService';

const TodayBorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? '200' : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#54b4a6' : '#308fe8',
  },
}));

const WeekBorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#f37a37' : '#308fe8',
  },
}));

const MonthBorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#fcc670' : '#308fe8',
  },
}));

const OvertimeBorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#2086f1' : '#308fe8',
  },
}));

const AnnouncementDescription = ({ description }) => {
  const [announcement, setAnnouncement] = useState([]);
  const history = useHistory();

  const toggleModal = (id) => {
    history.push(`announcement/view/${id}`);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const announcementDetails = await AnnouncementService.findAll();
      setAnnouncement(announcementDetails);
    };

    fetchUserData();
  }, []);

  return (
    <div className="announcementDescription">
      <h4>
        {`${description.substring(0, 150)}`}
        <span
          className="toggleText"
          onClick={() => toggleModal(announcement[0].id)}
        >
          ... Show More
        </span>
      </h4>
    </div>
  );
};

export default function Dashboard() {
  const [holidays, setHolidays] = useState([]);
  const [announcement, setAnnouncement] = useState([]);
  const [todaysAttendance, setTodaysAttendance] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [isWeekend, setIsweekend] = useState(false);
  const [isWorkFromHome, setIsWorkFromHome] = useState(false);
  const [isWorkFromHomeCheckOut, setIsWorkFromHomeCheckOut] = useState(false);

  const [isLeave, setIsLeave] = useState(false);
  const [isAbsent, setIsAbsent] = useState(false);

  const [weekLateMinutes, setWeekLateMinutes] = useState(0);
  const [weekPercentage, setWeekPercentage] = useState(0);

  const [monthLateMinutes, setMonthLateMinutes] = useState(0);
  const [monthPercentage, setMonthPercentage] = useState(0);

  const [monthExtraMinutes, setMonthExtraMinutes] = useState(0);
  const [monthExtraMinutePercentage, setMonthExtraMinutePercentage] =
    useState(0);

  const history = useHistory();

  useEffect(() => {
    const fetchUserData = async () => {
      const holidaysDetails = await HolidayService.findAll();
      setHolidays(holidaysDetails);

      const announcementDetails = await AnnouncementService.findAll();
      setAnnouncement(announcementDetails);

      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);

      // today's record
      const getSpecificAttendance =
        await AttendanceService.getSpecificAttendance(parsedUser.id);

      if (getSpecificAttendance['todaysAttendance'][0].length > 0) {
        const todaysAttendance = getSpecificAttendance['todaysAttendance'][0];
        console.log(todaysAttendance);

        if (todaysAttendance === 'work_from_home') {
          setIsweekend(false);
          setIsLeave(false);
          setIsAbsent(false);
          const fullLeaves = getSpecificAttendance['todaysAttendance'][1];
          if (fullLeaves === 'full_day_leave') {
            setIsLeave(true);
            setIsAbsent(false);
            setIsWorkFromHome(false);
            setIsWorkFromHomeCheckOut(false);
          } else {
            if (fullLeaves === 'absent') {
              setIsWorkFromHomeCheckOut(false);
              setIsWorkFromHome(true);
            } else {
              const checkIns = getSpecificAttendance[
                'todaysAttendance'
              ][1].filter((item) => item.input_type === 'Check-in');

              const checkOutss = getSpecificAttendance[
                'todaysAttendance'
              ][1].filter((item) => item.input_type === 'Check-out');

              if (checkIns.length > 0 || checkOutss.length > 0) {
                if (checkIns.length > 0 && checkOutss.length > 0) {
                  setIsWorkFromHomeCheckOut(false);
                  setIsWorkFromHome(false);
                  setTodaysAttendance(checkIns[0]);

                  const lateMinutes = checkIns[0].late_minutes;
                  const totalPercentageOfLateMinutes =
                    (lateMinutes / 240) * 100;
                  setPercentage(totalPercentageOfLateMinutes);
                } else {
                  if (checkIns.length > 0) {
                    setIsWorkFromHomeCheckOut(true);
                    setIsWorkFromHome(false);

                    setTodaysAttendance(checkIns[0]);

                    const lateMinutes = checkIns[0].late_minutes;
                    const totalPercentageOfLateMinutes =
                      (lateMinutes / 240) * 100;
                    setPercentage(totalPercentageOfLateMinutes);
                  } else if (checkOutss.length > 0) {
                    setIsWorkFromHomeCheckOut(false);
                    setIsWorkFromHome(false);
                  }
                }
              } else {
                setIsWorkFromHomeCheckOut(false);
                setIsWorkFromHome(true);
              }
            }
          }
        } else if (todaysAttendance === 'weekend') {
          setIsweekend(true);
        } else if (todaysAttendance === 'full_day_leave') {
          setIsLeave(true);
          setIsAbsent(false);
        } else if (todaysAttendance === 'absent') {
          setIsAbsent(true);
        } else {
          setIsweekend(false);
          setIsLeave(false);
          setIsAbsent(false);

          const checkIns = todaysAttendance.filter(
            (item) => item.input_type === 'Check-in',
          );
          setTodaysAttendance(checkIns[0]);

          const lateMinutes = checkIns[0].late_minutes;
          const totalPercentageOfLateMinutes = (lateMinutes / 240) * 100;
          setPercentage(totalPercentageOfLateMinutes);
        }
      }

      // this week's record

      const thisWeekLateMinutes = getSpecificAttendance['weeksAttendance'];
      const totalPercentageOfThisWeekLateMinutes =
        (thisWeekLateMinutes / 1200) * 100;
      setWeekPercentage(totalPercentageOfThisWeekLateMinutes);
      setWeekLateMinutes(thisWeekLateMinutes);

      // this month's record

      const thisMonthLateMinutes = getSpecificAttendance['monthsAttendance'];
      const totalPercentageOfThisMonthLateMinutes =
        (thisMonthLateMinutes / 5280) * 100;
      setMonthPercentage(totalPercentageOfThisMonthLateMinutes);
      setMonthLateMinutes(thisMonthLateMinutes);

      // extra minutes of this  month's

      const thisMonthExtraMinutes = getSpecificAttendance['monthsExtraMinutes'];
      const totalPercentageOfThisMonthExtraMinutes =
        (thisMonthExtraMinutes / 7920) * 100;
      setMonthExtraMinutePercentage(totalPercentageOfThisMonthExtraMinutes);
      setMonthExtraMinutes(thisMonthExtraMinutes);
    };

    fetchUserData();
  }, []);

  const wfmAttendance = async (type: string) => {
    const storedUser = localStorage.getItem('user');
    const parsedUser = JSON.parse(storedUser);

    if (type === 'Check-in') {
      const data = {
        usersId: parsedUser.id,
        inputType: type,
        punchById: parsedUser.id,
        recordTime: new Date(),
      };

      try {
        await AttendanceService.wfmAttendance(data);
        setIsWorkFromHomeCheckOut(true);
      } catch (error) {
        alert('something went wrong while checking In try again later');
        setIsWorkFromHomeCheckOut(false);
      }
    } else if (type === 'Check-out') {
      const data = {
        usersId: parsedUser.id,
        inputType: type,
        punchById: parsedUser.id,
        recordTime: new Date(),
      };

      try {
        await AttendanceService.wfmAttendance(data);
        setIsWorkFromHomeCheckOut(false);
        setIsWorkFromHome(false);
      } catch (error) {
        alert('something went wrong while checking In try again later');
        setIsWorkFromHomeCheckOut(false);
      }
    }

    // today's record
    const getSpecificAttendance = await AttendanceService.getSpecificAttendance(
      parsedUser.id,
    );

    if (getSpecificAttendance['todaysAttendance'][0].length > 0) {
      const todaysAttendance = getSpecificAttendance['todaysAttendance'][0];

      if (todaysAttendance === 'work_from_home') {
        setIsweekend(false);
        setIsLeave(false);
        setIsAbsent(false);
        const fullLeaves = getSpecificAttendance['todaysAttendance'][1];
        if (fullLeaves === 'full_day_leave') {
          setIsLeave(true);
          setIsAbsent(false);
          setIsWorkFromHome(false);
          setIsWorkFromHomeCheckOut(false);
        } else {
          const checkIns = getSpecificAttendance['todaysAttendance'][1].filter(
            (item) => item.input_type === 'Check-in',
          );

          const checkOutss = getSpecificAttendance[
            'todaysAttendance'
          ][1].filter((item) => item.input_type === 'Check-out');

          if (checkIns.length > 0 || checkOutss.length > 0) {
            if (checkIns.length > 0 && checkOutss.length > 0) {
              setIsWorkFromHomeCheckOut(false);
              setIsWorkFromHome(false);
              setTodaysAttendance(checkIns[0]);

              const lateMinutes = checkIns[0].late_minutes;
              const totalPercentageOfLateMinutes = (lateMinutes / 240) * 100;
              setPercentage(totalPercentageOfLateMinutes);
            } else {
              if (checkIns.length > 0) {
                setIsWorkFromHomeCheckOut(true);
                setIsWorkFromHome(false);

                setTodaysAttendance(checkIns[0]);

                const lateMinutes = checkIns[0].late_minutes;
                const totalPercentageOfLateMinutes = (lateMinutes / 240) * 100;
                setPercentage(totalPercentageOfLateMinutes);
              } else if (checkOutss.length > 0) {
                setIsWorkFromHomeCheckOut(false);
                setIsWorkFromHome(false);
              }
            }
          } else {
            setIsWorkFromHomeCheckOut(false);
            setIsWorkFromHome(true);
          }
        }
      } else if (todaysAttendance === 'weekend') {
        setIsweekend(true);
      } else if (todaysAttendance === 'full_day_leave') {
        setIsLeave(true);
        setIsAbsent(false);
      } else if (todaysAttendance === 'absent') {
        setIsAbsent(true);
      } else {
        setIsweekend(false);
        setIsLeave(false);
        setIsAbsent(false);

        const checkIns = todaysAttendance.filter(
          (item) => item.input_type === 'Check-in',
        );
        setTodaysAttendance(checkIns[0]);

        const lateMinutes = checkIns[0].late_minutes;
        const totalPercentageOfLateMinutes = (lateMinutes / 240) * 100;
        setPercentage(totalPercentageOfLateMinutes);
      }
    }

    // this week's record

    const thisWeekLateMinutes = getSpecificAttendance['weeksAttendance'];
    const totalPercentageOfThisWeekLateMinutes =
      (thisWeekLateMinutes / 1200) * 100;
    setWeekPercentage(totalPercentageOfThisWeekLateMinutes);
    setWeekLateMinutes(thisWeekLateMinutes);

    // this month's record

    const thisMonthLateMinutes = getSpecificAttendance['monthsAttendance'];
    const totalPercentageOfThisMonthLateMinutes =
      (thisMonthLateMinutes / 5280) * 100;
    setMonthPercentage(totalPercentageOfThisMonthLateMinutes);
    setMonthLateMinutes(thisMonthLateMinutes);

    // extra minutes of this  month's

    const thisMonthExtraMinutes = getSpecificAttendance['monthsExtraMinutes'];
    const totalPercentageOfThisMonthExtraMinutes =
      (thisMonthExtraMinutes / 7920) * 100;
    setMonthExtraMinutePercentage(totalPercentageOfThisMonthExtraMinutes);
    setMonthExtraMinutes(thisMonthExtraMinutes);
  };

  const holidayDetails = (id) => {
    history.push(`holiday/view/${id}`);
  };

  const DateDisplay = ({ date, rowIndex }) => {
    const isMultipleDates = date.includes('-');

    return (
      <h3 key={rowIndex} style={{ fontWeight: rowIndex !== 0 ? '300' : '500' }}>
        {isMultipleDates ? (
          <>
            <span>{date.split('|')[0].trim()}</span>
            <br />
            <span>{date.split('|')[1].trim()}</span>
          </>
        ) : (
          date
        )}
      </h3>
    );
  };

  return (
    <Layout>
      <div className="dashboard lg:flex-row">
        <h4 className="responsive_screens_heading">Dashboard</h4>

        <div className="dashboard__first">
          <Card
            className="public_holidays margin_right_public_holidays"
            variant="outlined"
          >
            <div
              style={{
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderBottomColor: 'rgba(112, 112, 112, 0.15)',
              }}
              className="public_holidays_heading"
            >
              <div className="public_holidays_icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="62.034"
                  height="61.876"
                  viewBox="0 0 62.034 61.876"
                  className="public_holidays_svg_icon"
                >
                  <g data-name="Group 40">
                    <path
                      fill="#d8dbe0"
                      data-name="Path 18"
                      d="M58.861 61.876H3.163A3.163 3.163 0 0 1 0 58.713V19.227h62.034V58.7a3.174 3.174 0 0 1-3.173 3.173"
                    />
                    <path
                      fill="#ff4f1c"
                      data-name="Path 19"
                      d="M0 19.318V9.367a3.619 3.619 0 0 1 3.619-3.619h54.8a3.619 3.619 0 0 1 3.619 3.619v9.951"
                    />
                    <path
                      fill="#252e3f"
                      data-name="Line 24"
                      transform="translate(16.156 2)"
                      d="M0 0v7.518"
                    />
                    <path
                      fill="none"
                      data-name="Line 25"
                      transform="translate(16.156 2)"
                      style={{
                        strokeLinecap: 'round',
                        strokeLinejoin: 'round',
                        stroke: '#252e3f',
                        strokeWidth: '4px',
                      }}
                      d="M0 0v7.518"
                    />
                    <path
                      fill="#252e3f"
                      data-name="Line 26"
                      transform="translate(31.111 2)"
                      d="M0 0v7.518"
                    />
                    <path
                      fill="none"
                      data-name="Line 27"
                      transform="translate(31.111 2)"
                      style={{
                        strokeLinecap: 'round',
                        strokeLinejoin: 'round',
                        stroke: '#252e3f',
                        strokeWidth: '4px',
                      }}
                      d="M0 0v7.518"
                    />
                    <path
                      fill="#252e3f"
                      data-name="Line 28"
                      transform="translate(45.69 2)"
                      d="M0 0v7.518"
                    />
                    <path
                      fill="none"
                      data-name="Line 29"
                      transform="translate(45.69 2)"
                      d="M0 0v7.518"
                      style={{
                        strokeLinecap: 'round',
                        strokeLinejoin: 'round',
                        stroke: '#252e3f',
                        strokeWidth: '4px',
                      }}
                    />
                    <path
                      fill="none"
                      data-name="Rectangle 42"
                      transform="translate(7.66 25.043)"
                      d="M0 0h46.526v31.017H0z"
                      style={{
                        strokeLinecap: 'round',
                        strokeLinejoin: 'round',
                        stroke: '#fff',
                        strokeWidth: '3px',
                      }}
                    />
                    <path
                      fill="none"
                      data-name="Line 30"
                      transform="translate(18.5 25.757)"
                      d="M0 0v30.303"
                      style={{
                        strokeLinecap: 'round',
                        strokeLinejoin: 'round',
                        stroke: '#fff',
                        strokeWidth: '3px',
                      }}
                    />
                    <path
                      fill="none"
                      data-name="Line 31"
                      transform="translate(30.716 25.757)"
                      d="M0 0v30.303"
                      style={{
                        strokeMiterlimit: '10',
                        stroke: '#fff',
                        strokeWidth: '3px',
                      }}
                    />
                    <path
                      fill="none"
                      data-name="Line 32"
                      transform="translate(42.931 25.4)"
                      d="M0 0v30.303"
                      style={{
                        strokeLinecap: 'round',
                        strokeLinejoin: 'round',
                        stroke: '#fff',
                        strokeWidth: '3px',
                      }}
                    />
                    <path
                      fill="none"
                      data-name="Line 33"
                      transform="translate(7.66 35.162)"
                      d="M46.526 0H0"
                      style={{
                        strokeLinecap: 'round',
                        strokeLinejoin: 'round',
                        stroke: '#fff',
                        strokeWidth: '3px',
                      }}
                    />
                    <path
                      fill="none"
                      data-name="Line 34"
                      transform="translate(7.66 45.321)"
                      style={{
                        strokeLinecap: 'round',
                        strokeLinejoin: 'round',
                        stroke: '#fff',
                        strokeWidth: '3px',
                      }}
                      d="M46.526 0H0"
                    />
                    <path
                      fill="#ff4f1c"
                      data-name="Rectangle 43"
                      transform="translate(31.838 36.364)"
                      d="M0 0h9.97v7.754H0z"
                    />
                    <path
                      fill="none"
                      data-name="Rectangle 44"
                      d="M0 0h62.034v61.876H0z"
                    />
                  </g>
                </svg>
              </div>
              <div className="public_holidays_text">
                <h3>Public Holidays</h3>
              </div>
            </div>
            {holidays.length !== 0 ? (
              <div>
                {holidays.map((row, rowIndex) => (
                  <div key={rowIndex}>
                    {rowIndex === 0 ? (
                      <div
                        onClick={() => holidayDetails(row.id)}
                        className="public_holidays__content"
                        style={{ cursor: 'pointer' }}
                      >
                        <div
                          className="public_holidays_heading"
                          style={{
                            borderBottom: '1px solid rgba(112, 112, 112, 0.15)',
                          }}
                        >
                          <div className="public_holidays_icon">
                            <div>
                              <svg
                                className="svgIconSubHeadingsLaptop"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.936"
                                height="19.043"
                                viewBox="0 0 16.936 19.043"
                              >
                                <path
                                  fill="#ff4f1c"
                                  data-name="calendar_month_FILL0_wght300_GRAD0_opsz24 (1)"
                                  d="M141.8-845.571a1.738 1.738 0 0 1-1.278-.523 1.738 1.738 0 0 1-.523-1.278v-13.334a1.738 1.738 0 0 1 .523-1.278 1.738 1.738 0 0 1 1.278-.523h1.379v-1.341a.742.742 0 0 1 .219-.547.742.742 0 0 1 .547-.219.742.742 0 0 1 .547.219.742.742 0 0 1 .219.547v1.341h7.548v-1.36a.723.723 0 0 1 .215-.533.723.723 0 0 1 .533-.215.723.723 0 0 1 .533.215.723.723 0 0 1 .215.533v1.36h1.379a1.738 1.738 0 0 1 1.278.523 1.738 1.738 0 0 1 .523 1.278v13.334a1.738 1.738 0 0 1-.523 1.278 1.738 1.738 0 0 1-1.278.523zm0-1.494h13.334a.293.293 0 0 0 .211-.1.293.293 0 0 0 .1-.211v-9.349H141.5v9.349a.293.293 0 0 0 .1.211.293.293 0 0 0 .2.1zm-.307-11.15h13.947v-2.491a.293.293 0 0 0-.1-.211.293.293 0 0 0-.211-.1H141.8a.293.293 0 0 0-.211.1.293.293 0 0 0-.1.211zm0 0zm6.974 5.249a.85.85 0 0 1-.624-.258.849.849 0 0 1-.258-.624.849.849 0 0 1 .258-.624.85.85 0 0 1 .624-.258.85.85 0 0 1 .624.258.849.849 0 0 1 .258.624.849.849 0 0 1-.258.624.85.85 0 0 1-.622.258zm-3.985 0a.85.85 0 0 1-.624-.258.849.849 0 0 1-.258-.624.849.849 0 0 1 .258-.624.85.85 0 0 1 .624-.258.85.85 0 0 1 .624.258.849.849 0 0 1 .258.624.849.849 0 0 1-.258.624.85.85 0 0 1-.622.258zm7.97 0a.85.85 0 0 1-.624-.258.849.849 0 0 1-.258-.624.849.849 0 0 1 .258-.624.85.85 0 0 1 .624-.258.85.85 0 0 1 .624.258.849.849 0 0 1 .258.624.849.849 0 0 1-.258.624.85.85 0 0 1-.622.258zm-3.985 3.908a.85.85 0 0 1-.624-.258.849.849 0 0 1-.258-.624.849.849 0 0 1 .258-.624.849.849 0 0 1 .624-.258.849.849 0 0 1 .624.258.849.849 0 0 1 .258.624.849.849 0 0 1-.258.624.85.85 0 0 1-.622.259zm-3.985 0a.85.85 0 0 1-.624-.258.849.849 0 0 1-.258-.624.849.849 0 0 1 .258-.624.849.849 0 0 1 .624-.258.849.849 0 0 1 .624.258.849.849 0 0 1 .258.624.849.849 0 0 1-.258.624.85.85 0 0 1-.622.259zm7.97 0a.85.85 0 0 1-.624-.258.849.849 0 0 1-.258-.624.849.849 0 0 1 .258-.624.849.849 0 0 1 .624-.258.849.849 0 0 1 .624.258.849.849 0 0 1 .258.624.849.849 0 0 1-.258.624.85.85 0 0 1-.622.259z"
                                  transform="translate(-140.001 864.614)"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="8"
                                height="8"
                                className="svgIconSubHeadingsMobile"
                                viewBox="0 0 8 8"
                              >
                                <circle
                                  fill="#15267d"
                                  data-name="Ellipse 18"
                                  cx="4"
                                  cy="4"
                                  r="4"
                                />
                              </svg>
                            </div>
                          </div>

                          <div className="public_holidays_sub_heading_text">
                            <DateDisplay
                              date={row.from_date}
                              rowIndex={rowIndex}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            borderBottom: '1px solid rgba(112, 112, 112, 0.15)',
                            justifyContent: 'flex-start',
                          }}
                          className="public_holidays_heading public_holidays_second_heading"
                        >
                          <div className="public_holidays_sub_heading_text marquee-container">
                            <h3
                              className="marquee"
                              key={rowIndex}
                              style={{
                                fontWeight: rowIndex !== 0 ? '300' : '500',
                                textAlign: 'left',
                              }}
                            >
                              {row.name}
                            </h3>
                          </div>
                          <div
                            className="public_holidays_sub_heading_text"
                            style={{ marginLeft: '1vw' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11.917"
                              height="13"
                              viewBox="0 0 11.917 13"
                            >
                              <g data-name="Group 74">
                                <path d="m2.653 10.42 3.674-3.671-3.675-3.675a.944.944 0 0 1 1.335-1.335l4.347 4.347a.943.943 0 0 1 0 1.335l-4.346 4.343a.943.943 0 0 1-1.335 0 .963.963 0 0 1 0-1.344z" />
                                <path
                                  data-name="Rectangle 90"
                                  transform="rotate(-90 6.5 6.5)"
                                  fill="none"
                                  d="M0 0h13v11.917H0z"
                                />
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div key={rowIndex} className="public_holidays__content">
                        <div
                          style={{
                            borderBottom: '1px solid rgba(112, 112, 112, 0.15)',
                          }}
                          className="public_holidays_heading"
                        >
                          <div className="public_holidays_icon">
                            <svg
                              className="svgIconSubHeadingsLaptop"
                              xmlns="http://www.w3.org/2000/svg"
                              width="6"
                              height="6"
                              viewBox="0 0 8 8"
                            >
                              <circle
                                fill="#15267d"
                                data-name="Ellipse 18"
                                cx="4"
                                cy="4"
                                r="4"
                              />
                            </svg>
                          </div>

                          <div className="public_holidays_sub_heading_text">
                            <DateDisplay
                              date={row.from_date}
                              rowIndex={rowIndex}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            borderBottom: '1px solid rgba(112, 112, 112, 0.15)',
                            justifyContent: 'flex-start',
                          }}
                          className="public_holidays_heading public_holidays_second_heading"
                        >
                          <div className="public_holidays_sub_heading_text">
                            <h3
                              key={rowIndex}
                              style={{
                                fontWeight: rowIndex !== 0 ? '300' : '500',
                                textAlign: 'left',
                              }}
                            >
                              {row.name}
                            </h3>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div
                style={{ display: 'flex', justifyContent: 'center' }}
                className="public_holidays__content"
              >
                <div className="public_holidays_sub_heading_text">
                  <h3 style={{ fontWeight: '500', textAlign: 'center' }}>
                    No Records To Show
                  </h3>
                </div>
              </div>
            )}
          </Card>

          {/* announcement */}
          <Card
            className="public_holidays margin_top_public_holidays"
            variant="outlined"
            style={{ marginRight: 0 }}
          >
            <div
              className="public_holidays_heading"
              style={{
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderBottomColor: 'rgba(112, 112, 112, 0.15)',
              }}
            >
              <div className="public_holidays_icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="62.077"
                  height="62.248"
                  viewBox="0 0 62.077 62.248"
                  className="public_holidays_svg_icon"
                >
                  <defs>
                    <clipPath id="np90zpum8a">
                      <path
                        fill="none"
                        data-name="Rectangle 52"
                        transform="translate(0 -.001)"
                        d="M0 0h62.077v62.248H0z"
                      />
                    </clipPath>
                  </defs>
                  <g data-name="Group 33">
                    <g
                      style={{ clipPath: 'url(#np90zpum8a)' }}
                      data-name="Group 32"
                      transform="translate(0 .001)"
                    >
                      <path
                        fill="#252e3f"
                        data-name="Path 38"
                        d="m25.507 46.879-1.792 1.188a1.02 1.02 0 0 0-.286 1.413l1.636 2.467a1.02 1.02 0 0 0 1.413.286l1.791-1.188a1.019 1.019 0 0 0 .287-1.413l-1.636-2.467a1.02 1.02 0 0 0-1.413-.286"
                      />
                      <path
                        fill="#ff4f1c"
                        data-name="Path 39"
                        d="m13.4 47.4 8.55 12.893a4.375 4.375 0 0 0 6.065 1.229 4.376 4.376 0 0 0 1.229-6.065l-8.55-12.894z"
                      />
                      <path
                        fill="#ff4f1c"
                        data-name="Path 40"
                        d="m44.813 41.175-22.528 2.248-9.646-14.546L23.476 9z"
                      />
                      <path
                        fill="#ff4f1c"
                        data-name="Path 41"
                        d="m14.369 31.484 10.837-19.877L23.477 9 12.64 28.876l9.646 14.546z"
                      />
                      <path
                        fill="#ff4f1c"
                        data-name="Path 42"
                        d="M3.9 34.668a8.727 8.727 0 0 0 9.646 14.546l9.013-5.977-9.6-14.579z"
                      />
                      <path
                        fill="#ff4f1c"
                        data-name="Path 43"
                        d="m14.369 31.484-1.729-2.608-8.74 5.792a8.647 8.647 0 0 0-1.449 13.313 8.717 8.717 0 0 1 3.184-10.7z"
                      />
                      <path
                        fill="#d8dbe0"
                        data-name="Path 44"
                        d="M24.379.876a5.237 5.237 0 0 0-1.471 7.26l22.476 33.9a5.242 5.242 0 0 0 7.262 1.471 5.237 5.237 0 0 0 1.471-7.26L31.64 2.347A5.24 5.24 0 0 0 24.379.876"
                      />
                      <path
                        fill="#d8dbe0"
                        data-name="Path 45"
                        d="M33.377 4.955a5.231 5.231 0 0 0-7.262-1.471h-.016a5.259 5.259 0 0 0-1.471 7.262L22.9 8.138a5.239 5.239 0 0 1 8.733-5.791z"
                      />
                      <path
                        fill="#252e3f"
                        data-name="Path 46"
                        d="m38.364 12.487 8.5 12.817a7.689 7.689 0 1 0-8.5-12.817"
                      />
                      <path
                        fill="#ff4f1c"
                        data-name="Line 52"
                        transform="translate(52.482 5.707)"
                        d="M7.265 0 0 5.441"
                      />
                      <path
                        fill="none"
                        data-name="Line 53"
                        transform="translate(52.482 5.707)"
                        style={{
                          stroke: '#ff4f1c',
                          strokeLinecap: 'round',
                          strokeLinejoin: 'round',
                          strokeWidth: '3px',
                        }}
                        d="M7.265 0 0 5.441"
                      />
                      <path
                        fill="#ff4f1c"
                        data-name="Line 54"
                        transform="translate(55.707 16.715)"
                        d="M4.87 0H0"
                      />
                      <path
                        fill="none"
                        data-name="Line 55"
                        transform="translate(55.707 16.715)"
                        d="M4.87 0H0"
                        style={{
                          stroke: '#ff4f1c',
                          strokeLinecap: 'round',
                          strokeLinejoin: 'round',
                          strokeWidth: '3px',
                        }}
                      />
                      <path
                        fill="#ff4f1c"
                        data-name="Line 56"
                        transform="translate(47.73 2.1)"
                        d="M2.365 0 0 4.257"
                      />
                      <path
                        fill="none"
                        data-name="Line 57"
                        transform="translate(47.73 2.1)"
                        style={{
                          stroke: '#ff4f1c',
                          strokeLinecap: 'round',
                          strokeLinejoin: 'round',
                          strokeWidth: '3px',
                        }}
                        d="M2.365 0 0 4.257"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="public_holidays_text">
                <h3>Announcements</h3>
              </div>
            </div>
            {announcement.length !== 0 ? (
              <div>
                {announcement.map((row, rowIndex) => (
                  <div key={rowIndex}>
                    <div
                      style={{ padding: '1vw' }}
                      className="public_holidays__content"
                    >
                      <div
                        style={{
                          borderBottom: '1px solid',
                          borderColor: 'rgba(112, 112, 112, 0.15)',
                        }}
                        className="public_holidays_heading"
                      >
                        <div className="public_holidays_icon">
                          <div>
                            <svg
                              className="svgIconSubHeadingsLaptop"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16.936"
                              height="19.043"
                              viewBox="0 0 16.936 19.043"
                            >
                              <path
                                fill="#ff4f1c"
                                data-name="calendar_month_FILL0_wght300_GRAD0_opsz24 (1)"
                                d="M141.8-845.571a1.738 1.738 0 0 1-1.278-.523 1.738 1.738 0 0 1-.523-1.278v-13.334a1.738 1.738 0 0 1 .523-1.278 1.738 1.738 0 0 1 1.278-.523h1.379v-1.341a.742.742 0 0 1 .219-.547.742.742 0 0 1 .547-.219.742.742 0 0 1 .547.219.742.742 0 0 1 .219.547v1.341h7.548v-1.36a.723.723 0 0 1 .215-.533.723.723 0 0 1 .533-.215.723.723 0 0 1 .533.215.723.723 0 0 1 .215.533v1.36h1.379a1.738 1.738 0 0 1 1.278.523 1.738 1.738 0 0 1 .523 1.278v13.334a1.738 1.738 0 0 1-.523 1.278 1.738 1.738 0 0 1-1.278.523zm0-1.494h13.334a.293.293 0 0 0 .211-.1.293.293 0 0 0 .1-.211v-9.349H141.5v9.349a.293.293 0 0 0 .1.211.293.293 0 0 0 .2.1zm-.307-11.15h13.947v-2.491a.293.293 0 0 0-.1-.211.293.293 0 0 0-.211-.1H141.8a.293.293 0 0 0-.211.1.293.293 0 0 0-.1.211zm0 0zm6.974 5.249a.85.85 0 0 1-.624-.258.849.849 0 0 1-.258-.624.849.849 0 0 1 .258-.624.85.85 0 0 1 .624-.258.85.85 0 0 1 .624.258.849.849 0 0 1 .258.624.849.849 0 0 1-.258.624.85.85 0 0 1-.622.258zm-3.985 0a.85.85 0 0 1-.624-.258.849.849 0 0 1-.258-.624.849.849 0 0 1 .258-.624.85.85 0 0 1 .624-.258.85.85 0 0 1 .624.258.849.849 0 0 1 .258.624.849.849 0 0 1-.258.624.85.85 0 0 1-.622.258zm7.97 0a.85.85 0 0 1-.624-.258.849.849 0 0 1-.258-.624.849.849 0 0 1 .258-.624.85.85 0 0 1 .624-.258.85.85 0 0 1 .624.258.849.849 0 0 1 .258.624.849.849 0 0 1-.258.624.85.85 0 0 1-.622.258zm-3.985 3.908a.85.85 0 0 1-.624-.258.849.849 0 0 1-.258-.624.849.849 0 0 1 .258-.624.849.849 0 0 1 .624-.258.849.849 0 0 1 .624.258.849.849 0 0 1 .258.624.849.849 0 0 1-.258.624.85.85 0 0 1-.622.259zm-3.985 0a.85.85 0 0 1-.624-.258.849.849 0 0 1-.258-.624.849.849 0 0 1 .258-.624.849.849 0 0 1 .624-.258.849.849 0 0 1 .624.258.849.849 0 0 1 .258.624.849.849 0 0 1-.258.624.85.85 0 0 1-.622.259zm7.97 0a.85.85 0 0 1-.624-.258.849.849 0 0 1-.258-.624.849.849 0 0 1 .258-.624.849.849 0 0 1 .624-.258.849.849 0 0 1 .624.258.849.849 0 0 1 .258.624.849.849 0 0 1-.258.624.85.85 0 0 1-.622.259z"
                                transform="translate(-140.001 864.614)"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="8"
                              className="svgIconSubHeadingsMobile"
                              viewBox="0 0 8 8"
                            >
                              <circle
                                fill="#15267d"
                                data-name="Ellipse 18"
                                cx="4"
                                cy="4"
                                r="4"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="public_holidays_sub_heading_text">
                          <h3
                            style={{
                              fontWeight: rowIndex !== 0 ? '300' : '500',
                            }}
                          >
                            {row.time}
                          </h3>
                        </div>
                      </div>

                      <div
                        style={{
                          justifyContent: 'flex-start',
                          borderBottom: '1px solid',
                          borderColor: 'rgba(112, 112, 112, 0.15)',
                        }}
                        className="public_holidays_heading public_holidays_second_heading"
                      >
                        <div className="public_holidays_sub_heading_text">
                          <h3
                            style={{
                              fontWeight: rowIndex !== 0 ? '300' : '500',
                            }}
                          >
                            {row.title}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div>
                      <AnnouncementDescription description={row.description} />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div
                style={{ display: 'flex', justifyContent: 'center' }}
                className="public_holidays__content"
              >
                <div className="public_holidays_sub_heading_text">
                  <h3 style={{ fontWeight: '500', textAlign: 'center' }}>
                    No Records To Show
                  </h3>
                </div>
              </div>
            )}
          </Card>
        </div>

        <h4 className="attendance_overview_heading">Attendance Overview</h4>
        <div className="dashboard__second">
          <Card
            // className="public_holidays margin_right_public_holidays attendance_overview"
            className="attendance_overview attendance_overview__first margin_right_public_holidays attendance__overview"
            variant="outlined"
          >
            <div className="attendance_overview__header">
              <div className="attendance_overview__header_text">
                <h3>Today</h3>
              </div>
              <div className="attendance_overview__header_text attendance_overview__header_text_right">
                <a style={{ paddingRight: '5px' }} href="/attendance">
                  View Details
                </a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="viewMoreSvgIcon"
                  viewBox="0 0 11.917 13"
                >
                  <g data-name="Group 74">
                    <path d="m2.653 10.42 3.674-3.671-3.675-3.675a.944.944 0 0 1 1.335-1.335l4.347 4.347a.943.943 0 0 1 0 1.335l-4.346 4.343a.943.943 0 0 1-1.335 0 .963.963 0 0 1 0-1.344z" />
                    <path
                      fill="none"
                      data-name="Rectangle 90"
                      transform="rotate(-90 6.5 6.5)"
                      d="M0 0h13v11.917H0z"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div className="attendance_overview__header_text attendance_overview__header_text_second">
              {!isWeekend && !isLeave && !isAbsent && !isWorkFromHome && (
                <p>Check in at {todaysAttendance['record_time']}</p>
              )}
            </div>
            <div className="attendance_overview_body">
              {isWorkFromHome || isWorkFromHomeCheckOut ? (
                <div>
                  {isWorkFromHome && (
                    <div>
                      <CircularProgressbarWithChildren
                        className="lateMinuteBar"
                        value={100}
                        strokeWidth={8}
                        styles={{
                          root: { width: '15vw', height: '15vw' },
                          path: { stroke: `#6FCF6B` },
                          trail: { stroke: '#f0f0f0' },
                        }}
                      >
                        <div
                          className="checkInIconParent"
                          style={{ backgroundColor: '#6FCF6B' }}
                        >
                          <svg
                            className="pulsate wfmSvg"
                            xmlns="http://www.w3.org/2000/svg"
                            // xmlns="http://www.w3.org/1999/xlink"
                            viewBox="0 0 57.93 81"
                            onClick={() => wfmAttendance('Check-in')}
                          >
                            <defs>
                              <clipPath id="clip-path">
                                <rect
                                  id="Rectangle_297"
                                  data-name="Rectangle 297"
                                  width="26.442"
                                  height="18.401"
                                  fill="none"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Group_320"
                              data-name="Group 320"
                              transform="translate(-711.237 -791.834)"
                            >
                              <g
                                id="Group_310"
                                data-name="Group 310"
                                transform="translate(-10084.763 12214.32)"
                              >
                                <path
                                  id="Path_73"
                                  data-name="Path 73"
                                  d="M37.473,32.411a4.788,4.788,0,0,1-1.921,8.4,4.781,4.781,0,0,1-1.907,8.4,4.8,4.8,0,0,1-2.983,8.529L3.5,57.433a3.51,3.51,0,0,1-3.5-3.5V26.46C0,22.127,13.858,14.125,14.6,9.85,15.064,7.092,14.45,0,16.357,0c3.235,0,7.388,1.242,7.388,8.428,0,6.341-4.152,14.566-4.152,14.566H36.4a4.788,4.788,0,0,1,1.076,9.453Z"
                                  transform="translate(10796 -11356.436) rotate(-90)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_225"
                                  data-name="Path 225"
                                  d="M10821.406-11400.392v-16.265h9.59v14.964Z"
                                  transform="translate(-2.409 8.499)"
                                  fill="#fff"
                                />
                                <ellipse
                                  id="Ellipse_49"
                                  data-name="Ellipse 49"
                                  cx="4.793"
                                  cy="4.526"
                                  rx="4.793"
                                  ry="4.526"
                                  transform="translate(10819 -11412.684)"
                                  fill="#fff"
                                />
                                <rect
                                  id="Rectangle_296"
                                  data-name="Rectangle 296"
                                  width="35.301"
                                  height="11.767"
                                  rx="4"
                                  transform="translate(10818.629 -11353.254)"
                                  fill="#fff"
                                />
                                <g
                                  id="Group_307"
                                  data-name="Group 307"
                                  transform="translate(10811.071 -11422.486) rotate(3)"
                                >
                                  <g
                                    id="Group_306"
                                    data-name="Group 306"
                                    clipPath="url(#clip-path)"
                                  >
                                    <path
                                      id="Path_226"
                                      data-name="Path 226"
                                      d="M2.7,18.4A2.319,2.319,0,0,1,.463,16.69a13.221,13.221,0,1,1,25.765-1.1,2.319,2.319,0,1,1-4.562-.83,8.584,8.584,0,1,0-16.728.716A2.321,2.321,0,0,1,2.7,18.4"
                                      fill="#fff"
                                    />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                  )}
                  <div>
                    {isWorkFromHomeCheckOut && (
                      <CircularProgressbarWithChildren
                        className="lateMinuteBar"
                        value={100}
                        strokeWidth={8}
                        styles={{
                          root: { width: '15vw', height: '15vw' },
                          path: { stroke: `#F28D49` },
                          trail: { stroke: '#f0f0f0' },
                        }}
                      >
                        <div
                          className="checkInIconParent"
                          style={{ backgroundColor: '#F28D49' }}
                        >
                          <svg
                            className="pulsate wfmSvg"
                            xmlns="http://www.w3.org/2000/svg"
                            // xmlns="http://www.w3.org/1999/xlink"
                            viewBox="0 0 57.93 81"
                            onClick={() => wfmAttendance('Check-out')}
                          >
                            <defs>
                              <clipPath id="clip-path">
                                <rect
                                  id="Rectangle_297"
                                  data-name="Rectangle 297"
                                  width="26.442"
                                  height="18.401"
                                  fill="none"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Group_320"
                              data-name="Group 320"
                              transform="translate(-711.237 -791.834)"
                            >
                              <g
                                id="Group_310"
                                data-name="Group 310"
                                transform="translate(-10084.763 12214.32)"
                              >
                                <path
                                  id="Path_73"
                                  data-name="Path 73"
                                  d="M37.473,32.411a4.788,4.788,0,0,1-1.921,8.4,4.781,4.781,0,0,1-1.907,8.4,4.8,4.8,0,0,1-2.983,8.529L3.5,57.433a3.51,3.51,0,0,1-3.5-3.5V26.46C0,22.127,13.858,14.125,14.6,9.85,15.064,7.092,14.45,0,16.357,0c3.235,0,7.388,1.242,7.388,8.428,0,6.341-4.152,14.566-4.152,14.566H36.4a4.788,4.788,0,0,1,1.076,9.453Z"
                                  transform="translate(10796 -11356.436) rotate(-90)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_225"
                                  data-name="Path 225"
                                  d="M10821.406-11400.392v-16.265h9.59v14.964Z"
                                  transform="translate(-2.409 8.499)"
                                  fill="#fff"
                                />
                                <ellipse
                                  id="Ellipse_49"
                                  data-name="Ellipse 49"
                                  cx="4.793"
                                  cy="4.526"
                                  rx="4.793"
                                  ry="4.526"
                                  transform="translate(10819 -11412.684)"
                                  fill="#fff"
                                />
                                <rect
                                  id="Rectangle_296"
                                  data-name="Rectangle 296"
                                  width="35.301"
                                  height="11.767"
                                  rx="4"
                                  transform="translate(10818.629 -11353.254)"
                                  fill="#fff"
                                />
                                <g
                                  id="Group_307"
                                  data-name="Group 307"
                                  transform="translate(10811.071 -11422.486) rotate(3)"
                                >
                                  <g
                                    id="Group_306"
                                    data-name="Group 306"
                                    clipPath="url(#clip-path)"
                                  >
                                    <path
                                      id="Path_226"
                                      data-name="Path 226"
                                      d="M2.7,18.4A2.319,2.319,0,0,1,.463,16.69a13.221,13.221,0,1,1,25.765-1.1,2.319,2.319,0,1,1-4.562-.83,8.584,8.584,0,1,0-16.728.716A2.321,2.321,0,0,1,2.7,18.4"
                                      fill="#fff"
                                    />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </CircularProgressbarWithChildren>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  {isWeekend || isLeave ? (
                    <div>
                      <CircularProgressbarWithChildren
                        className="lateMinuteBar"
                        value={percentage}
                        strokeWidth={8}
                        styles={{
                          root: { width: '15vw', height: '15vw' },
                          path: { stroke: `rgba(255,60,57,255)` },
                          trail: { stroke: '#f0f0f0' },
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="todayAttendanceIcon"
                          viewBox="0 0 121.059 121"
                          style={{ margin: '30px' }}
                        >
                          <defs>
                            <linearGradient
                              id="skyvnm45ta"
                              x1=".218"
                              y1="-.023"
                              x2=".82"
                              y2=".837"
                              gradientUnits="objectBoundingBox"
                            >
                              <stop offset="0" stopColor="#407093" />
                              <stop
                                offset="1"
                                stopColor="#407093"
                                stopOpacity="0"
                              />
                            </linearGradient>
                          </defs>
                          <g
                            data-name="Group 73"
                            transform="translate(-684 -753)"
                          >
                            <circle
                              cx="60.5"
                              cy="60.5"
                              transform="translate(684 753)"
                              fill="#c8dae5"
                              r="60.5"
                            />
                            <path
                              data-name="Subtraction 1"
                              d="M3066.334 6607.866 3027 6579.2l65.745-49.681 27.144 24.366c-1.961 11.892-6 22.129-12 30.428a56.314 56.314 0 0 1-18.007 16.112 61.226 61.226 0 0 1-16.395 6.338 44.992 44.992 0 0 1-7.158 1.1zm27.339-78.208-.7-.308.2-.15.506.457z"
                              transform="translate(-2315.167 -5734.199)"
                              fill="url(#skyvnm45ta)"
                            />
                            <path
                              data-name="Path 85"
                              d="M4.741 103.426H64a4.741 4.741 0 0 1 4.741 4.74v42.667A4.741 4.741 0 0 1 64 155.574H4.741A4.741 4.741 0 0 1 0 150.833v-42.667a4.741 4.741 0 0 1 4.741-4.74zm0 0"
                              transform="translate(710 690.426)"
                              fill="#365e7d"
                            />
                            <path
                              data-name="Path 87"
                              d="M196.223 4.889V12h4.741V4.889h14.222V12h4.74V4.889a4.741 4.741 0 0 0-4.74-4.741h-14.222a4.741 4.741 0 0 0-4.741 4.741zm0 0"
                              transform="translate(536.295 781.852)"
                              fill="#365e7d"
                            />
                            <path
                              data-name="Path 88"
                              d="m68.741 152.184-29.63 13.037H29.63L0 152.184v-13.037a4.741 4.741 0 0 1 4.741-4.741H64a4.741 4.741 0 0 1 4.741 4.741zm0 0"
                              transform="translate(710 663.002)"
                              fill="#2b4d66"
                            />
                            <path
                              data-name="Path 89"
                              d="m68.741 121.2-29.63 13.037H29.63L0 121.2v-13.034a4.741 4.741 0 0 1 4.741-4.74H64a4.741 4.741 0 0 1 4.741 4.74zm0 0"
                              transform="translate(710 690.426)"
                              fill="#407093"
                            />
                            <path
                              data-name="Path 93"
                              d="M258.191 371.941h9.481v4.741a4.741 4.741 0 1 1-9.481 0zm0 0"
                              transform="translate(481.439 452.726)"
                              fill="#ffa230"
                            />
                            <path
                              data-name="Path 94"
                              d="M289.172 402.922h2.37v2.371h-2.37zm0 0"
                              transform="translate(454.013 425.301)"
                              fill="#fdb62f"
                            />
                            <path
                              data-name="Path 95"
                              d="M258.191 330.629h9.481v4.741h-9.481zm0 0"
                              transform="translate(481.439 489.297)"
                              fill="#ffa230"
                            />
                            <path
                              data-name="Path 96"
                              d="M258.191 330.629h9.481V333h-9.481zm0 0"
                              transform="translate(481.439 489.297)"
                              fill="#fdb62f"
                            />
                            <path
                              data-name="Path 97"
                              d="M258.191 371.941h9.481v2.37h-9.481zm0 0"
                              transform="translate(481.439 452.726)"
                              fill="#f0890c"
                            />
                          </g>
                        </svg>
                      </CircularProgressbarWithChildren>
                    </div>
                  ) : (
                    <div>
                      {!isAbsent ? (
                        <div>
                          <CircularProgressbarWithChildren
                            className="lateMinuteBar"
                            value={percentage}
                            strokeWidth={8}
                            styles={{
                              root: { width: '15vw', height: '15vw' },
                              path: { stroke: `rgba(255,60,57,255)` },
                              trail: { stroke: '#f0f0f0' },
                            }}
                          >
                            {todaysAttendance['late_minutes'] > 0 ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="todayAttendanceIcon"
                                viewBox="0 0 121.059 121"
                                style={{ margin: '10px' }}
                              >
                                <defs>
                                  <linearGradient
                                    id="1wvvmt0i1a"
                                    x1=".233"
                                    y1="-.294"
                                    x2="1.027"
                                    y2=".706"
                                    gradientUnits="objectBoundingBox"
                                  >
                                    <stop offset="0" stopColor="#b71c1c" />
                                    <stop
                                      offset="1"
                                      stopColor="#b71c1c"
                                      stopOpacity="0"
                                    />
                                  </linearGradient>
                                  <linearGradient
                                    id="j1yfotx60b"
                                    x1=".088"
                                    y1=".174"
                                    x2=".826"
                                    y2=".765"
                                  />
                                </defs>
                                <g data-name="Layer 2">
                                  <g
                                    data-name="06.Dislike"
                                    transform="translate(-.21 -.21)"
                                  >
                                    <circle
                                      fill="#eb2540"
                                      cx="60.5"
                                      cy="60.5"
                                      r="60.5"
                                      transform="translate(.21 .21)"
                                    />
                                    <path
                                      fill="url(#1wvvmt0i1a)"
                                      data-name="Path 71"
                                      d="M57.771 83.79s-4.587 9.087-4.587 16.092v.072L35.56 82.33a5.25 5.25 0 0 0 3.646 1.46z"
                                      transform="translate(-7.19 -16.646)"
                                    />
                                    <path
                                      fill="url(#j1yfotx60b)"
                                      data-name="Path 72"
                                      d="M134.006 68.3a60.634 60.634 0 0 1-43.88 58.3L63 99.47a9.574 9.574 0 0 0 3.909.734c2.106 0 1.428-7.835 1.939-10.882.8-4.787 16.132-13.563 16.132-18.35V40.583a3.814 3.814 0 0 0-.742-2.266l4.651 4.651V70.94a3.893 3.893 0 0 0 3.885 3.869h10.643a3.893 3.893 0 0 0 3.877-3.869V40.583a3.845 3.845 0 0 0-1.094-2.673l27.764 27.756c.04.87.04 1.747.04 2.633z"
                                      transform="translate(-12.737 -7.665)"
                                    />
                                    <path
                                      fill="#fff"
                                      data-name="Path 73"
                                      d="M82.909 40.2a3.893 3.893 0 0 1 3.885-3.861h10.644a3.885 3.885 0 0 1 3.877 3.861v30.354a3.893 3.893 0 0 1-3.877 3.869H86.795a3.893 3.893 0 0 1-3.885-3.869zM37.6 63.98a5.29 5.29 0 0 1 2.123-9.28 5.282 5.282 0 0 1 2.106-9.279A5.3 5.3 0 0 1 45.125 36l30.014.335A3.877 3.877 0 0 1 79 40.2v30.354C79 75.34 63.69 84.18 62.868 88.9c-.511 3.048.168 10.882-1.939 10.882-3.574 0-8.162-1.372-8.162-9.311 0-7 4.587-16.092 4.587-16.092H38.79A5.29 5.29 0 0 1 37.6 63.94z"
                                      transform="translate(-6.773 -7.278)"
                                    />
                                  </g>
                                </g>
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="todayAttendanceIcon"
                                viewBox="0 0 121.059 121"
                                style={{ margin: '10px' }}
                              >
                                <defs>
                                  <linearGradient
                                    id="dponfxfcra"
                                    x1=".218"
                                    y1="-.023"
                                    x2=".82"
                                    y2=".837"
                                    gradientUnits="objectBoundingBox"
                                  >
                                    <stop offset="0" stopColor="#47a144" />
                                    <stop
                                      offset="1"
                                      stopColor="#6fcf6b"
                                      stopOpacity="0"
                                    />
                                  </linearGradient>
                                </defs>
                                <g
                                  data-name="Group 72"
                                  transform="translate(-683.559 -771)"
                                >
                                  <circle
                                    cx="60.5"
                                    cy="60.5"
                                    transform="translate(683.559 771)"
                                    fill="#6fcf6b"
                                    r="60.5"
                                  />
                                  <path
                                    data-name="Subtraction 2"
                                    d="m3066.332 7788.5-37.815-27.561-1.517.346.473-1.108-.473-.344.917-.694 14.912-34.891h7l22.357-24.093 9.44 8.031 6.336 5.579.117-.089 30.765 26.173a68.444 68.444 0 0 1-10.953 25.1 56.269 56.269 0 0 1-18.006 16.11 61.219 61.219 0 0 1-16.394 6.339 45.684 45.684 0 0 1-7.154 1.1h-.006z"
                                    transform="translate(-2315.328 -6897.5)"
                                    fill="url(#dponfxfcra)"
                                  />
                                  <path
                                    data-name="Path 73"
                                    d="M51.907 95.589a3.893 3.893 0 0 1-3.885 3.861H37.379a3.885 3.885 0 0 1-3.879-3.861V65.232a3.893 3.893 0 0 1 3.877-3.869h10.645a3.893 3.893 0 0 1 3.885 3.869zm45.308-23.783a5.29 5.29 0 0 1-2.122 9.279 5.282 5.282 0 0 1-2.106 9.279 5.3 5.3 0 0 1-3.295 9.422l-30.014-.335a3.877 3.877 0 0 1-3.861-3.861V65.232c0-4.787 15.31-13.627 16.132-18.35.51-3.047-.168-10.882 1.938-10.882 3.574 0 8.162 1.372 8.162 9.311 0 7-4.587 16.092-4.587 16.092h18.565a5.29 5.29 0 0 1 1.189 10.443z"
                                    transform="translate(676.726 764)"
                                    fill="#fff"
                                  />
                                </g>
                              </svg>
                            )}
                          </CircularProgressbarWithChildren>
                        </div>
                      ) : (
                        <div>
                          <CircularProgressbarWithChildren
                            className="lateMinuteBar"
                            value={percentage}
                            strokeWidth={8}
                            styles={{
                              root: { width: '15vw', height: '15vw' },
                              path: { stroke: `rgba(255,60,57,255)` },
                              trail: { stroke: '#f0f0f0' },
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="todayAttendanceIcon"
                              viewBox="0 0 121 121"
                              // style={{ margin: '30px' }}
                            >
                              <defs>
                                <clipPath id="57sznm2nhb">
                                  <path
                                    data-name="Rectangle 140"
                                    fill="none"
                                    d="M0 0h78.022v78.022H0z"
                                  />
                                </clipPath>
                                <clipPath id="2kmwufvptc">
                                  <path
                                    data-name="Rectangle 141"
                                    fill="#ff8282"
                                    d="M0 0h38.481v76.307H0z"
                                  />
                                </clipPath>
                                <clipPath id="9iu7mnehvd">
                                  <path
                                    data-name="Rectangle 147"
                                    fill="none"
                                    d="M0 0h10.625v28.207H0z"
                                  />
                                </clipPath>
                                <clipPath id="yezwt1vvte">
                                  <path
                                    data-name="Rectangle 148"
                                    fill="none"
                                    d="M0 0h20.151v3.052H0z"
                                  />
                                </clipPath>
                                <clipPath id="m0yl4l1bkf">
                                  <path
                                    data-name="Rectangle 149"
                                    fill="none"
                                    d="M0 0h76.629v76.629H0z"
                                  />
                                </clipPath>
                                <linearGradient
                                  id="3jjuiichxa"
                                  x1=".166"
                                  y1=".223"
                                  x2=".916"
                                  y2=".844"
                                  gradientUnits="objectBoundingBox"
                                >
                                  <stop offset="0" stopColor="#2086f1" />
                                  <stop
                                    offset="1"
                                    stopColor="#509ae8"
                                    stopOpacity="0"
                                  />
                                </linearGradient>
                              </defs>
                              <g
                                data-name="Group 121"
                                transform="translate(-684 -753)"
                              >
                                <circle
                                  cx="60.5"
                                  cy="60.5"
                                  transform="translate(684 753)"
                                  fill="#d9f2ee"
                                  r="60.5"
                                />
                                <path
                                  data-name="Subtraction 5"
                                  d="m-4133.514 12649.609-41.486-29.257 61.188-46.232c-1.05-.953-2.077-1.9-3.055-2.8l-.25-.231-.009-.01 3.771-12.774 3.174 2.746.163.34 26.389 25.267a67.77 67.77 0 0 1-.128 9.042l.355.317c-1.961 11.893-6 22.129-12 30.429a56.256 56.256 0 0 1-18.007 16.111 60.991 60.991 0 0 1-16.4 6.34 54.6 54.6 0 0 1-3.711.714z"
                                  transform="translate(4888.124 -11776.332)"
                                  style={{
                                    opacity: '.53',
                                    fill: 'url(#3jjuiichxa)',
                                  }}
                                />
                                <path
                                  data-name="Path 113"
                                  d="M96.5 88.175a44.125 44.125 0 1 1-43.519-44.721A44.125 44.125 0 0 1 96.5 88.175"
                                  transform="translate(691.744 725.551)"
                                  fill="#3b568b"
                                />
                                <g
                                  data-name="Group 90"
                                  style={{
                                    opacity: '.93',
                                  }}
                                >
                                  <g data-name="Group 89">
                                    <g
                                      data-name="Group 88"
                                      style={{ clipPath: 'url(#57sznm2nhb)' }}
                                      transform="translate(705.229 774.201)"
                                    >
                                      <path
                                        data-name="Path 114"
                                        d="M100.834 97.473a39.011 39.011 0 1 1-38.477-39.538 39.011 39.011 0 0 1 38.477 39.537"
                                        transform="translate(-22.816 -57.931)"
                                        fill="#fff"
                                      />
                                    </g>
                                  </g>
                                </g>
                                <g data-name="Group 93">
                                  <g data-name="Group 92">
                                    <g
                                      data-name="Group 91"
                                      style={{ clipPath: 'url(#2kmwufvptc)' }}
                                      transform="translate(744.687 774.087)"
                                    >
                                      <path
                                        data-name="Path 115"
                                        d="M132.686 57.615V95.8l10.922 38.118a39.015 39.015 0 0 0-10.922-76.307"
                                        transform="translate(-132.686 -57.615)"
                                        fill="#ff8282"
                                      />
                                    </g>
                                  </g>
                                </g>
                                <g
                                  data-name="Group 108"
                                  style={{ opacity: '.3' }}
                                >
                                  <g data-name="Group 107">
                                    <g
                                      data-name="Group 106"
                                      style={{ clipPath: 'url(#9iu7mnehvd)' }}
                                      transform="translate(744.156 813.723)"
                                    >
                                      <path
                                        data-name="Path 122"
                                        d="m0 0 3.829 14.745 6.8 13.462-3.12-15.65z"
                                      />
                                    </g>
                                  </g>
                                </g>
                                <path
                                  data-name="Path 123"
                                  d="m130.262 162.2 2.854 17.411 6.8 13.462-3.12-15.65z"
                                  transform="translate(613.554 649.445)"
                                  fill="#263238"
                                />
                                <g
                                  data-name="Group 111"
                                  style={{ opacity: '.3' }}
                                >
                                  <g data-name="Group 110">
                                    <g
                                      data-name="Group 109"
                                      style={{ clipPath: 'url(#yezwt1vvte)' }}
                                      transform="translate(724.588 811.117)"
                                    >
                                      <path
                                        data-name="Path 124"
                                        d="M20.151 1.9 9.324 3.052-.001 1.78 9.668-.001z"
                                      />
                                    </g>
                                  </g>
                                </g>
                                <path
                                  data-name="Path 125"
                                  d="M95.826 160.454 85 161.606l-9.325-1.272 9.669-1.781z"
                                  transform="translate(648.538 651.783)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 126"
                                  d="m124.135 67.553-.065 4.754-.67-.007.057-4.163-1.073-.015.008-.591z"
                                  transform="translate(618.605 710.118)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 127"
                                  d="m133.017 71.782-.008.591-3.416-.046.006-.462 1.961-1.842c.509-.482.608-.78.613-1.078.006-.489-.336-.793-1-.8a1.494 1.494 0 0 0-1.222.5l-.456-.407a2.193 2.193 0 0 1 1.748-.7c.992.014 1.623.532 1.612 1.347a1.926 1.926 0 0 1-.8 1.484l-1.472 1.379z"
                                  transform="translate(614.048 710.111)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 128"
                                  d="m178.267 81.533-.065 4.754-.673-.009.057-4.163-1.073-.015.008-.591z"
                                  transform="translate(583.914 701.16)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 129"
                                  d="m210.035 120.9-.008.591-3.416-.046.007-.462 1.961-1.841c.509-.482.608-.78.613-1.079.006-.489-.336-.793-1-.8a1.494 1.494 0 0 0-1.222.5l-.456-.407a2.193 2.193 0 0 1 1.748-.7c.992.014 1.623.532 1.612 1.347a1.925 1.925 0 0 1-.8 1.484l-1.474 1.383z"
                                  transform="translate(564.689 678.633)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 130"
                                  d="M219.391 163.43c-.011.8-.611 1.446-1.82 1.429a2.7 2.7 0 0 1-1.737-.6l.32-.532a2.142 2.142 0 0 0 1.426.529c.713.01 1.125-.311 1.132-.827s-.349-.834-1.164-.845l-.38-.005.006-.475 1.215-1.451-2.289-.031.008-.591 3.138.042-.006.462-1.264 1.518c.95.1 1.425.645 1.415 1.378"
                                  transform="translate(558.714 650.838)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 131"
                                  d="m209.662 210.218-.9-.012-.016 1.168-.659-.009.016-1.168-2.643-.036.006-.475 2.474-3.078.733.01-2.37 2.97 1.827.025.014-1.032.639.009-.014 1.032.9.012z"
                                  transform="translate(565.364 620.987)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 132"
                                  d="M175.1 241.039c-.011.822-.6 1.472-1.82 1.456a2.724 2.724 0 0 1-1.738-.6l.32-.532a2.137 2.137 0 0 0 1.42.529c.72.01 1.132-.317 1.139-.834.007-.537-.328-.874-1.428-.889l-1.08-.015.285-2.482 2.683.036-.008.591-2.112-.029-.147 1.3.53.007c1.42.019 1.968.618 1.957 1.46"
                                  transform="translate(587.098 601.08)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 133"
                                  d="M129.174 254.984a1.538 1.538 0 0 1-1.7 1.478c-1.277-.017-1.985-.883-1.964-2.4a2.193 2.193 0 0 1 2.282-2.462 2.293 2.293 0 0 1 1.178.294l-.265.527a1.651 1.651 0 0 0-.907-.237c-.965-.013-1.591.583-1.607 1.772a1.532 1.532 0 0 0 0 .17 1.582 1.582 0 0 1 1.374-.606 1.469 1.469 0 0 1 1.61 1.462m-.659.018c.008-.557-.409-.923-1.075-.932a.987.987 0 0 0-1.106.909c-.007.489.381.922 1.115.932a.941.941 0 0 0 1.065-.909"
                                  transform="translate(616.603 592.152)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 134"
                                  d="m85.887 236.481-.006.462-1.981 4.267-.72-.01 1.938-4.138-2.173-.03-.011.849-.652-.009.02-1.44z"
                                  transform="translate(644.304 601.873)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 135"
                                  d="M53.452 207.807c-.012.863-.752 1.389-1.914 1.373s-1.874-.562-1.862-1.425a1.179 1.179 0 0 1 .851-1.136 1.075 1.075 0 0 1-.652-1.035c.011-.795.7-1.281 1.729-1.267s1.718.519 1.708 1.314a1.081 1.081 0 0 1-.686 1.016 1.182 1.182 0 0 1 .826 1.159m-.679-.029c.007-.523-.45-.855-1.2-.866s-1.207.31-1.214.832.437.876 1.191.886 1.22-.316 1.228-.853m-2.226-2.157c-.006.469.377.766 1.029.775s1.063-.278 1.07-.747-.411-.773-1.049-.781-1.043.278-1.05.753"
                                  transform="translate(665.2 622.455)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 136"
                                  d="M40.206 158.794a2.193 2.193 0 0 1-2.283 2.462 2.288 2.288 0 0 1-1.178-.295l.265-.526a1.673 1.673 0 0 0 .914.236c.964.013 1.584-.583 1.6-1.771v-.17a1.586 1.586 0 0 1-1.38.606 1.464 1.464 0 0 1-1.6-1.462 1.532 1.532 0 0 1 1.7-1.478c1.277.017 1.985.883 1.965 2.4m-.823-.915c.006-.489-.381-.922-1.122-.932a.935.935 0 0 0-1.058.909c-.008.557.4.923 1.067.932a.989.989 0 0 0 1.113-.909"
                                  transform="translate(673.616 653.167)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 137"
                                  d="m48.012 114.592-.065 4.754-.673-.009.057-4.163-1.073-.015.008-.591z"
                                  transform="translate(667.39 679.972)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 138"
                                  d="M53.946 117c.021-1.549.862-2.42 1.976-2.405s1.924.909 1.9 2.458-.862 2.42-1.969 2.405-1.931-.909-1.909-2.458m3.2.043c.017-1.216-.491-1.834-1.231-1.844s-1.272.594-1.288 1.81.492 1.834 1.238 1.844 1.265-.594 1.281-1.81"
                                  transform="translate(662.463 679.955)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 139"
                                  d="m80.918 80.21-.065 4.754-.673-.009.057-4.163-1.073-.015.008-.591z"
                                  transform="translate(646.301 702.008)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 140"
                                  d="m87.917 80.3-.065 4.754-.673-.009.057-4.163-1.073-.015.008-.591z"
                                  transform="translate(641.815 701.946)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 141"
                                  d="M191.619 228.648a.621.621 0 1 1-.612-.629.621.621 0 0 1 .612.629"
                                  transform="translate(575.028 607.266)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 142"
                                  d="M151.281 252.432a.621.621 0 1 1-.612-.629.621.621 0 0 1 .612.629"
                                  transform="translate(600.879 592.023)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 143"
                                  d="M214.281 190.063a.621.621 0 1 1-.612-.629.621.621 0 0 1 .612.629"
                                  transform="translate(560.505 631.993)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 144"
                                  d="M213.216 141.212a.621.621 0 1 1-.612-.629.621.621 0 0 1 .612.629"
                                  transform="translate(561.188 663.301)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 145"
                                  d="M191.641 99.877a.621.621 0 1 1-.612-.629.621.621 0 0 1 .612.629"
                                  transform="translate(575.014 689.791)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 146"
                                  d="M155.516 77.693a.621.621 0 1 1-.612-.629.621.621 0 0 1 .612.629"
                                  transform="translate(598.165 704.009)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 147"
                                  d="M63.767 226.91a.621.621 0 1 0 .629-.612.621.621 0 0 0-.629.612"
                                  transform="translate(656.168 608.369)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 148"
                                  d="M103.444 251.782a.621.621 0 1 0 .629-.612.621.621 0 0 0-.629.612"
                                  transform="translate(630.741 592.429)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 149"
                                  d="M42.163 187.723a.621.621 0 1 0 .629-.612.621.621 0 0 0-.629.612"
                                  transform="translate(670.015 633.481)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 150"
                                  d="M44.555 138.919a.621.621 0 1 0 .629-.612.621.621 0 0 0-.629.612"
                                  transform="translate(668.481 664.76)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 151"
                                  d="M67.246 98.186a.621.621 0 1 0 .629-.612.621.621 0 0 0-.629.612"
                                  transform="translate(653.939 690.864)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 152"
                                  d="M103.96 76.992a.621.621 0 1 0 .629-.612.621.621 0 0 0-.629.612"
                                  transform="translate(630.41 704.446)"
                                  fill="#263238"
                                />
                                <path
                                  data-name="Path 153"
                                  d="M129.406 159.014a2.463 2.463 0 1 1-2.945-1.859 2.463 2.463 0 0 1 2.945 1.859"
                                  transform="translate(617.221 652.719)"
                                  fill="#263238"
                                />
                                <g
                                  data-name="Group 114"
                                  style={{ opacity: '.3' }}
                                >
                                  <g data-name="Group 113">
                                    <g
                                      data-name="Group 112"
                                      style={{ clipPath: 'url(#m0yl4l1bkf)' }}
                                      transform="translate(705.812 774.812)"
                                    >
                                      <path
                                        data-name="Path 154"
                                        d="M43.637 76.27a38.77 38.77 0 0 1-5.844.359 38.152 38.152 0 1 1 5.844-.359M33.091 1.07a37.7 37.7 0 1 0 5.734-.353 38.082 38.082 0 0 0-5.734.353"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </CircularProgressbarWithChildren>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            {isWorkFromHome || isWorkFromHomeCheckOut ? (
              <div>
                {isWorkFromHome && (
                  <h3 className="attendance_overview_footer">
                    Click the check In Icon Above To Mark Your Check In For
                    Today
                  </h3>
                )}
                {isWorkFromHomeCheckOut && (
                  <h3 className="attendance_overview_footer">
                    Click the check Out Icon Above To Mark Your Check Out For
                    Today
                  </h3>
                )}
              </div>
            ) : (
              <div>
                {!isWeekend && !isLeave && !isAbsent && !isWorkFromHome ? (
                  <div>
                    {todaysAttendance['late_minutes'] > 0 ? (
                      <h3 className="attendance_overview_footer">
                        Oops! You were {todaysAttendance['late_minutes']} mins
                        late today!
                      </h3>
                    ) : (
                      <h3 className="attendance_overview_footer">
                        Bravo! You were on time today!
                      </h3>
                    )}
                  </div>
                ) : (
                  <div>
                    {isWeekend ? (
                      <h3 className="attendance_overview_footer">
                        Enjoy your weekend!
                      </h3>
                    ) : (
                      <div>
                        {!isAbsent ? (
                          <h3 className="attendance_overview_footer">
                            Oh, You are on leave today!
                          </h3>
                        ) : (
                          <h3 className="attendance_overview_footer">
                            You still haven't arrived!
                          </h3>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </Card>

          {/* announcement */}
          <Card
            // className="public_holidays  margin_top_public_holidays statistic_attendance"
            className="attendance_overview attendance_overview__second margin_top_public_holidays statistic_attendance"
            variant="outlined"
          >
            <div className="attendance_overview__header">
              <div className="attendance_overview__header_text">
                <h3>Statistics</h3>
              </div>
              <div className="attendance_overview__header_text attendance_overview__header_text_right">
                <a style={{ paddingRight: '5px' }} href="/attendance">
                  View Details
                </a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="viewMoreSvgIcon"
                  viewBox="0 0 11.917 13"
                >
                  <g data-name="Group 74">
                    <path d="m2.653 10.42 3.674-3.671-3.675-3.675a.944.944 0 0 1 1.335-1.335l4.347 4.347a.943.943 0 0 1 0 1.335l-4.346 4.343a.943.943 0 0 1-1.335 0 .963.963 0 0 1 0-1.344z" />
                    <path
                      fill="none"
                      data-name="Rectangle 90"
                      transform="rotate(-90 6.5 6.5)"
                      d="M0 0h13v11.917H0z"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div className="statistics">
              <div className="single__statistics">
                <div className="statistics_body">
                  <div className="statistics_header">
                    <div className="statistics_header_text">
                      <h3>Today Late Minutes</h3>
                    </div>
                    <div className="statistics_header_text">
                      {!isWeekend && !isLeave && !isWorkFromHome ? (
                        <h3>{todaysAttendance['late_minutes']} mins</h3>
                      ) : (
                        <h3>---</h3>
                      )}
                    </div>
                  </div>
                  <Box sx={{ flexGrow: 1 }}>
                    <TodayBorderLinearProgress
                      className="attendanceProgress"
                      variant="determinate"
                      value={percentage}
                    />
                  </Box>
                </div>
              </div>
              <div className="single__statistics">
                <div className="statistics_body">
                  <div className="statistics_header">
                    <div className="statistics_header_text">
                      <h3>This week's Late Minutes</h3>
                    </div>
                    <div className="statistics_header_text">
                      <h3>{weekLateMinutes} mins</h3>
                    </div>
                  </div>
                  <Box sx={{ flexGrow: 1 }}>
                    <WeekBorderLinearProgress
                      variant="determinate"
                      className="attendanceProgress"
                      value={weekPercentage}
                    />
                  </Box>
                </div>
              </div>
              <div className="single__statistics">
                <div className="statistics_body">
                  <div className="statistics_header">
                    <div className="statistics_header_text">
                      <h3>This month's Late Minutes</h3>
                    </div>
                    <div className="statistics_header_text">
                      <h3>{monthLateMinutes} mins</h3>
                    </div>
                  </div>
                  <Box sx={{ flexGrow: 1 }}>
                    <MonthBorderLinearProgress
                      variant="determinate"
                      className="attendanceProgress"
                      value={monthPercentage}
                    />
                  </Box>
                </div>
              </div>
              <div className="single__statistics">
                <div className="statistics_body">
                  <div className="statistics_header">
                    <div className="statistics_header_text">
                      <h3>Overtime</h3>
                    </div>
                    <div className="statistics_header_text">
                      <h3>{monthExtraMinutes}</h3>
                    </div>
                  </div>
                  <Box sx={{ flexGrow: 1 }}>
                    <OvertimeBorderLinearProgress
                      variant="determinate"
                      className="attendanceProgress"
                      value={monthExtraMinutePercentage}
                    />
                  </Box>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Layout>
  );
}
