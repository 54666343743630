import 'react-toastify/dist/ReactToastify.css';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import OutlinedInput from '@mui/material/OutlinedInput';
import { alpha, styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Layout from '../../components/layout';
import AttendanceService from '../../services/AttendanceService';
import UserService from '../../services/UserService';
import ValidationModal from '../BootstrapValidationModal';

// Define the type for location state
interface LocationState {
  date: string; // or Date if you're passing a Date object
}

export default function EditAttendance() {
  const { userIdAttendance, checkInId, checkOutId } = useParams<{
    userIdAttendance: string;
    checkInId: string;
    checkOutId: string;
  }>();

  const { usersId } = useParams() as { usersId: string };
  const location = useLocation<LocationState>();
  const { date } = location.state;
  const jsDate = new Date(date);

  const history = useHistory();

  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCheckInDate, setSelectedCheckInDate] = useState(null);
  const [selectedCheckOutDate, setSelectedCheckOutDate] = useState(null);
  const [checkInDateError, setCheckInDateError] = useState(false);
  const [checkOutDateError, setCheckOutDateError] = useState(false);
  const [selectedCheckInId, setSelectedCheckInId] = useState(null);
  const [selectedCheckOutId, setSelectedCheckOutId] = useState(null);
  const [isSubmittingModalOpen, setIsSubmittingModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [progress, setProgress] = React.useState(0);
  const [currentUserId, setCurrentUserId] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    let intervalId;
    let isMounted = true;

    const startProgress = () => {
      intervalId = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress = oldProgress + 100 / steps;
          if (newProgress >= 100) {
            clearInterval(intervalId);
            setTimeout(() => {
              if (isMounted) {
                setIsSubmittingModalOpen(false);
                history.push(`/attendance/view/${currentUserId}`);
              }
            }, 500);
            return 100;
          }
          return newProgress;
        });
      }, intervalDuration);
    };

    const intervalDuration = 30;
    const totalTime = 1000;
    const steps = totalTime / intervalDuration;

    if (isSubmittingModalOpen) {
      startProgress();
    }

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [isSubmittingModalOpen]);

  const closeSubmittingModal = () => {
    setIsSubmittingModalOpen(false);
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const parsedUser = JSON.parse(storedUser);
    setUserId(parsedUser.id);
    const fetchUserData = async () => {
      if (parsedUser.roleId === 35) {
        // Specific user IDs to process
        const specificUserIds = [parsedUser.id, 18, 56];
        const allUsersData = [];

        // Loop through each user ID
        for (const userId of specificUserIds) {
          // Fetch team details for the current user ID
          const user_details = await UserService.findTeam(userId);

          // Find the specific user in the team details
          const foundItem = user_details['user'].find(
            (item) => item.id === parseInt(usersId),
          );

          if (!foundItem) {
            console.log(`User with ID ${userId} not found in the team.`);
            continue; // Skip to the next user if not found
          } else {
            console.log(
              `Found user: (${foundItem.first_name})${foundItem.last_name}`,
            );
          }

          // Prepare the data for attendance update
          const data = {
            checkInId: checkInId,
            checkOutId: checkOutId,
          };

          // Fetch attendance details for the current user
          const attendance = await AttendanceService.edit(userId, data);

          // Process check-in details
          if (attendance.data.checkInDetails) {
            const dayjsCheckInObject = dayjs(
              attendance.data.checkInDetails.recordTime,
            );
            setSelectedCheckInDate(dayjsCheckInObject);
            setSelectedCheckInId(attendance.data.checkInDetails.id);
          }

          // Process check-out details
          if (attendance.data.checkOutDetails) {
            const dayjsCheckOutObject = dayjs(
              attendance.data.checkOutDetails.recordTime,
            );
            setSelectedCheckOutDate(dayjsCheckOutObject);
            setSelectedCheckOutId(attendance.data.checkOutDetails.id);
          }

          // Decrypt and set the full name of the user
          const fullNameOfUser =
            foundItem.first_name + ' ' + foundItem.last_name;

          // Add the processed data to the array
          allUsersData.push({
            userId: userId,
            fullName: fullNameOfUser,
            attendance: attendance.data,
          });
        }

        // Set the state with the combined data for all users
        setCurrentUserId(parsedUser.id); // Default to the logged-in user's ID
        setUserName(allUsersData.map((user) => user.fullName).join(', ')); // Combine names for display

        // Log the processed data for debugging
        console.log('Processed User Data:', allUsersData);
      } else {
        const user_details = await UserService.findTeam(parsedUser.id);
        const foundItem = user_details['user'].find(
          (item) => item.id === parseInt(usersId),
        );
        const data = {
          checkInId: checkInId,
          checkOutId: checkOutId,
        };
        const attendance = await AttendanceService.edit(usersId, data);
        setCurrentUserId(attendance.data.usersId);
        if (attendance.data.checkInDetails) {
          const dayjsCheckInObject = dayjs(
            attendance.data.checkInDetails.recordTime,
          );
          setSelectedCheckInDate(dayjsCheckInObject);
          setSelectedCheckInId(attendance.data.checkInDetails.id);
        }

        if (attendance.data.checkOutDetails) {
          const dayjsCheckOutObject = dayjs(
            attendance.data.checkOutDetails.recordTime,
          );
          setSelectedCheckOutDate(dayjsCheckOutObject);
          setSelectedCheckOutId(attendance.data.checkOutDetails.id);
        }

        const fullNameOfUser = foundItem.first_name + ' ' + foundItem.last_name;
        setUserName(fullNameOfUser);
      }
    };
    const check = async (loggedInUser) => {
      const check = await AttendanceService.check(usersId, loggedInUser);
      if (check) {
        fetchUserData();
      } else {
        history.push(`/attendance`);
      }
    };
    if (
      parsedUser.roleId === 1 ||
      parsedUser.roleId === 4 ||
      parsedUser.roleId === 35
    ) {
      fetchUserData();
    } else {
      check(parsedUser.id);
    }
  }, [userIdAttendance]);

  const onSubmit = async () => {
    if (!selectedCheckInDate) {
      setCheckInDateError(true);
      setIsErrorModalOpen(true);
      setErrorMessage('Please enter check in time');
      return;
    } else {
      setCheckInDateError(false);
      setErrorMessage('');
    }

    if (!selectedCheckOutDate) {
      setCheckOutDateError(true);
      setIsErrorModalOpen(true);
      setErrorMessage('Please enter check out time');
      return;
    } else {
      setCheckOutDateError(false);
      setErrorMessage('');
    }
    const isValid = selectedCheckOutDate.isAfter(selectedCheckInDate);

    if (!isValid) {
      // setValidDateError(true);
      setCheckInDateError(true);
      setCheckOutDateError(true);
      setIsErrorModalOpen(true);
      setErrorMessage('Check Out should be marked after Check in');

      return;
    } else {
      // setValidDateError(false);
      setCheckInDateError(false);
      setCheckOutDateError(false);
      setErrorMessage('');
    }
    setIsSubmitting(true);
    try {
      const checkInDateTime = dayjs(date)
        .set('hour', dayjs(selectedCheckInDate, 'HH:mm').hour())
        .set('minute', dayjs(selectedCheckInDate, 'HH:mm').minute());

      const checkOutDateTime = dayjs(date)
        .set('hour', dayjs(selectedCheckOutDate, 'HH:mm').hour())
        .set('minute', dayjs(selectedCheckOutDate, 'HH:mm').minute());

      const data = {
        check_in_id: selectedCheckInId ? selectedCheckInId : 'empty',
        check_in: checkInDateTime,
        check_out_id: selectedCheckOutId ? selectedCheckOutId : 'empty',
        check_out: checkOutDateTime,
        usersId: usersId,
        full_name: userName,
        punchById: userId,
      };
      await AttendanceService.addMissedAttendance(data);
      setIsSubmitting(false);
      setIsSubmittingModalOpen(true);
    } catch (error) {
      setIsSubmitting(false);
    }
  };
  const minTime = dayjs(jsDate).startOf('day').hour(8);
  const handleCheckInDateChange = (time) => {
    if (!time) {
      setCheckInDateError(true);
      return;
    }
    if (selectedCheckOutDate) {
      const checkInTime = dayjs(time.$d);
      const checkOutDate = dayjs(selectedCheckOutDate.$d);

      const year = checkOutDate.year();
      const month = checkOutDate.month();
      const day = checkOutDate.date();

      const hour = checkInTime.hour();
      const minute = checkInTime.minute();
      const second = checkInTime.second();

      const newTime = dayjs()
        .year(year)
        .month(month)
        .date(day)
        .hour(hour)
        .minute(minute)
        .second(second);
      setCheckInDateError(false);
      setSelectedCheckInDate(newTime);
    } else {
      setCheckInDateError(false);
      setSelectedCheckInDate(time);
    }
  };

  const handleCheckOutDateChange = (date) => {
    if (selectedCheckInDate) {
      const checkInTime = dayjs(selectedCheckInDate.$d);
      const checkOutDate = dayjs(date.$d);

      const year = checkInTime.year();
      const month = checkInTime.month();
      const day = checkInTime.date();

      const hour = checkOutDate.hour();
      const minute = checkOutDate.minute();
      const second = checkOutDate.second();

      const newTime = dayjs()
        .year(year)
        .month(month)
        .date(day)
        .hour(hour)
        .minute(minute)
        .second(second);
      setSelectedCheckOutDate(newTime);
    } else {
      setSelectedCheckOutDate(date);
    }
  };

  const maxAllowedTime = new Date();
  maxAllowedTime.setHours(23, 59, 59, 999);

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
      border: '1px solid',
      borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
      fontSize: 16,
      width: 'auto',
      padding: '10px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  return (
    <Layout>
      <div className="leavesIndex">
        <h4 className="attendance_overview_heading">Edit Attendance</h4>
        <div className="leavesIndex__body">
          <div className="leavesIndex__body_padding">
            <Box
              component="form"
              noValidate
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr' },
                gap: 2,
              }}
            >
              <div>
                <div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {/* <div style={{ width: '45%' }} className="singleInputFields">
                      <FormControl variant="standard">
                        <InputLabel
                          style={{ marginBottom: '1vw' }}
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          User Name
                        </InputLabel>
                        <BootstrapInput
                          defaultValue={userName}
                          id="bootstrap-input"
                          readOnly
                        />
                      </FormControl>
                    </div> */}
                    <div className="singleInputField singleInputFieldForm singleInputEditAttendance">
                      <div className="mt-2 flex items-center">
                        <InputLabel
                          className="formInputLabels"
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          User Name
                        </InputLabel>
                        <FormControl variant="standard">
                          <OutlinedInput
                            name="specific_item"
                            value={userName}
                            readOnly
                          />
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="singleInputField singleInputFieldForm singleInputEditAttendance">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker']}>
                          <InputLabel
                            className="formInputLabels"
                            shrink
                            htmlFor="bootstrap-input"
                          >
                            CheckIn
                          </InputLabel>

                          <TimePicker
                            onChange={handleCheckInDateChange}
                            className={`leaveDurationInput ${
                              checkInDateError ? 'leaveDurationInput-error' : ''
                            }`}
                            minTime={minTime}
                            value={selectedCheckInDate}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="singleInputField singleInputFieldForm singleInputEditAttendance">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker']}>
                          <InputLabel
                            className="formInputLabels"
                            shrink
                            htmlFor="bootstrap-input"
                          >
                            Check Out
                          </InputLabel>

                          <TimePicker
                            onChange={handleCheckOutDateChange}
                            value={selectedCheckOutDate}
                            className={`leaveDurationInput ${
                              checkOutDateError
                                ? 'leaveDurationInput-error'
                                : ''
                            }`}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '5vw',
                    }}
                  >
                    <div className="singleInputField"></div>
                    <div className="singleInputField">
                      <div className="formButton">
                        <button
                          type="button"
                          disabled={isSubmitting}
                          className="formSubmitButton"
                          onClick={onSubmit}
                        >
                          {isSubmitting ? 'Submitting...' : 'Submit'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </div>
        <ValidationModal
          isOpen={isSubmittingModalOpen}
          onClose={closeSubmittingModal}
        >
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div className="ProgressModal">
                <Box className="progressOfSubmitting" sx={{ width: '100%' }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                      height: 15,
                      borderRadius: 10,
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#1fc214',
                      },
                    }}
                  />
                </Box>
                <span>Submitting Request</span>
              </div>
            </div>
          </div>
        </ValidationModal>

        <ValidationModal isOpen={isErrorModalOpen} onClose={closeErrorModal}>
          <div className="modal__popup">
            <div className="ValidationModal">
              <span>{errorMessage}</span>
              <div className="CloseValidationModalButton">
                <button
                  type="button"
                  disabled={isSubmitting}
                  className="CloseValidationModal"
                  onClick={closeErrorModal}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </ValidationModal>
      </div>
    </Layout>
  );
}
