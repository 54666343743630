import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../../components/layout';
import LeaveService from '../../services/LeaveService';
import LeaveTypeService from '../../services/LeaveTypeService';
import ValidationModal from '../BootstrapValidationModal';
import RejectionModal, { InternModal } from '../RejectionModal';

const TwoDaysBefore = dayjs().subtract(2, 'day');

export default function NewLeave() {
  const history = useHistory();

  const [allLeaveType, setAllLeaveType] = useState([]);
  const [selectedLeaveType, setSelectedLeaveType] = useState('');
  const [descriptionText, setDescriptionText] = useState('');
  const [selectedHalf, setSelectedHalf] = useState('');
  const [selectedleaveTenure, setSelectedleaveTenure] = useState('');
  const [selectedHalfDayDate, setSelectedHalfDayDate] = useState(null);
  const [selectedShortFromDate, setSelectedShortFromDate] = useState(null);
  const [selectedShortToDate, setSelectedShortToDate] = useState(null);

  const [selectedDate, setSelectedDate] = useState(null);
  const [dateError, setDateError] = useState(false);

  const [createdById, setCreatedById] = useState(0);
  const [progress, setProgress] = React.useState(0);
  const [remainingFullLeave, setRemainingFullLeave] = useState(0);
  const [remainingHalfLeave, setRemainingHalfLeave] = useState(0);
  const [remainingShortLeave, setRemainingShortLeave] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQuotaModalOpen, setIsQuotaModalOpen] = useState(false);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [isInternModalOpen, setIsInternModalOpen] = useState(false);

  const [isSubmittingModalOpen, setIsSubmittingModalOpen] = useState(false);

  const [leaveTypeError, setLeaveTypeError] = useState(false);
  const [selectedHalfDayDateError, setSelectedHalfDayDateError] =
    useState(false);
  const [selectedWhichHalfError, setSelectedWhichHalfError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  const [shortDateError, setShortDateError] = useState(false);
  const [shortDateErrorText, setShortDateErrorText] = useState('');

  const [errorFromBackend, setErrorFromBackend] = useState('');

  const [availFullLeave, setAvailFullLeave] = useState(true);
  const [availHalfLeave, setAvailHalfLeave] = useState(true);
  const [availShortLeave, setAvailShortLeave] = useState(true);
  const [availFullLeaveError, setAvailFullLeaveError] = useState('');
  const [quotaExceedError, setQuotaExceedError] = useState('');
  const [focusedLeaveType, setFocusedLeaveType] = useState(true);
  const [focusedDuration, setFocusedDuration] = useState(true);
  const [internText, setInternText] = useState('');
  const [probationText, setProbationText] = useState('');
  const [currentMonthFullLeaves, setCurrentMonthFullLeaves] = useState(0);
  const [currentMonthShortLeaves, setCurrentMonthShortLeaves] = useState(0);
  const [currentMonthHalfLeaves, setCurrentMonthHalfLeaves] = useState(0);

  useEffect(() => {
    let intervalId;
    let isMounted = true;
    const storedUser = localStorage.getItem('user');
    const parsedUser = JSON.parse(storedUser);

    const startProgress = () => {
      intervalId = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress = oldProgress + 100 / steps;
          if (newProgress >= 100) {
            clearInterval(intervalId);
            setTimeout(() => {
              if (isMounted) {
                setIsSubmitting(false);
                setIsSubmittingModalOpen(false);
                history.push(`/MyLeaves/${parsedUser.id}`);
              }
            }, 500);
            return 100;
          }
          return newProgress;
        });
      }, intervalDuration);
    };

    const intervalDuration = 30;
    const totalTime = 1000;
    const steps = totalTime / intervalDuration;

    if (isSubmittingModalOpen) {
      startProgress();
    }

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [isSubmittingModalOpen]);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const parsedUser = JSON.parse(storedUser);
    if (parsedUser.roleId === 33 || parsedUser.roleId === 34) {
      setIsInternModalOpen(true);
      const sidebar = document.querySelector('.sidebarParent');
      const navbar = document.querySelector('.main__navbar__right');

      if (sidebar) {
        sidebar.classList.add('blurred__sidebar');
      }
      if (navbar) {
        navbar.classList.add('blurred__sidebar');
      }
      if (parsedUser.roleId === 33) {
        setInternText(
          'As an intern, you are not eligible for standard leave. In case of an emergency, please submit a special leave request with valid reasons.',
        );
      }
      if (parsedUser.roleId === 34) {
        setProbationText(
          'As a probationary employee, you are not eligible for standard leave. In case of an emergency, please submit a special leave request with valid reasons.',
        );
      }
    }
    const fetchUserData = async () => {
      const leaveType = await LeaveTypeService.findAll();
      setAllLeaveType(leaveType);

      setCreatedById(parsedUser.id);

      const leaves = await LeaveService.findRemaingLeaves(parsedUser.id);
      setRemainingFullLeave(leaves.sickLeave + leaves.casualLeave);
      setRemainingHalfLeave(leaves.halfLeave);
      setRemainingShortLeave(leaves.shortLeave);

      const today = new Date();

      const staticStartDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        1,
      );

      const staticEndDate = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0,
      );

      const formatToLocalDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
      };

      const data = {
        userId: parsedUser.id,
        month: 'specialFilter',
        startDate: formatToLocalDate(staticStartDate),
        endDate: formatToLocalDate(staticEndDate),
      };

      const totalLeaves = await LeaveService.filterMyLeavesOfMonth(data);

      const filteredUsers = totalLeaves.filter(
        (user) => user.userId === parsedUser.id,
      );
      if (filteredUsers.length > 0) {
        const noOfFullLeavesInThisMonth = filteredUsers[0].fullDays;
        const noOfHalfLeavesInThisMonth = filteredUsers[0].halfLeaves;
        const noOfShortLeavesInThisMonth = filteredUsers[0].shortLeaves;
        if (noOfFullLeavesInThisMonth > 0) {
          setAvailFullLeave(false);
          setCurrentMonthFullLeaves(noOfFullLeavesInThisMonth);
        }
        if (noOfHalfLeavesInThisMonth > 0) {
          setAvailHalfLeave(false);
          setCurrentMonthHalfLeaves(noOfHalfLeavesInThisMonth);
        }
        if (noOfShortLeavesInThisMonth > 0) {
          setAvailShortLeave(false);
          setCurrentMonthShortLeaves(noOfFullLeavesInThisMonth);
        }
      }
    };
    fetchUserData();
  }, []);

  const onSubmit = async () => {
    if (selectedleaveTenure === 'half_day') {
      if (remainingHalfLeave < 1) {
        setIsModalOpen(true);
        setIsSubmitting(false);
        setAvailFullLeaveError(
          'Your half-leave quota has been used up. If you still need leave, please submit a special request',
        );
        return;
      }
    }

    if (selectedLeaveType === '') {
      setLeaveTypeError(true);
      setIsModalOpen(true);
      return;
    } else {
      setLeaveTypeError(false);
    }

    if (selectedleaveTenure === '') {
      setIsModalOpen(true);
      return;
    }

    const validateDescription = () => {
      if (descriptionText === '') {
        setDescriptionError(true);
        setIsModalOpen(true);
        setIsSubmitting(false);

        return false;
      } else {
        setDescriptionError(false);
        return true;
      }
    };

    if (shortDateError) {
      setIsDateModalOpen(true);
      setIsSubmitting(false);
      return;
    } else {
      setDescriptionError(false);
    }

    const validateDate = (date, setError) => {
      if (!date) {
        setError(true);
        setIsModalOpen(true);
        setIsSubmitting(false);
        return false;
      } else {
        setError(false);
        return true;
      }
    };

    const validateHalf = () => {
      if (selectedHalf === '') {
        setSelectedWhichHalfError(true);
        setIsModalOpen(true);
        setIsSubmitting(false);

        return false;
      } else {
        setSelectedWhichHalfError(false);
        return true;
      }
    };

    const leaveTypes = {
      full_day: !availFullLeave,
      half_day: !availHalfLeave,
      short_leave: !availShortLeave,
      full_and_half: !availFullLeave || !availHalfLeave,
      half_and_short: !availHalfLeave || !availShortLeave,
      full_and_short: !availFullLeave || !availShortLeave,
      full_and_half_and_short:
        !availFullLeave || !availHalfLeave || !availShortLeave,
    };
    const leaveErrors = {
      full_day:
        "You can't take more than one full leave in one month if you really need it then apply it via special request",
      half_day:
        "You can't take more than one half leave in one month if you really need it then apply it via special request",
      short_leave:
        "You can't take more than one Short leave in one month if you really need it then apply it via special request",
      full_and_half:
        "You can't take more than one full or half leave in one month if you really need it then apply it via special request",
      half_and_short:
        "You can't take more than one half or short leave in one month if you really need it then apply it via special request",
      full_and_short:
        "You can't take more than one full or short leave in one month if you really need it then apply it via special request",
      full_and_half_and_short:
        "You can't take more than one full, half, or short leave in one month if you really need it then apply it via special request",
    };

    if (leaveTypes[selectedleaveTenure]) {
      setIsModalOpen(true);
      setIsSubmitting(false);
      setAvailFullLeaveError(leaveErrors[selectedleaveTenure]);
      return;
    }

    const checkLeaveQuota = (quota, message) => {
      if (quota === 0) {
        setIsQuotaModalOpen(true);
        setIsSubmitting(false);
        setQuotaExceedError(message);
        return true;
      }
      return false;
    };

    const leaveQuotaMessages = {
      full_day: 'Your Full Day Leaves Quota Ended',
      half_day: 'Your Half Day Leaves Quota Ended',
      short_leave: 'Your Short Leaves Quota Ended',
    };

    const tenureChecks = {
      full_day: () => {
        return checkLeaveQuota(remainingFullLeave, leaveQuotaMessages.full_day);
      },
      half_day: () =>
        checkLeaveQuota(remainingHalfLeave, leaveQuotaMessages.half_day),
      short_leave: () =>
        checkLeaveQuota(remainingShortLeave, leaveQuotaMessages.short_leave),
      full_and_half: () => {
        if (checkLeaveQuota(remainingFullLeave, leaveQuotaMessages.full_day))
          return true;

        return checkLeaveQuota(remainingHalfLeave, leaveQuotaMessages.half_day);
      },
      full_and_short: () => {
        if (checkLeaveQuota(remainingFullLeave, leaveQuotaMessages.full_day))
          return true;
        return checkLeaveQuota(
          remainingShortLeave,
          leaveQuotaMessages.short_leave,
        );
      },
      half_and_short: () => {
        if (checkLeaveQuota(remainingHalfLeave, leaveQuotaMessages.half_day))
          return true;
        return checkLeaveQuota(
          remainingShortLeave,
          leaveQuotaMessages.short_leave,
        );
      },
      full_and_half_and_short: () => {
        if (checkLeaveQuota(remainingFullLeave, leaveQuotaMessages.full_day))
          return true;
        if (checkLeaveQuota(remainingHalfLeave, leaveQuotaMessages.half_day))
          return true;
        return checkLeaveQuota(
          remainingShortLeave,
          leaveQuotaMessages.short_leave,
        );
      },
    };

    if (tenureChecks[selectedleaveTenure]()) {
      return;
    }

    const buildLeaveData = (leaveTenure, date, extraData = {}) => {
      return {
        leaveTypeId: selectedLeaveType,
        date: date.toISOString(),
        status: 'pending',
        description: descriptionText,
        leaveTenure: leaveTenure,
        createdById: createdById,
        ...extraData,
      };
    };

    setIsSubmitting(true);

    try {
      switch (selectedleaveTenure) {
        case 'half_day':
          if (
            !validateDate(selectedHalfDayDate, setSelectedHalfDayDateError) ||
            !validateHalf() ||
            !validateDescription()
          )
            return;
          const data = {
            leaveTenure: selectedleaveTenure,
            which_half: selectedHalf,
            date: selectedHalfDayDate.toISOString(),
            usersId: createdById,
          };
          const checkLeaveOnCurrentDate = await LeaveService.leaveOnCurrentDate(
            data,
          );
          if (checkLeaveOnCurrentDate['error']) {
            setIsModalOpen(true);
            setErrorFromBackend(checkLeaveOnCurrentDate['message']);
            setIsSubmitting(false);
            return;
          } else {
            setIsModalOpen(false);
            setErrorFromBackend('');
          }

          await LeaveService.save(
            buildLeaveData('half_day', selectedHalfDayDate, {
              which_half: selectedHalf,
            }),
          );
          break;

        case 'short_leave':
          if (
            !validateDate(selectedShortFromDate, setShortDateError) ||
            !validateDescription()
          )
            return;
          const data2 = {
            leaveTenure: selectedleaveTenure,
            which_half: 'short_leave',
            date: selectedShortFromDate.toISOString(),
            usersId: createdById,
          };
          const checkLeaveOnCurrentDate2 =
            await LeaveService.leaveOnCurrentDate(data2);
          if (checkLeaveOnCurrentDate2['error']) {
            setIsModalOpen(true);
            setErrorFromBackend(checkLeaveOnCurrentDate2['message']);
            setIsSubmitting(false);
            return;
          } else {
            setIsModalOpen(false);
            setErrorFromBackend('');
          }
          await LeaveService.save(
            buildLeaveData('short_leave', selectedShortFromDate),
          );
          break;

        case 'full_day':
          if (
            !validateDate(selectedDate, setDateError) ||
            !validateDescription()
          )
            return;
          const data1 = {
            leaveTenure: selectedleaveTenure,
            which_half: 'full_day',
            date: selectedDate,
            usersId: createdById,
          };
          const checkLeaveOnCurrentDate1 =
            await LeaveService.leaveOnCurrentDate(data1);
          if (checkLeaveOnCurrentDate1['error']) {
            setIsModalOpen(true);
            setErrorFromBackend(checkLeaveOnCurrentDate1['message']);
            setIsSubmitting(false);
            return;
          } else {
            setIsModalOpen(false);
            setErrorFromBackend('');
          }
          await LeaveService.save(buildLeaveData('full_day', selectedDate));
          break;

        case 'full_and_half':
          if (
            !validateDate(selectedDate, setDateError) ||
            !validateDate(selectedHalfDayDate, setSelectedHalfDayDateError) ||
            !validateHalf() ||
            !validateDescription()
          )
            return;
          const data3 = {
            leaveTenure: selectedleaveTenure,
            which_half: 'full_and_half',
            date_h: selectedHalfDayDate.toISOString(),
            date_f: selectedDate,
            usersId: createdById,
          };
          const checkLeaveOnCurrentDate3 =
            await LeaveService.leaveOnCurrentDate(data3);
          if (checkLeaveOnCurrentDate3['error']) {
            setIsModalOpen(true);
            setErrorFromBackend(checkLeaveOnCurrentDate3['message']);
            setIsSubmitting(false);
            return;
          } else {
            setIsModalOpen(false);
            setErrorFromBackend('');
          }
          await LeaveService.save(buildLeaveData('full_day', selectedDate));
          await LeaveService.save(
            buildLeaveData(selectedHalf, selectedHalfDayDate, {
              which_half: selectedHalf,
            }),
          );
          break;

        case 'full_and_short':
          if (
            !validateDate(selectedDate, setDateError) ||
            !validateDate(selectedShortFromDate, setShortDateError) ||
            !validateDescription()
          )
            return;
          const data5 = {
            leaveTenure: selectedleaveTenure,
            which_half: 'full_and_short',
            date_f: selectedDate,
            date: selectedShortFromDate,
            usersId: createdById,
          };
          const checkLeaveOnCurrentDate5 =
            await LeaveService.leaveOnCurrentDate(data5);
          if (checkLeaveOnCurrentDate5['error']) {
            setIsModalOpen(true);
            setErrorFromBackend(checkLeaveOnCurrentDate5['message']);
            setIsSubmitting(false);
            return;
          } else {
            setIsModalOpen(false);
            setErrorFromBackend('');
          }
          await LeaveService.save(buildLeaveData('full_day', selectedDate));
          await LeaveService.save(
            buildLeaveData('short_leave', selectedShortFromDate),
          );
          break;

        case 'half_and_short':
          if (
            !validateDate(selectedHalfDayDate, setSelectedHalfDayDateError) ||
            !validateHalf() ||
            !validateDate(selectedShortFromDate, setShortDateError) ||
            !validateDescription()
          )
            return;
          const data6 = {
            leaveTenure: selectedleaveTenure,
            which_half: selectedHalf,
            date: selectedHalfDayDate.toISOString(),
            usersId: createdById,
          };
          const checkLeaveOnCurrentDate6 =
            await LeaveService.leaveOnCurrentDate(data6);
          if (checkLeaveOnCurrentDate6['error']) {
            setIsModalOpen(true);
            setErrorFromBackend(checkLeaveOnCurrentDate6['message']);
            setIsSubmitting(false);
            return;
          } else {
            setIsModalOpen(false);
            setErrorFromBackend('');
          }
          await LeaveService.save(
            buildLeaveData(selectedHalf, selectedHalfDayDate, {
              which_half: selectedHalf,
            }),
          );
          await LeaveService.save(
            buildLeaveData('short_leave', selectedShortFromDate),
          );
          break;

        case 'full_and_half_and_short':
          if (
            !validateDate(selectedDate, setDateError) ||
            !validateDate(selectedHalfDayDate, setSelectedHalfDayDateError) ||
            !validateHalf() ||
            !validateDate(selectedShortFromDate, setShortDateError) ||
            !validateDescription()
          )
            return;
          const data4 = {
            leaveTenure: selectedleaveTenure,
            which_half: 'full_and_half_and_short',
            date_h: selectedHalfDayDate.toISOString(),
            date_f: selectedDate,
            date: selectedShortFromDate,
            usersId: createdById,
          };
          const checkLeaveOnCurrentDate4 =
            await LeaveService.leaveOnCurrentDate(data4);

          if (checkLeaveOnCurrentDate4['error']) {
            setIsModalOpen(true);
            setErrorFromBackend(checkLeaveOnCurrentDate4['message']);
            setIsSubmitting(false);
            return;
          } else {
            setIsModalOpen(false);
            setErrorFromBackend('');
          }
          await LeaveService.save(buildLeaveData('full_day', selectedDate));
          await LeaveService.save(
            buildLeaveData(selectedHalf, selectedHalfDayDate, {
              which_half: selectedHalf,
            }),
          );
          await LeaveService.save(
            buildLeaveData('short_leave', selectedShortFromDate),
          );
          break;

        default:
          throw new Error('Invalid leave tenure type');
      }
      setIsSubmittingModalOpen(true);
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeDateModal = () => {
    setIsDateModalOpen(false);
  };

  const closeQuotaModal = () => {
    setIsQuotaModalOpen(false);
  };

  const closeSubmittingModal = () => {
    setIsSubmittingModalOpen(false);
  };
  const closeInternModal = () => {
    history.push('special/add');
  };

  const handleSelectLeaveType = (event) => {
    if (event.target.value !== '') {
      setLeaveTypeError(false);
    }
    setSelectedLeaveType(event.target.value);
  };

  const handleShortFromDateChange = (date) => {
    setSelectedShortFromDate(date);
    const endDate = dayjs(date.$d).add(2, 'hour');
    setSelectedShortToDate(endDate);

    const dateObject = date.$d;
    const hours = dateObject.getHours();
    // Check if the hours are between 9 AM (9) and 6 PM (18)
    if (hours >= 9 && hours <= 16) {
      if (date) {
        setShortDateError(false);
        setShortDateErrorText('');
        const currentDate = dayjs();
        if (date.month() !== currentDate.month()) {
          setAvailShortLeave(true);
        } else {
          if (currentMonthShortLeaves > 0) {
            setAvailShortLeave(false);
          } else {
            setAvailShortLeave(true);
          }
        }
      }
    } else {
      setShortDateError(true);
      setShortDateErrorText(
        'short leave time must fall within the specified time range of 9 am to 4 pm',
      );
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date) {
      setDateError(false);
      const currentDate = dayjs();
      if (date.month() !== currentDate.month()) {
        setAvailFullLeave(true);
      } else {
        if (currentMonthFullLeaves > 0) {
          setAvailFullLeave(false);
        } else {
          setAvailFullLeave(true);
        }
      }
    }
  };

  const handleHalfDayChange = (date) => {
    setSelectedHalfDayDate(date);
    if (date) {
      setSelectedHalfDayDateError(false);
      const currentDate = dayjs();
      if (date.month() !== currentDate.month()) {
        setAvailHalfLeave(true);
      } else {
        if (currentMonthHalfLeaves > 0) {
          setAvailHalfLeave(false);
        } else {
          setAvailHalfLeave(true);
        }
      }
    }
  };

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;

    if (name === 'select_half') {
      setSelectedHalf(value);
      if (selectedWhichHalfError) {
        if (value !== '') {
          setSelectedWhichHalfError(false);
        }
      }
    }
  };

  const handleChangeDescription = (e) => {
    const { value } = e.target;
    setDescriptionText(value);
    if (value !== '') {
      setDescriptionError(false);
    }
  };

  const handleRadioChange = (event) => {
    setSelectedHalfDayDate(null);
    setDescriptionText('');
    setSelectedHalf('');
    setSelectedleaveTenure(event.target.value);
    setErrorFromBackend('');
    setAvailFullLeaveError('');
    setQuotaExceedError('');
  };

  const maxAllowedTime = new Date(selectedShortFromDate);
  maxAllowedTime.setHours(maxAllowedTime.getHours() + 2);
  const sixPM = new Date(selectedShortFromDate);
  sixPM.setHours(18, 0, 0, 0);

  if (maxAllowedTime > sixPM) {
    maxAllowedTime.setHours(18, 0, 0, 0);
  }

  const minAllowedTime = new Date(selectedShortFromDate);
  minAllowedTime.setHours(9, 0, 0, 0);

  const maxAllowedTimes = new Date(selectedShortFromDate);
  maxAllowedTimes.setHours(18, 0, 0, 0);

  const handleFocusLeaveType = () => {
    setFocusedLeaveType(true);
  };

  const handleBlurLeaveType = () => {
    setFocusedLeaveType(false);
  };

  const handleFocusDuration = () => {
    setFocusedDuration(true);
  };

  const handleBlurDuration = () => {
    setFocusedDuration(false);
  };

  return (
    <Layout>
      <div className="leavesIndex">
        <h4 className="attendance_overview_heading">Leave Request Form</h4>
        <div className="leavesIndex__header">
          <div className="leavesIndex__header_left">
            <div className="remaingLeavesCounter remaingLeavesCounterLeaveDetails">
              <p className="attendance_overview_sub_heading">
                Remaining Leaves:
              </p>
              <div>
                <div className="singleLeavesCounter">
                  <p className="remaingLeaversCounter">
                    Half: {remainingHalfLeave}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="leavesIndex__body">
          <div className="leavesIndex__body_padding">
            <Box
              component="form"
              noValidate
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr' },
                gap: 2,
              }}
            >
              <div>
                <div className="displayFlexAddForm">
                  <div className="singleInputField addLeaveInputField">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Type Of Leave
                      </InputLabel>
                      <select
                        name="leave_type"
                        id="leave_type"
                        className={`leaveDurationInput ${
                          leaveTypeError ? 'leaveDurationInput-error' : ''
                        } ${
                          focusedLeaveType ? 'leaveDurationInputFocused' : ''
                        }`}
                        onChange={handleSelectLeaveType}
                        onFocus={handleFocusLeaveType}
                        onBlur={handleBlurLeaveType}
                      >
                        <option value="">Select Leave Type</option>
                        {allLeaveType.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="singleInputField addLeaveInputField displayFlexAddFormSecond">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Duration
                      </InputLabel>
                      <select
                        name="leave_type"
                        id="leave_type"
                        className={`leaveDurationInput ${
                          leaveTypeError ? 'leaveDurationInput-error' : ''
                        } ${
                          focusedDuration ? 'leaveDurationInputFocused' : ''
                        }`}
                        onChange={handleRadioChange}
                        onFocus={handleFocusDuration}
                        onBlur={handleBlurDuration}
                      >
                        <option value="">Select Duration</option>
                        <option value="full_day">Full Day</option>
                        <option value="half_day">Half Day</option>
                        <option value="short_leave">Short Leave</option>
                        <option value="full_and_half">
                          Full Day and Half Day
                        </option>
                        <option value="full_and_short">
                          Full Day and Short Leave
                        </option>
                        <option value="half_and_short">
                          Half Day and Short Leave
                        </option>
                        <option value="full_and_half_and_short">
                          Full Day , Half Day and Short Leave
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {selectedLeaveType !== '' &&
                (selectedleaveTenure === 'half_day' ||
                  selectedleaveTenure === 'full_and_half' ||
                  selectedleaveTenure === 'half_and_short' ||
                  selectedleaveTenure === 'full_and_half_and_short') && (
                  <div>
                    <div className="displayFlexAddForm displayFlexAddFormSecond">
                      <div className="singleInputField singleInputFieldWithoutBorder">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={[
                              'DatePicker',
                              'MobileDatePicker',
                              'DesktopDatePicker',
                              'StaticDatePicker',
                            ]}
                          >
                            {selectedleaveTenure === 'full_and_half' ||
                            selectedleaveTenure === 'half_and_short' ||
                            selectedleaveTenure ===
                              'full_and_half_and_short' ? (
                              <InputLabel
                                className="formInputLabels"
                                shrink
                                htmlFor="bootstrap-input"
                              >
                                Date for Half Day
                              </InputLabel>
                            ) : (
                              <InputLabel
                                className="formInputLabels"
                                shrink
                                htmlFor="bootstrap-input"
                              >
                                Date
                              </InputLabel>
                            )}
                            <DesktopDatePicker
                              onChange={handleHalfDayChange}
                              className={`leaveDurationInput leaveDurationInputDate newLeaveScreen ${
                                selectedHalfDayDateError
                                  ? 'leaveDurationInput-error'
                                  : ''
                              }`}
                              minDate={TwoDaysBefore}
                              value={selectedHalfDayDate}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className="singleInputField addLeaveInputField displayFlexAddFormSecond">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={[
                              'DatePicker',
                              'MobileDatePicker',
                              'DesktopDatePicker',
                              'StaticDatePicker',
                            ]}
                          >
                            <InputLabel
                              className="formInputLabels"
                              shrink
                              htmlFor="bootstrap-input"
                            >
                              Select Half
                            </InputLabel>
                            <select
                              name="select_half"
                              id="select_half"
                              className={`leaveDurationInput ${
                                selectedWhichHalfError
                                  ? 'leaveDurationInput-error'
                                  : ''
                              }`}
                              style={{ padding: '16.5px 12px' }}
                              onChange={handleChange}
                            >
                              <option value="">Select Half</option>
                              <option value="first_half">First</option>
                              <option value="second_half">Second</option>
                            </select>
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div>

                    {selectedleaveTenure === 'full_and_half' ||
                    selectedleaveTenure === 'half_and_short' ||
                    selectedleaveTenure === 'full_and_half_and_short' ? (
                      <div></div>
                    ) : (
                      <div
                        className="displayFlexAddForm displayFlexAddFormSecond"
                        style={{
                          margin: '1vw 0',
                        }}
                      >
                        <div className="singleInputField singleInputFieldTextArea">
                          <InputLabel
                            className="formInputLabels"
                            shrink
                            htmlFor="bootstrap-input"
                          >
                            Reason For Leave
                          </InputLabel>
                          <FormControl variant="standard">
                            <textarea
                              className={`leaveDurationTextArea leaveDurationInput ${
                                descriptionError
                                  ? 'leaveDurationInput-error'
                                  : ''
                              }`}
                              onChange={handleChangeDescription}
                              value={descriptionText}
                              placeholder="Enter text here..."
                              rows={6}
                              cols={50}
                            />
                          </FormControl>
                        </div>
                      </div>
                    )}
                  </div>
                )}

              {/* short */}
              {selectedLeaveType !== '' &&
                (selectedleaveTenure === 'short_leave' ||
                  selectedleaveTenure === 'half_and_short' ||
                  selectedleaveTenure === 'full_and_short' ||
                  selectedleaveTenure === 'full_and_half_and_short') && (
                  <div>
                    <div className="displayFlexAddForm displayFlexAddFormSecond">
                      <div className="singleInputField singleInputFieldWithoutBorder">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DateTimePicker']}>
                            {selectedleaveTenure === 'half_and_short' ||
                            selectedleaveTenure === 'full_and_short' ||
                            selectedleaveTenure ===
                              'full_and_half_and_short' ? (
                              <InputLabel
                                className="formInputLabels"
                                shrink
                                htmlFor="bootstrap-input"
                              >
                                From Date Short Leave
                              </InputLabel>
                            ) : (
                              <InputLabel
                                className="formInputLabels"
                                shrink
                                htmlFor="bootstrap-input"
                              >
                                From Date
                              </InputLabel>
                            )}
                            <DateTimePicker
                              onChange={handleShortFromDateChange}
                              className={`leaveDurationInput newLeaveScreen ${
                                shortDateError ? 'leaveDurationInput-error' : ''
                              }`}
                              minDate={TwoDaysBefore}
                              value={selectedShortFromDate}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className="singleInputField singleInputFieldWithoutBorder">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DateTimePicker']}>
                            {selectedleaveTenure === 'half_and_short' ||
                            selectedleaveTenure === 'full_and_short' ||
                            selectedleaveTenure ===
                              'full_and_half_and_short' ? (
                              <InputLabel
                                className="formInputLabels"
                                shrink
                                htmlFor="bootstrap-input"
                              >
                                To Date Short Leave
                              </InputLabel>
                            ) : (
                              <InputLabel
                                className="formInputLabels"
                                shrink
                                htmlFor="bootstrap-input"
                              >
                                To Date
                              </InputLabel>
                            )}
                            <DateTimePicker
                              className={`leaveDurationInput newLeaveScreen ${
                                shortDateError ? 'leaveDurationInput-error' : ''
                              }`}
                              value={selectedShortToDate}
                              readOnly
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div>

                    {selectedleaveTenure === 'full_and_short' ||
                    selectedleaveTenure === 'full_and_half_and_short' ? (
                      <div></div>
                    ) : (
                      <div
                        className="displayFlexAddForm displayFlexAddFormSecond"
                        style={{
                          margin: '1vw 0',
                        }}
                      >
                        <div className="singleInputField singleInputFieldTextArea">
                          <InputLabel
                            className="formInputLabels"
                            shrink
                            htmlFor="bootstrap-input"
                          >
                            Reason For Leave
                          </InputLabel>
                          <FormControl variant="standard">
                            <textarea
                              className={`leaveDurationTextArea leaveDurationInput ${
                                descriptionError
                                  ? 'leaveDurationInput-error'
                                  : ''
                              }`}
                              onChange={handleChangeDescription}
                              value={descriptionText}
                              placeholder="Enter text here..."
                              rows={6}
                              cols={50}
                            />
                          </FormControl>
                        </div>
                      </div>
                    )}
                  </div>
                )}

              {selectedLeaveType !== '' &&
                (selectedleaveTenure === 'full_day' ||
                  selectedleaveTenure === 'full_and_half' ||
                  selectedleaveTenure === 'full_and_short' ||
                  selectedleaveTenure === 'full_and_half_and_short') && (
                  <div>
                    <div className="displayFlexAddForm displayFlexAddFormSecond">
                      <div className="singleInputField singleInputFieldWithoutBorder">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={[
                              'DatePicker',
                              'MobileDatePicker',
                              'DesktopDatePicker',
                              'StaticDatePicker',
                            ]}
                          >
                            {selectedleaveTenure === 'full_and_half' ||
                            selectedleaveTenure === 'full_and_short' ||
                            selectedleaveTenure ===
                              'full_and_half_and_short' ? (
                              <InputLabel
                                className="formInputLabels"
                                shrink
                                htmlFor="bootstrap-input"
                              >
                                Date for Full Day
                              </InputLabel>
                            ) : (
                              <InputLabel
                                className="formInputLabels"
                                shrink
                                htmlFor="bootstrap-input"
                              >
                                Date
                              </InputLabel>
                            )}
                            {/* <DesktopDatePicker className="leaveDurationInput" /> */}
                            <DesktopDatePicker
                              onChange={handleDateChange}
                              className={`leaveDurationInput newLeaveScreen ${
                                dateError ? 'leaveDurationInput-error' : ''
                              }`}
                              minDate={TwoDaysBefore}
                              value={selectedDate}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className="singleInputField displayFlexAddFormSecond"></div>
                    </div>
                    <div
                      className="displayFlexAddForm displayFlexAddFormSecond"
                      style={{
                        margin: '1vw 0',
                      }}
                    >
                      <div className="singleInputField singleInputFieldTextArea">
                        <InputLabel
                          className="formInputLabels"
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          Reason For Leave
                        </InputLabel>
                        <FormControl variant="standard">
                          <textarea
                            className={`leaveDurationTextArea leaveDurationInput ${
                              descriptionError ? 'leaveDurationInput-error' : ''
                            }`}
                            onChange={handleChangeDescription}
                            value={descriptionText}
                            placeholder="Enter text here..."
                            rows={6}
                            cols={50}
                          />
                        </FormControl>
                      </div>
                    </div>
                  </div>
                )}
              <div className="displayFlexAddForm displayFlexAddFormSecond">
                <div className="singleInputField"></div>
                <div className="singleInputField">
                  <div className="formButton">
                    <button
                      type="button"
                      disabled={isSubmitting}
                      className="formSubmitButton"
                      onClick={onSubmit}
                    >
                      {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </div>

        <ValidationModal
          isOpen={isSubmittingModalOpen}
          onClose={closeSubmittingModal}
        >
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div className="ProgressModal">
                <Box className="progressOfSubmitting" sx={{ width: '100%' }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                      height: 15,
                      borderRadius: 10,
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#1fc214',
                      },
                    }}
                  />
                </Box>
                <span>Submitting Request</span>
              </div>
            </div>
          </div>
        </ValidationModal>

        <RejectionModal isOpen={isModalOpen} onClose={closeModal}>
          <div className="modal__popup">
            {availFullLeaveError === '' ? (
              <div className="Portal-Login-Form">
                {errorFromBackend === '' ? (
                  <div
                    className="ValidationModal"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <span>Please Enter All Fields To Continue!</span>

                    <div className="CloseRejectionModalButton">
                      <button
                        type="button"
                        className="CloseRejectionModal"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    className="ValidationModal"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <span>{errorFromBackend}</span>

                    <div className="CloseRejectionModalButton">
                      <button
                        type="button"
                        className="CloseRejectionModal"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="Portal-Login-Form">
                <div
                  className="ValidationModal"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span>{availFullLeaveError}</span>

                  <div className="CloseRejectionModalButton">
                    <button
                      type="button"
                      className="CloseRejectionModal"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </RejectionModal>

        <RejectionModal isOpen={isQuotaModalOpen} onClose={closeQuotaModal}>
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div
                className="ValidationModal"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span>{quotaExceedError}</span>

                <div className="CloseRejectionModalButton">
                  <button
                    type="button"
                    className="CloseRejectionModal"
                    onClick={closeQuotaModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </RejectionModal>

        <RejectionModal isOpen={isDateModalOpen} onClose={closeDateModal}>
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div
                className="ValidationModal"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span className="approvalText">{shortDateErrorText}</span>

                <div className="CloseRejectionModalButton">
                  <button
                    type="button"
                    className="CloseRejectionModal"
                    onClick={closeDateModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </RejectionModal>

        {/* intern or probation modal */}
        <InternModal isOpen={isInternModalOpen} onClose={closeInternModal}>
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div
                className="ValidationModal interTextModal"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {internText !== '' && (
                  <span className="interText">{internText}</span>
                )}
                {probationText !== '' && (
                  <span className="interText">{probationText}</span>
                )}

                <div className="CloseRejectionModalButton">
                  <button
                    type="button"
                    className="submitValidationModal"
                    onClick={closeInternModal}
                  >
                    Special Request
                  </button>
                </div>
              </div>
            </div>
          </div>
        </InternModal>
      </div>
    </Layout>
  );
}
